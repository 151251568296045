import React from 'react';
import { chain } from 'lodash';

import { State } from '../../../types/types';
import { LegalEngineResponseItem } from '../../../types/types';
import { getStoreByNumber, Store } from '../../../domain/Store';

export interface IllegalStateInfo {
  readonly state: State;
  readonly adjustedTerm: number;
  readonly totalCostLimit: string;
}

interface IllegalValueBoxProps {
  readonly illegalResponse: LegalEngineResponseItem[];
  readonly stores: Store[];
  readonly poNumber?: string;
}

export const illegalValueBoxTestId = 'illegalValueBoxComponent';

export const IllegalValueBox = ({
  illegalResponse,
  stores,
  poNumber,
}: IllegalValueBoxProps) => {
  const states = illegalResponse.map(
    (item: LegalEngineResponseItem) =>
      ({
        adjustedTerm: item?.priceInfo?.weeklyTerm,
        totalCostLimit: item?.priceInfo?.totalCost,
        state: getStoreByNumber(stores, item?.storeNumber)?.state,
      } as IllegalStateInfo)
  );

  const uniqueStatesWithIllegalValues: IllegalStateInfo[] = chain(states)
    .uniqBy((item: IllegalStateInfo) => item.state.name)
    .value();

  return (
    <div data-testid={illegalValueBoxTestId}>
      <h2>Weekly Term Updated</h2>

      {uniqueStatesWithIllegalValues.map(
        ({ state, adjustedTerm, totalCostLimit }: IllegalStateInfo) => (
          <p key={state?.name}>
            <div>
              The current pricing is outside of the <b>{state?.name}</b> state
              legal limits.
            </div>
            {totalCostLimit && (
              <div>
                The maximum state limit in {state?.name} for <b>Weekly term</b>{' '}
                is: <b>{adjustedTerm}</b>
              </div>
            )}
          </p>
        )
      )}

      {poNumber ? (
        <p>PO#{poNumber} has been updated.</p>
      ) : (
        <p>Your PO has been updated.</p>
      )}
    </div>
  );
};
