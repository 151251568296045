import { makeStyles, RACButton, RACCard } from '@rentacenter/racstrap';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { LooseWorkModal } from '../../components/POs/LooseWorkModal';
import { AppRoute } from '../../config/route-config';

export interface CreatPOFooterProps {
  children: any;
  isEditPo: boolean;
  isDirty: boolean;
}
const useStyles = makeStyles((theme: any) => ({
  footerRoot: {
    width: '100%',
    marginBottom: '0rem',
    display: 'block',
    position: 'fixed',
    bottom: '0',
    left: '0',
    zIndex: 1100,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(0),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0',
  },
  cardBody: {
    flex: '0 0 auto',
    padding: '1rem 1rem',
  },
  leftButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.typography.pxToRem(4),
  },
  rightButtonsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: theme.typography.pxToRem(4),
  },
  button: {
    height: theme.typography.pxToRem(43),
  },
}));

export const CreatePOFooter = ({
  children,
  isEditPo,
  isDirty,
}: CreatPOFooterProps) => {
  const classes = useStyles();

  const [showLooseWorkModal, setShowLooseWorkModal] = useState(false);
  const [isSubmittedPOReload, setIsSubmittedPOReload] = useState(false);
  const mode = isEditPo ? '?draft' : '';

  const handleBackButtonClick = () => {
    if (isDirty) {
      setShowLooseWorkModal(true);
    } else {
      setIsSubmittedPOReload(true);
    }
  };

  return (
    <div className={clsx(classes.footerRoot)}>
      {showLooseWorkModal && (
        <LooseWorkModal
          onClose={() => setShowLooseWorkModal(false)}
          handleCancelButtonClick={() => setIsSubmittedPOReload(true)}
          isEditPo={isEditPo}
        />
      )}
      <RACCard className={classes.card}>
        {isSubmittedPOReload && <Redirect to={AppRoute.POs + mode} />}

        <div className={clsx(classes.cardBody)}>
          <div className={clsx(classes.row)}>
            <div className={classes.leftButtonsContainer}>
              <RACButton
                onClick={handleBackButtonClick}
                variant="outlined"
                color="secondary"
              >
                Back
              </RACButton>
            </div>
            <div className={classes.rightButtonsContainer}>{children}</div>
          </div>
        </div>
      </RACCard>
    </div>
  );
};
