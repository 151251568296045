import { AxiosResponse } from 'axios';
import { capitalize, isEmpty } from 'lodash';
import {
  ALL_OPTION,
  STORE_NUMBER_KEY,
  SubmittdPOStatusTypes,
} from '../constants/constants';
import { APIError, ListStatus, ReferenceItem } from '../types/types';
import { add, sub } from 'date-fns';
export const sanitizeURL = (url: string): string => {
  if (!url) {
    return '';
  }

  const badURLRegex = RegExp('^((https)|(http)):/{3,}');
  const isBadURL = badURLRegex.test(url);

  if (isBadURL) {
    return 'https://' + url.replace(badURLRegex, '');
  }

  return url;
};

export const getErrorMessage = (response?: AxiosResponse<APIError>) => {
  if (response?.status === 400 && response?.data.errors[0].code) {
    return response?.data.errors[0].message;
  }

  return null;
};

export const getSelectedStore = () =>
  sessionStorage.getItem(STORE_NUMBER_KEY) || '';

export interface ComponentStateProps {
  loading?: boolean;
  hasApiError?: boolean;
  response?: any;
  customMessage?: string | null | undefined;
}

export const getComponentState = ({
  loading,
  hasApiError,
  response,
  customMessage,
}: ComponentStateProps): ListStatus => {
  if (loading) return 'loading';
  if (hasApiError) return 'apiError';
  if (customMessage) return 'custom';

  if (response) {
    if (Array.isArray(response)) {
      return response.length > 0 ? 'success' : 'empty';
    } else if (isEmpty(response)) {
      return 'empty';
    }
    return 'success';
  } else {
    return 'initial';
  }
};

export const noOp = () => {
  // noop
};

export const orderByField = (fieldName: string, arrayToOrder: any[]) => {
  if (!arrayToOrder || !arrayToOrder.length || !fieldName) return arrayToOrder;
  return arrayToOrder?.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1));
};

export const isNonEmptyValue = (value: string): boolean => {
  if (value?.length > 0) {
    return value.trim() !== '';
  }

  return true;
};

export const filterById = (items: any[], id: string) =>
  (items &&
    items?.filter((el: { id: string }) => el.id === id?.split('.')[0])) ||
  [];

interface Element {
  name: string;
  id: string;
  [key: string]: any;
}
export const populateSelectByChild = (data: any[], child?: string) => {
  return (
    data &&
    data.map((element: Element) => {
      const { name, id } = element;

      return child
        ? {
            value: id,
            label: name || id,
            [child]: element[child],
          }
        : {
            value: id,
            label: name || id,
          };
    })
  );
};

export const roundUp = (number: number, decimals = 2) => {
  return Number(Math.ceil(Number(number + 'e' + decimals)) + 'e-' + decimals);
};

export const calculateValue = (calculate: any, ...moreArgs: string[]) => {
  const rest = moreArgs.map((value: string) => {
    return parseFloat(value) || 1;
  });

  const output: number = calculate(...rest);

  return roundUp(output).toString();
};

export const formatMoney = (amount: string | number) => {
  if (amount === null || amount === undefined || isNaN(amount as number)) {
    return '$0.00';
  }

  return `$${Number(amount).toFixed(2)}`;
};

export const pipe =
  (...fns: any) =>
  (x: any) =>
    fns.reduce((v: any, f: (value: any) => void) => f(v), x);

export const mapReferenceResponse = (references: ReferenceItem[]) => {
  if (!references || !references.length) return references;
  return references.map((reference) => ({
    label: capitalize(reference.description),
    value: reference.referenceCode,
    ...reference,
  }));
};

export const filterSubmittedPurchaseOrdersType = (
  references: ReferenceItem[]
) => {
  if (!references || !references.length) return references;

  return references.filter((reference) =>
    SubmittdPOStatusTypes.find((value) => reference.referenceCode === value)
  );
};

export const addAllOption = (arrayWithOptions: any[]) => {
  if (!arrayWithOptions) {
    return [
      {
        label: ALL_OPTION,
        value: ALL_OPTION,
      },
    ];
  }

  return [
    {
      label: ALL_OPTION,
      value: ALL_OPTION,
    },
    ...arrayWithOptions,
  ];
};

export const TWELVE_MONTHS_AGO = sub(new Date(), { months: 12 });
export const TWELVE_MONTHS_AFTER = add(new Date(), { months: 12 });
