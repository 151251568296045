import React, { useEffect } from 'react';
import { useAsync } from 'react-async';
import { getPoSubTypes } from '../api/purchaseOrders';
import { useGlobalContext } from '../context/global/GlobalContext';
import { useUserPermissions } from '../context/permission/PermissionsProvider';
import { setPoSubTypesAction } from '../context/poSubTypes/poSubTypesActions';
import { orderByField } from '../utils/utils';

export const PoSubTypesFetcher = () => {
  const { dispatch } = useGlobalContext();

  const { canSelectPoSubType } = useUserPermissions();

  const { data } = useAsync({
    promiseFn: getPoSubTypes,
  });

  useEffect(() => {
    if (canSelectPoSubType && data) {
      dispatch(setPoSubTypesAction(orderByField('label', data)));
    }
  }, [canSelectPoSubType, data, dispatch]);

  return <></>;
};
