import { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { PurchaseOrderFormValues } from '../../types/types';
import { AppRoute } from '../../config/route-config';
import { PoFormField } from '../../constants/constants';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';

export interface DuplicatePoState {
  readonly duplicatePO?: boolean;
  readonly previousValues?: PurchaseOrderFormValues;
  readonly poId?: string;
}

interface DuplicatePoMethods {
  getValues: UseFormMethods['getValues'];
  setValue: UseFormMethods['setValue'];
  reset: UseFormMethods['reset'];
}

export function useDuplicatePo(poId: string, methods: DuplicatePoMethods) {
  const { getValues, setValue, reset } = methods;
  const history = useHistory<DuplicatePoState>();
  const {
    location: { state: locationState },
  } = history;

  const handleDuplicate = () => {
    history.push(AppRoute.Create, {
      duplicatePO: true,
      previousValues: getValues() as PurchaseOrderFormValues,
      poId: poId,
    });
  };

  useEffect(
    function resetForm() {
      if (locationState && locationState.duplicatePO) {
        const { previousValues, poId } = locationState;

        reset(
          {
            ...previousValues,
            [PoFormField.ReferenceNumber]: '',
            [PoFormField.PoStatus]: PoStatus.DRAFT,
            [PoFormField.Supplier]: `${previousValues?.supplier}-x`,
          },
          { isValid: true }
        );
        history.push(AppRoute.Create, {
          previousValues,
          poId,
        });
      }
    },
    [locationState, reset, setValue, history]
  );

  return { handleDuplicate };
}
