import { createContext, Dispatch } from 'react';

import { submittedPosInitialState, DraftPosState } from './draftPosReducer';
import { DraftPosAction } from './draft-pos-actions';
import { noOp } from '../../utils/utils';

export interface DraftPosContextValue extends DraftPosState {
  dispatch: Dispatch<DraftPosAction>;
}

export const DraftPosContext = createContext<DraftPosContextValue>({
  ...submittedPosInitialState,
  dispatch: () => noOp,
});
