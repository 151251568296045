import { makeStyles } from '@rentacenter/racstrap';
import { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { generateCancelToken } from '../../api/client';
import { getDraftPurchaseOrder } from '../../api/purchaseOrders';
import { getStores } from '../../api/store';
import { ApiStateWrapper } from '../../common/ApiStateWrapper/ApiStateWrapper';
import { AppRoute } from '../../config/route-config';
import { Store } from '../../domain/Store';
import {
  PurchaseOrder as PurchaseOrderModel,
  PurchaseOrderFormValues,
} from '../../types/types';
import { toPurchaseOrderFormValues } from '../../utils/purchaseOrderFormMappers';
import { PODetailsForm } from './PODetailsForm';
import { useUserStateContext } from '../../context/user/user-contexts';

export type PODetailsProps = RouteComponentProps<
  { purchaseOrderId: string },
  Record<string, unknown>
>;

export const useStyles = makeStyles({
  contentHeight: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
  },
});

export const DraftPODetails = (props: PODetailsProps) => {
  const classes = useStyles();
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderModel | null>(
    null
  );
  const [purchaseOrderFormValues, setPurchaseOrderFormValues] =
    useState<PurchaseOrderFormValues | null>(null);
  const [isSaveDraftDisabled, setIsSaveDraftDisabled] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [purchaseOrderNotFound, setPurchaseOrderNotFound] = useState(false);
  const [apiError, setApiError] = useState<Error | null>(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [isStoreLoading, setIsStoreLoading] = useState(false);
  const [searchStoreResult, setSearchStoreResult] = useState<Store[]>([]);
  const { user } = useUserStateContext();

  const {
    match: {
      params: { purchaseOrderId },
    },
  } = props;

  useEffect(() => {
    const fetchPurchaseOrder = async () => {
      try {
        if (!purchaseOrderId) return;

        const purchaseOrder = await getDraftPurchaseOrder(
          user?.employeeId || '',
          purchaseOrderId
        );

        const draftPurchaseOrder = purchaseOrder?.draftPurchaseOrders[0];

        purchaseOrder
          ? setPurchaseOrder(draftPurchaseOrder)
          : setPurchaseOrderNotFound(true);
      } catch (error: any) {
        setIsSaveDraftDisabled(false);
        setIsSubmitDisabled(false);
        setApiError(error);
      }
    };

    if (purchaseOrderId) {
      fetchPurchaseOrder();
    }
  }, [purchaseOrderId, user]);

  useEffect(() => {
    if (purchaseOrder) {
      setPurchaseOrderFormValues(toPurchaseOrderFormValues(purchaseOrder));
    }
  }, [purchaseOrder]);

  useEffect(() => {
    async function fetchStores() {
      const cancelToken: CancelTokenSource = generateCancelToken();

      if (searchTerm.length >= 3 || searchTerm === '') {
        setIsStoreLoading(true);
        const fetchedStores: Store[] = await getStores(
          searchTerm,
          cancelToken.token
        );
        setIsStoreLoading(false);
        setSearchStoreResult(fetchedStores as Store[]);
      }
    }
    fetchStores();
  }, [searchTerm]);

  return (
    <>
      <>
        {purchaseOrderNotFound && <Redirect to="/not-found" />}
        {apiError && <Redirect to={AppRoute.Index} />}
        {searchStoreResult.length &&
        (!purchaseOrderId || purchaseOrderFormValues) ? (
          <PODetailsForm
            purchaseOrderId={purchaseOrderId}
            purchaseOrder={purchaseOrderFormValues}
            stores={searchStoreResult}
            isSaveDraftDisabled={isSaveDraftDisabled}
            setIsSaveDraftDisabled={setIsSaveDraftDisabled}
            isSubmitDisabled={isSubmitDisabled}
            setIsSubmitDisabled={setIsSubmitDisabled}
            onSearch={setSearchTerm}
            isSearchLoading={isStoreLoading}
            {...props}
          />
        ) : (
          <ApiStateWrapper
            loading
            hasApiError={purchaseOrderNotFound}
            successContent={<></>}
            classes={{
              loader: classes.contentHeight,
              apiError: classes.contentHeight,
            }}
          />
        )}
      </>
    </>
  );
};
