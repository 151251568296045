/* eslint-disable react-hooks/exhaustive-deps */
import {
  makeStyles,
  RACSearchDropdown,
  RACTextField,
  Typography,
  RACButton,
  RACCOLOR,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AppRoute } from '../../config/route-config';
import {
  emptyField,
  INPUT_MAX_LENGTH,
  NONE_OPTIONS,
  PoFormField,
  PoFormLabel,
  poSubTypeForWhichFieldIsRequired,
  VALIDATIONS,
} from '../../constants/constants';
import { useGlobalContext } from '../../context/global/GlobalContext';
import { PurchaseOrderFormValues, SelectFieldOption } from '../../types/types';
import { isNonEmptyValue } from '../../utils/utils';
import { AlertModal } from '../POs/AlertModal';
import { DeletePOConfirmModal } from '../POs/DeletePOConfirmModal';
import { DELETE_SUCCESS } from '../POs/POTable';
import { DuplicatePoState } from './useDuplicatePo';
import { useUserPermissions } from '../../context/permission/PermissionsProvider';

export interface DetailsTitleProps {
  purchaseOrder: PurchaseOrderFormValues | null;
  purchaseOrderId?: string;
  disabled?: boolean;
  isEditMode?: boolean;
  isCanceled?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    marginBottom: '1.5rem',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(8),
  },
  field: {
    width: '17rem',
  },
  referenceField: {
    width: theme.typography.pxToRem(220),
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    gap: 32,
    marginTop: '0.5rem',
  },
  group: {
    display: 'flex',
    gap: 8,
    maxHeight: theme.typography.pxToRem(50),
  },
  input: {
    background: 'white',
  },
  infoSection: {
    display: 'flex',
    gap: theme.typography.pxToRem(32),
  },
  cancelButton: {
    backgroundColor: RACCOLOR.LINEN,
    padding: theme.typography.pxToRem(10),
    width: theme.typography.pxToRem(97),
    height: theme.typography.pxToRem(35),
    color: RACCOLOR.PUNCH,
    '&:hover': {
      backgroundColor: RACCOLOR.LINEN,
    },
  },
  badgeRoot: {
    margin: 'auto',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(36)}`,
    borderRadius: theme.typography.pxToRem(25),
    marginRight: theme.typography.pxToRem(18),
    background: 'silver',
    color: 'white',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
  },
}));

export const DetailsTitle = ({
  purchaseOrder,
  disabled,
  purchaseOrderId,
  isEditMode,
  isCanceled,
}: DetailsTitleProps) => {
  const classes = useStyles();
  const { poSubTypes } = useGlobalContext();
  const history = useHistory<DuplicatePoState>();
  const [poTypeValue, setPoTypeValue] = useState<SelectFieldOption>(emptyField);
  const [referenceNumberValue, setReferenceNumberValue] = useState('');
  const [referenceNumberErrorMessage, setReferenceNumberErrorMessage] =
    useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [isDuplicatePO, setIsDuplicatePO] = useState(false);

  const { setValue, errors } = useFormContext();
  const { poSubtypeRequired } = useUserPermissions();

  const onChangePOType = (e: any) => {
    setValue(PoFormField.PurchaseOrderSubType, e.value);
    setPoTypeValue({ value: e.value, label: e.label });
  };

  const onChangeReferenceNumber = (e: any) => {
    if (e?.length <= INPUT_MAX_LENGTH) {
      setReferenceNumberErrorMessage('');
    }
    setValue(PoFormField.ReferenceNumber, e);
    setReferenceNumberValue(e);
  };

  const handleDeletePOModalResolve = (value: string) => {
    value === 'success'
      ? setAlertMessage(DELETE_SUCCESS)
      : setAlertMessage(value);
    value === 'success' ? setAlertType('success') : setAlertType('error');
    setOpenDeleteModal(false);
    setOpenAlertModal(true);
  };

  const handleAlertClose = () => {
    setOpenAlertModal(false);
    history.push({ pathname: AppRoute.POs, search: '?draft' });
  };

  const poSubType: string | undefined = useWatch({
    name: PoFormField.PurchaseOrderSubType,
  });
  const referenceNumberIsRequired =
    poSubType === poSubTypeForWhichFieldIsRequired;

  useEffect(() => {
    if (errors[PoFormField.ReferenceNumber]) {
      setReferenceNumberErrorMessage(VALIDATIONS[PoFormField.ReferenceNumber]);
    } else {
      setReferenceNumberErrorMessage('');
    }
  }, [errors]);

  useEffect(() => {
    if (purchaseOrder?.purchaseOrderSubType) {
      const selectedOption = poSubTypes.find(
        (opt: any) => opt.value === purchaseOrder.purchaseOrderSubType
      );
      setPoTypeValue(selectedOption as any);
    }
    if (purchaseOrder?.referenceNumber && !isDuplicatePO) {
      setReferenceNumberValue(purchaseOrder?.referenceNumber);
    }
  }, [poSubTypes, purchaseOrder, setValue]);

  useEffect(() => {
    if (history?.location?.state?.duplicatePO) {
      setIsDuplicatePO(true);
      onChangeReferenceNumber('');
    }
  }, [history?.location?.state?.duplicatePO]);

  const isCancelledLabelVisible = isCanceled && !isDuplicatePO;
  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <Typography noWrap variant="h4">
          {purchaseOrderId ? `PO# ${purchaseOrderId}` : 'Create PO'}
        </Typography>
        <div className={clsx(classes.row, classes.spaceBetween)}>
          <div className={classes.infoSection}>
            {!!poSubTypes?.length && (
              <div className={classes.group}>
                <span className={classes.field}>
                  <Controller
                    name={PoFormField.PurchaseOrderSubType}
                    rules={{ required: poSubtypeRequired }}
                    required
                    render={({ name }) => (
                      <RACSearchDropdown
                        required={poSubtypeRequired}
                        inputLabel={PoFormLabel.PurchaseOrderSubType}
                        disabled={disabled}
                        name={name}
                        options={
                          poSubtypeRequired
                            ? poSubTypes
                            : [NONE_OPTIONS, ...poSubTypes]
                        }
                        OnChange={onChangePOType}
                        value={poTypeValue}
                      />
                    )}
                  />
                </span>
              </div>
            )}

            <div className={classes.group}>
              <span className={classes.referenceField}>
                <Controller
                  name={PoFormField.ReferenceNumber}
                  rules={{
                    required: referenceNumberIsRequired,
                    maxLength: INPUT_MAX_LENGTH,
                    validate: (value) => isNonEmptyValue(value),
                  }}
                  render={({ name }) => (
                    <RACTextField
                      label={PoFormLabel.ReferenceNumber}
                      disabled={disabled}
                      classes={{
                        input: classes.input,
                      }}
                      required={referenceNumberIsRequired}
                      type="text"
                      onChange={onChangeReferenceNumber}
                      value={referenceNumberValue || ''}
                      name={name}
                      errorMessage={referenceNumberErrorMessage}
                    />
                  )}
                />
              </span>
            </div>
          </div>
          {isEditMode && (
            <RACButton
              variant="contained"
              color="primary"
              className={classes.cancelButton}
              onClick={() => setOpenDeleteModal(true)}
            >
              Delete
            </RACButton>
          )}
          {isCancelledLabelVisible && (
            <div className={classes.badgeRoot}>Cancelled</div>
          )}
          {openDeleteModal && purchaseOrderId && (
            <DeletePOConfirmModal
              id={purchaseOrderId}
              onClose={() => setOpenDeleteModal(false)}
              onResolve={(value) => handleDeletePOModalResolve(value)}
            />
          )}
          {openAlertModal && (
            <AlertModal
              alertMessage={alertMessage}
              alertType={alertType}
              onClose={handleAlertClose}
            />
          )}
        </div>
      </div>
    </div>
  );
};
