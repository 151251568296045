/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  makeStyles,
  RACCOLOR,
  Typography,
} from '@rentacenter/racstrap';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ArrayField, useFieldArray, useFormContext } from 'react-hook-form';
import {
  FormAllocation,
  FormPricing,
  PurchaseOrderFormValues,
  State,
} from '../../types/types';
import { createFormPricing } from './FormPricing';
import { PricingItem } from './PricingItem';
import { ReactComponent as AccordionIcon } from '../../assets/img/accordionIcon.svg';
import { statesOfUS } from '../../constants/constants';
import { getNumberOfAllocationsWithTheSameState } from '../../utils/formAllocations';

interface Props {
  uniqueStates: string[];
  allocations: FormAllocation[];
  purchaseOrder: PurchaseOrderFormValues | null;
}

const useStyles = makeStyles(() => ({
  accordionRoot: {
    width: '100%',
    boxShadow: '0 0 0 rgb(0 0 0 / 8%)',
  },
  accordionSummaryRoot: {
    expanded: {
      minHeight: 'unset !important',
    },
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg) !important',
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 12,
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  accordionSummaryExpanded: {
    minHeight: 'unset !important',
  },
  accordionDetailsRoot: {
    background: RACCOLOR.LAVENDER_MIST,
  },
  avatarRoot: {
    width: 25,
    height: 25,
    marginLeft: 12,
    fontSize: '1rem',
    backgroundColor: RACCOLOR.ARC_LIGHT,
    color: RACCOLOR.DEEP_SKY_BLUE,
  },
}));

export const PricingList = (props: Props) => {
  const { uniqueStates, allocations, purchaseOrder } = props;
  const classes = useStyles();

  const { trigger } = useFormContext<PurchaseOrderFormValues>();

  const { fields, append, remove } = useFieldArray<FormPricing>({
    name: 'pricing',
  });

  const [expanded, setExpanded] = useState<number>(0);

  const [stateToRemove, setStateToRemove] = useState<string[]>([]);

  const onToggleAccordion = (indexParam: number) => {
    if (indexParam === expanded) {
      setExpanded(-1);
    } else {
      setExpanded(indexParam);
    }
  };

  const openStorePanel = useCallback(
    (isChanged: boolean, index: number, isStateAdded = true) => {
      setExpanded((isChanged || isStateAdded) && `panel${index}`);
    },
    []
  );

  useEffect(() => {
    if (allocations.every((f: FormAllocation) => f.isDefault)) {
      return;
    }

    const existingStates: string[] = fields
      .map((f: Partial<ArrayField<FormPricing>>) => f.state)
      .filter((f: string | undefined): f is string => !!f);

    const stateToAdd: string | undefined = uniqueStates.find(
      (state: string) => !existingStates.includes(state)
    );

    const stateToRemove: string | undefined = existingStates.find(
      (state: string) => !uniqueStates.includes(state)
    );

    if (stateToAdd) {
      const formPricing: FormPricing = createFormPricing({
        state: stateToAdd,
      });

      append(formPricing);
    }

    if (stateToRemove) {
      setStateToRemove([stateToRemove]);
    }
  }, [allocations, append, fields, uniqueStates]);

  useEffect(() => {
    const [toRemove] = stateToRemove;

    if (toRemove) {
      const existingStates: string[] = fields
        .map((f: Partial<ArrayField<FormPricing>>) => f.state)
        .filter((f: string | undefined): f is string => !!f);

      const index = existingStates.indexOf(toRemove);

      remove(index);

      setStateToRemove([]);

      trigger();
    }
  }, [stateToRemove, fields, remove, trigger]);

  return useMemo(
    () => (
      <>
        {fields.map(
          (field: Partial<ArrayField<FormPricing>>, index: number) => (
            <Accordion
              key={index}
              classes={{
                root: classes.accordionRoot,
              }}
              expanded={expanded === index}
              onChange={() => onToggleAccordion(index)}
            >
              <AccordionSummary
                classes={{
                  root: classes.accordionSummaryRoot,
                  expanded: classes.accordionSummaryExpanded,
                }}
                expandIcon={<AccordionIcon />}
              >
                <Typography>
                  {statesOfUS.find(
                    (state: State) => state.abbreviation === field.state
                  )?.name || ''}
                </Typography>
                <Avatar
                  classes={{
                    root: classes.avatarRoot,
                  }}
                >
                  {field.state && allocations.length
                    ? getNumberOfAllocationsWithTheSameState(
                        allocations,
                        field.state
                      )
                    : 0}
                </Avatar>
              </AccordionSummary>
              <AccordionDetails
                classes={{ root: classes.accordionDetailsRoot }}
              >
                <PricingItem
                  field={field}
                  index={index}
                  allocations={allocations}
                  purchaseOrder={purchaseOrder}
                />
              </AccordionDetails>
            </Accordion>
          )
        )}
      </>
    ),
    [allocations, expanded, fields, openStorePanel, purchaseOrder]
  );
};
