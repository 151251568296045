import { useContext, useEffect } from 'react';
import { CancelTokenSource } from 'axios';

import { generateCancelToken } from '../../api/client';
import { StoresDispatchContext } from '../../context/stores/StoresProvider';
import { getStoresThunk } from '../../context/stores/stores-thunks';

export const StoresFetcher = () => {
  const dispatch = useContext(StoresDispatchContext);

  useEffect(() => {
    const cancelToken: CancelTokenSource = generateCancelToken();
    getStoresThunk(dispatch, cancelToken.token);

    return cancelToken.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
