import { makeStyles, RACButton } from '@rentacenter/racstrap';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AutoNumeric } from '../../common/AutoNumeric/AutoNumeric';
import { FieldWithTooltip } from '../../common/FieldWithTooltip/FieldWithTooltip';
import { PoFormField } from '../../constants/constants';
import { PurchaseOrderFormValues } from '../../types/types';

export const costOfAlgnment = 'CostOfAlignentComponent';

interface Props {
  readonly purchaseOrder: PurchaseOrderFormValues | null;
  readonly disabled: boolean;
  readonly isChecked: boolean;
  readonly onConfirm: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.typography.pxToRem(12),
  },
  buttons: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(16),
  },
}));

export const CostOfAlignment = (props: Props) => {
  const { purchaseOrder, disabled, isChecked, onConfirm } = props;

  const { watch } = useFormContext();
  const alignmentValue = watch(PoFormField.CostOfAlignment);

  const classes = useStyles();

  return useMemo(
    () => (
      <>
        <div className={classes.root} data-testid={costOfAlgnment}>
          <FieldWithTooltip id={PoFormField.CostOfAlignment} currency>
            <Controller
              as={AutoNumeric}
              name={PoFormField.CostOfAlignment}
              imperativeMode
              placeholder=""
              disabled={disabled}
              rules={{
                max: 500,
              }}
              options={{
                minimumValue: 0,
                maximumValue: 500,
                decimalPlaces: 0,
                restrictZeroFirstChar: true,
              }}
            />
          </FieldWithTooltip>
        </div>
        <div className={classes.buttons}>
          <RACButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={isChecked && !alignmentValue}
            onClick={onConfirm}
          >
            Submit
          </RACButton>
        </div>
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [purchaseOrder?.costOfAlignment, disabled, alignmentValue, isChecked]
  );
};
