import {
  makeStyles,
  RACButton,
  RACCOLOR,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACTooltip,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../assets/img/Accordion-close.svg';
import { ReactComponent as ArrowDown } from '../../assets/img/Accordion-down.svg';
import { ApiStateWrapper } from '../../common/ApiStateWrapper/ApiStateWrapper';
import { AppRoute } from '../../config/route-config';
import { PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL } from '../../constants/constants';
import { useGlobalContext } from '../../context/global/GlobalContext';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';
import { PurchaseOrder } from '../../domain/PurchaseOrder/PurchaseOrder';
import { formatMoney } from '../../utils/utils';
import { DeletePOConfirmModal } from './DeletePOConfirmModal';
import { AlertModal } from './AlertModal';

export const testId = 'poTableTestId';

const COLUMN_TEXT_LIMIT = 30;
const DESCRIPTION_COLUMN_TEXT_LIMIT = 40;
export const DELETE_SUCCESS = 'Manual  PO deleted successfully.';
export interface POTableProps {
  data?: PurchaseOrder[];
  isLoading?: boolean;
  hasError?: boolean;
  onRefresh: () => void;
}
const useClasses = makeStyles((theme: any) => ({
  paddingTopBottom8: {
    background: RACCOLOR.WHITE,
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    '@media print': {
      paddingTop: '.2rem',
      paddingBottom: '.0rem',
    },
  },
  contentHeight: {
    height: theme.typography.pxToRem(330),
  },
  defaultCursor: {
    cursor: 'default',
  },
  poNumber: {
    color: RACCOLOR.DEEP_SKY_BLUE,
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '90%',
    height: '100%',
    position: 'absolute',
    top: 0,
  },
  details: {
    position: 'relative',
    height: theme.typography.pxToRem(81),
  },
  noPadding: {
    padding: '0 !important',
  },
  cancelButton: {
    backgroundColor: RACCOLOR.LINEN,
    padding: theme.typography.pxToRem(10),
    width: theme.typography.pxToRem(97),
    height: theme.typography.pxToRem(35),
    color: RACCOLOR.PUNCH,
    '&:hover': {
      backgroundColor: RACCOLOR.LINEN,
    },
  },
  disableButton: {
    width: theme.typography.pxToRem(97),
    height: theme.typography.pxToRem(35),
    padding: theme.typography.pxToRem(10),
    '&:disabled': {
      backgroundColor: RACCOLOR.ICEWIND_DALE,
      color: RACCOLOR.POMPEII_ASH,
    },
  },
  innerTablePadding: {
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
      3
    )} ${theme.typography.pxToRem(10)}`,
  },
}));

export const tableHeaderlist = [
  '',
  'PO#',
  'Store#',
  'Supplier',
  'Department',
  'Sub Department',
  'Condition',
  'Brand',
  'Bracket',
  'Total Cost',
  'Qty',
  'Actions',
];

const getDataById = (data: [], parentId: string): any[] | undefined => {
  return data && data.filter(({ id }: { id: string }) => parentId === id);
};

const getDepartmentById: any = (
  classificationsByDepts: any,
  purchaseOrder: PurchaseOrder
) => {
  if (!classificationsByDepts) {
    return null;
  }
  return getDataById(classificationsByDepts, purchaseOrder.department || '');
};

const trimText = (text: string | undefined) => {
  if (!text) return '';

  if (text.length > COLUMN_TEXT_LIMIT)
    return `${text.substring(0, COLUMN_TEXT_LIMIT)}...`;

  return text;
};

const trimTextWithTooltip = (
  text: string | undefined,
  length: number = COLUMN_TEXT_LIMIT
) => {
  if (!text) return '';

  if (text.length > length) {
    return (
      <RACTooltip title={text}>
        <span>{trimText(text)}</span>
      </RACTooltip>
    );
  }

  return text;
};

const getSubDepartmentById: any = (
  classificationsByDepts: any,
  purchaseOrder: PurchaseOrder
) => {
  const departments = getDepartmentById(classificationsByDepts, purchaseOrder);
  if (departments?.length) {
    return getDataById(
      departments[0].subDepartments,
      purchaseOrder.subDepartment || ''
    );
  }
};

const getBracketById: any = (
  classificationsByDepts: any,
  purchaseOrder: PurchaseOrder
) => {
  const subDepartments = getSubDepartmentById(
    classificationsByDepts,
    purchaseOrder
  );
  if (subDepartments?.length) {
    return getDataById(subDepartments[0].brackets, purchaseOrder.bracket || '');
  }
};

export const DraftPOTable = ({
  data,
  isLoading,
  hasError,
  onRefresh,
}: POTableProps) => {
  const classes = useClasses();
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);
  const {
    classifications: { classificationsByDepts },
  } = useGlobalContext();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [selectedPO, setSelectedPO] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const onToggleExpand = (newIndex: number) => {
    if (newIndex === expandedIndex) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(newIndex);
    }
  };

  const renderTableHead = () => (
    <>
      {tableHeaderlist.map((col, index) => {
        return (
          <RACTableCell
            key={index}
            classes={{
              root: clsx(classes.paddingTopBottom8),
            }}
          >
            {col}
          </RACTableCell>
        );
      })}
    </>
  );

  const renderTableContent = () => {
    if (!data?.length) return <></>;

    return renderTableContentFilled();
  };

  const handleDeletePOModalClose = () => {
    setSelectedPO('');
    setOpenDeleteModal(false);
    setOpenAlertModal(false);
  };

  const handleDeletePOModalResolve = (value: string) => {
    value === 'success'
      ? setAlertMessage(DELETE_SUCCESS)
      : setAlertMessage(value);
    value === 'success' ? setAlertType('success') : setAlertType('error');
    onRefresh();
    setOpenDeleteModal(false);
    setSelectedPO('');
    setOpenAlertModal(true);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderTableContentFilled = () => (
    <>
      {data?.map((obj, index) => {
        const hasMultiPleStores = obj.allocations.length > 1;
        const isDraftPO = obj.poStatus === PoStatus.DRAFT;
        const storeNum =
          isDraftPO && hasMultiPleStores
            ? 'Multi'
            : get(obj, 'allocations[0].storeNum');

        const generateActions = (selectedPOId: string | undefined) => {
          if (isDraftPO && selectedPOId) {
            return (
              <RACButton
                variant="contained"
                color="primary"
                className={classes.cancelButton}
                onClick={() => {
                  setSelectedPO(selectedPOId);
                  setOpenDeleteModal(true);
                }}
              >
                Delete
              </RACButton>
            );
          }
        };
        return (
          <React.Fragment key={index}>
            <RACTableRow hover backgroundColor="white">
              <RACTableCell
                onClick={() => onToggleExpand(index)}
                className={clsx(classes.defaultCursor, classes.poNumber)}
              >
                {index === expandedIndex ? <ArrowDown /> : <ArrowRight />}
              </RACTableCell>
              <RACTableCell
                className={clsx(classes.defaultCursor, classes.poNumber)}
              >
                <Link
                  style={{ color: 'inherit', textDecoration: 'unset' }}
                  to={{
                    pathname: `${AppRoute.DraftDetailsNavigation}/${obj.id}`,
                  }}
                >
                  {obj.id}
                </Link>
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {storeNum}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {trimTextWithTooltip(obj.supplier)}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {getDepartmentById(classificationsByDepts, obj)?.[0]?.name ||
                  PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {getSubDepartmentById(classificationsByDepts, obj)?.[0]?.name ||
                  PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {obj.itemCondition}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {trimTextWithTooltip(obj.brand)}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {getBracketById(classificationsByDepts, obj)?.[0]?.name || ''}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {formatMoney(obj.totalCost || '')}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {obj.totalQuantity}
              </RACTableCell>
              <RACTableCell className={classes.defaultCursor}>
                {generateActions(obj.id)}
              </RACTableCell>
            </RACTableRow>
            {expandedIndex === index && (
              <RACTableRow
                className={classes.details}
                key={`${index}-details`}
                backgroundColor={RACCOLOR.LAVENDER_MIST}
              >
                <RACTableCell className={classes.noPadding} colSpan={13}>
                  <div className={classes.row}>
                    <RACTable
                      renderTableHead={() => (
                        <>
                          <RACTableCell
                            className={classes.defaultCursor}
                          ></RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Supply Order#
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Model
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Cost per Item
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Submitted By
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Submitted Date and Time
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            Description
                          </RACTableCell>
                        </>
                      )}
                      // eslint-disable-next-line sonarjs/no-identical-functions
                      renderTableContent={() => (
                        <RACTableRow backgroundColor={RACCOLOR.LAVENDER_MIST}>
                          <RACTableCell
                            className={classes.defaultCursor}
                          ></RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {trimTextWithTooltip(obj.supplierOrderNo || 'NA')}
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {trimTextWithTooltip(
                              obj.model,
                              DESCRIPTION_COLUMN_TEXT_LIMIT
                            )}
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {formatMoney(obj.costPerItem || '')}
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {`${obj.lastModifiedBy || '--'}`}
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {new Date(obj.updatedAt || '').toLocaleDateString(
                              'en-US',
                              {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                              }
                            )}
                          </RACTableCell>
                          <RACTableCell
                            className={clsx(
                              classes.defaultCursor,
                              classes.innerTablePadding
                            )}
                          >
                            {trimTextWithTooltip(obj.description)}
                          </RACTableCell>
                        </RACTableRow>
                      )}
                    />
                  </div>
                </RACTableCell>
              </RACTableRow>
            )}
          </React.Fragment>
        );
      })}
    </>
  );

  return (
    <>
      {openDeleteModal && (
        <DeletePOConfirmModal
          id={selectedPO}
          onClose={handleDeletePOModalClose}
          onResolve={handleDeletePOModalResolve}
        />
      )}
      {openAlertModal && (
        <AlertModal
          alertMessage={alertMessage}
          alertType={alertType}
          onClose={handleDeletePOModalClose}
        />
      )}
      <ApiStateWrapper
        loading={isLoading}
        hasApiError={hasError}
        response={data}
        successContent={
          <RACTable
            renderTableHead={renderTableHead}
            renderTableContent={renderTableContent}
            stickyHeader
          />
        }
        classes={{
          loader: classes.contentHeight,
          apiError: classes.contentHeight,
          noItems: classes.contentHeight,
        }}
      />
    </>
  );
};
