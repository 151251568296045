import axios, { AxiosError, CancelTokenSource } from 'axios';
import { Auth } from '@aws-amplify/auth';

import { ApiUrls, appConfig } from '../config/app-config';
import { getSelectedStore } from '../utils/utils';
import { APIError } from '../types/types';

async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

async function getAccessToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getAccessToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

export const getTokens = async (isAccessTokenRequired: boolean) => {
  let accessToken;
  const idToken = await getJWToken();
  // local and localhost
  if (window.location.host.includes('local')) {
    if (isAccessTokenRequired) {
      accessToken = window.localStorage.getItem('ac') || undefined;
    }
    // qa, dev, uat & prod
  } else {
    if (isAccessTokenRequired) {
      accessToken = await getAccessToken();
    }
  }
  return {
    accessToken,
    idToken,
  };
};
export async function client(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  apiUrl: ApiUrls[keyof ApiUrls] = appConfig.apiUrls.micro_old,
  // access token is required for accessing AM APIs
  isAccessTokenRequired = false
) {
  const headers: any = {
    'Content-Type': 'application/json; charset=UTF-8',
  };

  const { idToken, accessToken } = await getTokens(isAccessTokenRequired);
  if (idToken) {
    headers.Authorization = `Bearer ${idToken}`;
  }
  if (accessToken) {
    headers.AccessToken = accessToken;
  }

  const storeNumber = getSelectedStore();
  if (storeNumber) {
    headers.storenumber = storeNumber;
  }

  const requestConfig: any = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }

  return axios(`${apiUrl}/${endpoint}`, requestConfig).then(
    (response) => response.data
  );
}
export const getCancelTokenSource = (): CancelTokenSource =>
  axios.CancelToken.source();

export const generateCancelToken = (): CancelTokenSource =>
  axios.CancelToken.source();

export const mapError = (e: AxiosError<APIError>) =>
  Promise.reject(
    e.response?.data || {
      errors: [
        {
          code: '500',
          message: 'Internal Server Error',
        },
      ],
    }
  );
