import React, { useEffect, useMemo } from 'react';
import {
  Controller,
  FieldErrors,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { get } from 'lodash';
import { PurchaseOrderFormValues } from '../../types/types';
import {
  PoFormField,
  PoFormLabel,
  VALIDATIONS,
} from '../../constants/constants';
import { calculateTotalQuantity } from '../../utils/formAllocations';
import { AutoNumeric } from '../../common/AutoNumeric/AutoNumeric';
import { FieldWithTooltip } from '../../common/FieldWithTooltip/FieldWithTooltip';

export const totalQuantityTestId = 'TotalQuantityComponent';

export const hasMaxQuantityError = (
  errors: FieldErrors<PurchaseOrderFormValues>
) => get(errors, `${PoFormField.TotalQuantity}.type`) === 'max';

export const TotalQuantity = () => {
  const { errors, setValue, setError, clearErrors } =
    useFormContext<PurchaseOrderFormValues>();

  const allocations = useWatch({ name: 'allocations' });
  const totalQuantity = calculateTotalQuantity(allocations as any);

  setValue(PoFormField.TotalQuantity, totalQuantity);

  useEffect(() => {
    if (totalQuantity && Number(totalQuantity) > 9999) {
      setError(PoFormField.TotalQuantity, {
        message: VALIDATIONS[PoFormField.TotalQuantity],
      });
    } else {
      clearErrors(['totalQuantity']);
    }
  }, [clearErrors, setError, totalQuantity]);

  return useMemo(
    () => (
      <div style={{ flex: 1 }} data-testid={totalQuantityTestId}>
        <FieldWithTooltip
          id={PoFormField.TotalQuantity}
          title={PoFormLabel.TotalQuantity}
          required={true}
          errorMessage={
            errors?.totalQuantity ? VALIDATIONS[PoFormField.TotalQuantity] : ''
          }
        >
          <Controller
            as={AutoNumeric}
            name={PoFormField.TotalQuantity}
            defaultValue=""
            imperativeMode
            imperativeValue={totalQuantity}
            placeholder=""
            options={{
              decimalPlaces: 0,
            }}
            hasError={!!errors?.totalQuantity}
            disabled
            rules={{
              required: true,
              max: 9999,
            }}
          />
        </FieldWithTooltip>
      </div>
    ),
    [errors?.totalQuantity, totalQuantity]
  );
};
