import React, { useEffect } from 'react';
import { useAsync } from 'react-async';
import { getClassifications } from '../api/classifications';
import { setClassificationsAction } from '../context/classifications/classificationsActions';
import { useGlobalContext } from '../context/global/GlobalContext';

export const ClassificationsFetcher = () => {
  const { dispatch } = useGlobalContext();

  const classificationsData = useAsync({
    promiseFn: getClassifications,
  });

  useEffect(() => {
    if (classificationsData) {
      dispatch(
        setClassificationsAction({
          ...classificationsData.data,
          isPending: classificationsData.isPending,
        })
      );
    }
  }, [classificationsData, dispatch]);

  return <></>;
};
