import React, { useState } from 'react';
import { RACCheckBox } from '@rentacenter/racstrap';
import { CostOfAlignment } from '../../InventoryDetailsItems/CostOfAlignment';

export interface AlignBoxProps {
  purchaseOrder: any;
  onConfirm: () => void;
}

export const AlignmentBox = ({ purchaseOrder, onConfirm }: AlignBoxProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const onToggleCheckbox = (value: boolean) => {
    setIsChecked(value);
  };

  return (
    <div>
      <h2>Alignment</h2>
      <div>
        <RACCheckBox
          color="primary"
          checked={isChecked}
          label="I would like to add alignment to this PO"
          onChange={(event) => {
            onToggleCheckbox(event.target.checked);
          }}
        />
        <>
          <CostOfAlignment
            isChecked={isChecked}
            purchaseOrder={purchaseOrder}
            disabled={!isChecked}
            onConfirm={onConfirm}
          />
        </>
      </div>
    </div>
  );
};
