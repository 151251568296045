import { makeStyles, RACCard, Typography } from '@rentacenter/racstrap';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { PoFormField, PoFormLabel } from '../../constants/constants';
import { PurchaseOrderFormValues } from '../../types/types';
import { Bracket } from '../InventoryDetailsItems/Bracket';
import { ClassificationsType } from '../InventoryDetailsItems/ClassificationsType';
import { Provider } from '../InventoryDetailsItems/context/Provider';
import { Department } from '../InventoryDetailsItems/Department';
import { InitialClassificationsSetter } from '../InventoryDetailsItems/InitialClassificationsSetter';
import { SubDepartment } from '../InventoryDetailsItems/SubDepartment';
import { SubType } from '../InventoryDetailsItems/SubType';

export interface ItemDetailsProps {
  purchaseOrder?: PurchaseOrderFormValues | null;
  isViewMode?: boolean;
}

const testId = 'inventoryTestId';
const useStyles = makeStyles((theme: any) => ({
  root: {
    marginBottom: '1.5rem',
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(8),
  },
  cardContentContainer: {
    padding: '1.25rem',
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  field: {
    width: theme.typography.pxToRem(200),
  },
}));

export const InventoryDetails = ({ purchaseOrder }: ItemDetailsProps) => {
  const classes = useStyles();
  const { register, getValues } = useFormContext();

  return (
    <Provider>
      <InitialClassificationsSetter />
      <div className={classes.root} data-testid={testId}>
        <div className={classes.row}>
          <Typography noWrap variant="h4">
            Inventory Details
          </Typography>
        </div>
        <RACCard>
          <div className={classes.cardContentContainer}>
            <span className={classes.field}>
              <Department
                name={PoFormField.Department}
                label={PoFormLabel.Department}
                purchaseOrder={purchaseOrder || null}
              />
            </span>
            <span className={classes.field}>
              <SubDepartment
                name={PoFormField.SubDepartment}
                label={PoFormLabel.SubDepartment}
                purchaseOrder={purchaseOrder || null}
              />
            </span>
            <span className={classes.field}>
              <Bracket
                name={PoFormField.Bracket}
                label={PoFormLabel.Bracket}
                purchaseOrder={purchaseOrder || null}
              />
            </span>
            <span className={classes.field}>
              <ClassificationsType
                name={PoFormField.ItemType}
                label={PoFormLabel.Type}
                purchaseOrder={purchaseOrder || null}
              />
            </span>
            <span className={classes.field}>
              <SubType
                name={PoFormField.ItemSubType}
                label={PoFormLabel.SubType}
                purchaseOrder={purchaseOrder || null}
              />
            </span>
            <input
              type="hidden"
              name={PoFormField.StartClassificationsSelect}
              ref={register}
              defaultValue={
                purchaseOrder?.startClassificationsSelect ||
                (getValues().startClassificationsSelect as string)
              }
            />
          </div>
        </RACCard>
      </div>
    </Provider>
  );
};
