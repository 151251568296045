import { FormPricingKeys, State } from '../types/types';
import { FormPricingConfig } from '../utils/formPricing';

export const ProductType = 'RPDMANPO';

export enum ProductPermissions {
  PO_SUBTYPE_REQUIRED = 'PO_SUBTYPE_REQUIRED',
  CAN_SELECT_PO_SUB_TYPE = 'PO_SUB_FILTER',
  AUTO_PRICING = 'PO_AUTO_PRICING',
  EDITABLE_PRICING = 'WEEKLY_RATE_TERM',
}

export const REQUIRED_FIELD_MESSAGE = 'This field is required';
export const PO_NUMBER_INVALID_MESSAGE = 'Invalid PO#';
export const TO_DATE_TOO_BIG = 'To date must be 30 days after from date';
export const INVALID_TO_DATE = 'To date must be after from date';

export const ALL_OPTION = 'All';

export const STORE_NUMBER_KEY = 'storeNumber';
export const CANCELLED_SUCCESS = 'Manual  PO cancelled successfully.';
export const UPDATE_SUCCESS = 'Manual PO updated successfully.';

export const CANCEL_CREATE_LOSE_WORK =
  'By Cancelling you will lose all data present in the form.';
export const CANCEL_EDIT_LOSE_WORK =
  'By Cancelling you will lose all data entered in this edit session.';

export const CONFIRM_MESSAGE = 'Do you confirm this action?';
export const INVALID_UPDATE_ATTEMPT =
  'Please set an amount greater than previously received and less than the total number of quantities originally ordered!';

export const FULL_API_ERROR_MESSAGE =
  'Unable to fetch the data. Please try again later.';

export const statesOfUS: State[] = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const submittedPOCancelMessage = 'submittedPOCancelMessage';
export const draftPOCancelMessage = 'draftPOCancelMessage';

export const selectPlaceholder = 'Select';

export enum PoFormField {
  Id = 'id',
  PurchaseOrderSubType = 'purchaseOrderSubType',
  ReferenceNumber = 'referenceNumber',
  PoStatus = 'poStatus',
  Supplier = 'supplier',
  Brand = 'brand',
  Model = 'model',
  SupplierOrderNo = 'supplierOrderNo',
  Description = 'description',
  CostPerItem = 'costPerItem',
  TotalCost = 'totalCost',
  TotalQuantity = 'totalQuantity',
  StartClassificationsSelect = 'startClassificationsSelect',
  Department = 'department',
  SubDepartment = 'subDepartment',
  Bracket = 'bracket',
  ItemType = 'itemType',
  ItemSubType = 'itemSubType',
  CostOfAlignment = 'costOfAlignment',
  Condition = 'itemCondition',
  Quantity = 'quantity',
  WeeklyRent = 'weeklyRent',
  WeeklyTerm = 'weeklyTerm',
}

export interface GenericObject {
  [key: string]: any;
}

export enum PoFormLabel {
  PurchaseOrderSubType = 'PO Type',
  ReferenceNumber = 'Purchase Reference Number',
  Supplier = 'Supplier',
  Brand = 'Brand',
  Model = 'Model',
  Condition = 'Condition',
  SupplierOrderNo = 'Supplier order number',
  Description = 'Description',
  Department = 'Department',
  SubDepartment = 'Sub-department',
  Bracket = 'Bracket',
  Type = 'Type',
  SubType = 'Sub-Type',
  TotalQuantity = 'Total quantity',
  CostPerItem = 'Cost per item',
  TotalCost = 'Total cost',
  Quantity = 'Quantity',
  Store = 'Store',
  BiWeeklyRate = 'Bi-Weekly rate',
  BiWeeklyTerm = 'Bi-Weekly term',
  MonthlyRate = 'Monthly rate',
  MonthlyTerm = 'Monthly term',
  SemiMonthlyRate = 'Semi-Monthly rate',
  SemiMonthlyTerm = 'Semi-Monthly term',
  TotalRTOPrice = 'Total RTO price',
  CashPrice = 'Cash price',
  WeeklyRate = 'Weekly rate',
  WeeklyTerm = 'Weekly term',
  CostOfAlignment = 'Cost of Alignment',
}

export const TIRES_DEPARTMENT = '46';
export const TIRES_SUB_DEPARTMENT = '460';
export const TIRES_CLASSIFICATIONS_SELECT = 'department';
export const TIRES_MANUALLY_RECEIVED_BRACKET = '4600';
export const TIRES_WHEEL_ITEM_TYPE = 'WHE';
export const TIRES_WHEEL_ITEM_SUB_TYPE = 'TIR';

export const emptyField = {
  value: '',
  label: 'Please Select',
};

export const ClassificationsInputPlaceholder = 'Please Select';

export const orderedPricingFields: Array<FormPricingKeys> = [
  FormPricingKeys.WeeklyRent,
  FormPricingKeys.WeeklyTerm,
  FormPricingKeys.TotalRtoPrice,
  FormPricingKeys.BiWeeklyRent,
  FormPricingKeys.BiWeeklyTerm,
  FormPricingKeys.SemiMonthlyRent,
  FormPricingKeys.SemiMonthlyTerm,
  FormPricingKeys.MonthlyRent,
  FormPricingKeys.MonthlyTerm,
];

export const pricingConfig: FormPricingConfig = {
  weeklyRent: {
    label: PoFormLabel.WeeklyRate,
    validations: { min: 1, max: 900 },
  },
  weeklyTerm: {
    label: PoFormLabel.WeeklyTerm,
    validations: { min: 1, max: 200 },
  },
  totalRtoPrice: { label: PoFormLabel.TotalRTOPrice, validations: { min: 1 } },
  biWeeklyRent: { label: PoFormLabel.BiWeeklyRate, validations: { min: 1 } },
  biWeeklyTerm: { label: PoFormLabel.BiWeeklyTerm, validations: { min: 1 } },
  semiMonthlyRent: {
    label: PoFormLabel.SemiMonthlyRate,
    validations: { min: 1 },
  },
  semiMonthlyTerm: {
    label: PoFormLabel.SemiMonthlyTerm,
    validations: { min: 1 },
  },
  monthlyRent: { label: PoFormLabel.MonthlyRate, validations: { min: 1 } },
  monthlyTerm: { label: PoFormLabel.MonthlyTerm, validations: { min: 1 } },
};

export const poSubTypeForWhichFieldIsRequired = 'PCRD';

export const GET_STORES_LIMIT = 125;

export const PURCHASE_ORDER_CARD_FIELD_EMPTY_LABEL = '-';
export const PURCHASE_ORDER_CARD_FIELD_EMPTY_VALUE = 'N/A';

export const INPUT_MAX_LENGTH = '50';

export const VALIDATIONS: { [k: string]: any } = {
  [PoFormField.PurchaseOrderSubType]: '',
  [PoFormField.ReferenceNumber]: 'Add number (50 max char)',
  [PoFormField.PoStatus]: '',
  [PoFormField.Supplier]: 'Name (240 max char)',
  [PoFormField.SupplierOrderNo]: 'Add supplier order no.',
  [PoFormField.Brand]: 'Name (50 max char)',
  [PoFormField.Model]: 'Model (50 max char)',
  [PoFormField.SupplierOrderNo]: 'Add number (150 max char)',
  [PoFormField.Description]: 'Description (255 max char)',
  [PoFormField.CostPerItem]: 'Add cost',
  [PoFormField.Department]: '',
  [PoFormField.SubDepartment]: '',
  [PoFormField.Bracket]: '',
  [PoFormField.ItemType]: '',
  [PoFormField.ItemSubType]: '',
  [PoFormField.CostOfAlignment]: '',
  [PoFormField.Condition]: '',
  [PoFormField.Quantity]: 'Add quantity',
  [PoFormField.TotalQuantity]:
    'The maximum number of items is 9,999. Please adjust your input to not exceed this' +
    ' value',
  [PoFormField.WeeklyRent]: 'Please add weekly rate value between 1 and 900',
  [PoFormField.WeeklyTerm]: 'Please add weekly term value between 1 and 200',
};

export const MANUAL_PO_API_LIMIT = 15;
export const INITIAL_OFFSET = 0;
export const NONE_OPTIONS = {
  value: '',
  label: 'None',
};

export const SubmittdPOStatusTypes = ['CV', 'OC', 'PRCV', 'RCV', 'SMT', 'COMP'];

export const DraftPOStatusTypes = {
  DRAFT: 'DFT',
  DELETED: 'DLT',
  COMPLETED: 'COMP',
};

export const PURCHASE_ORDER_TYPE_CACHE_KEY = 'purchase-order-type-key';
export enum ManagePOOperation {
  Cancel = 'cancel',
  Update = 'update',
}

export const purchaseOrderStatusTypes = [
  {
    referenceCode: 'APR',
    description: 'Approved - Released to Store',
  },
  {
    referenceCode: 'CV',
    description: 'Cancel Viewed',
  },
  {
    referenceCode: 'DN',
    description: 'Denied',
  },
  {
    referenceCode: 'EDI',
    description: 'Submitted to Vendor EDI',
  },
  {
    referenceCode: 'OC',
    description: 'Open Cancel',
  },
  {
    referenceCode: 'OS',
    description: 'Open Send',
  },
  {
    referenceCode: 'PAPR',
    description: 'Pending Approval',
  },
  {
    referenceCode: 'PRCV',
    description: 'Partially Received',
  },
  {
    referenceCode: 'RCV',
    description: 'Received',
  },
  {
    referenceCode: 'SMT',
    description: 'Submitted to Vendor',
  },
  {
    referenceCode: 'DFT',
    description: 'DRAFT',
  },
  {
    referenceCode: 'DLT',
    description: 'DELETED',
  },
  {
    referenceCode: 'COMP',
    description: 'COMPLETED',
  },
];

export enum POSearchFormLabel {
  PONumber = 'PO #',
  SearchBySubmittedDate = 'Search by Submitted Date: ',
}

export const CUSTOM_API_MESSAGE_FETCH_PO =
  'Please select one or multiple store in order to see POs.';

export const OPERATIONS = {
  CANCEL: 'cancel',
  DRAFT_DELETE: 'draftDelete',
};

export enum REDIRECT_ORIGIN {
  MANUAL_PO_LIST = 'manual-po-list',
}

export enum REDIRECT_ACTION {
  RECEIVE_PO = 'receivepo',
}

export enum REDIRECT_PARAM_VALUE_KEY {
  PO_NUMBER = 'ponumber',
}
export const DEFAULT_NR_OF_DECIMALS = 2;

export enum PO_TYPE {
  MANUAL_PO = 'MANUAL',
}
