/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@rentacenter/racstrap';
import React, { useEffect, useState } from 'react';
import { getDraftPurchaseOrders } from '../../api/purchaseOrders';
import { DraftPOTable } from './DraftPOTable';

const useStyles = makeStyles({
  tableWrapper: {
    overflow: 'auto',
    marginTop: '12px',
  },
});
export const DraftPOs = () => {
  const classes = useStyles();
  const [draftPOs, setDraftPOs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    getDraftPurchaseOrders()
      .then((response) => {
        setDraftPOs(response?.draftPurchaseOrders);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
        setHasError(false);
      });
  }, [refreshCount]);

  const onRefresh = () => {
    setRefreshCount(refreshCount + 1);
  };
  return (
    <>
      <div className={classes.tableWrapper}>
        <DraftPOTable
          isLoading={isLoading}
          data={draftPOs}
          onRefresh={onRefresh}
          hasError={hasError}
        />
      </div>
    </>
  );
};
