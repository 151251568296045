import React, { useRef, useState } from 'react';
import clsx from 'clsx';

import styles from './SearchSelection.module.scss';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { Button } from '@rentacenter/racstrap';
import OutsideClickHandler from 'react-outside-click-handler';
import { SearchTag } from './search-tag/SearchTag';
import { getStoreSearchName, Store } from '../../../domain/Store';
import { Popper, makeStyles } from '@rentacenter/racstrap';
import { noOp } from '../../../utils/utils';

interface Props {
  readonly isCollapsed: boolean;
  readonly selectedStores: Store[];
  readonly onChange?: (value: Store[]) => void;
  readonly isDisabled: boolean;
}

const noSelectionMessage = 'No stores selected';
const selectionMessage = 'Stores selected : ';

const useStyles = makeStyles((theme) => ({
  popRoot: {
    zIndex: 40,
    padding: theme.typography.pxToRem(5),
    width: theme.typography.pxToRem(300),
  },
  storeInfo: {
    backgroundColor: 'white',
    left: '-220px',
    position: 'relative',
    padding: theme.typography.pxToRem(10),
    overflow: 'auto',
    border: '0.0625rem solid #C4C4C4',
    borderTopWidth: '0',
    top: '-82px', //-3px
    height: theme.typography.pxToRem(280),
  },
  arrow: {
    position: 'absolute',
    top: theme.typography.pxToRem(25),
    right: theme.typography.pxToRem(10),
    color: '#C4C4C4',
    cursor: 'pointer',
    zIndex: 2600,
  },
  collapsed: {
    visibility: 'hidden',
  },
  message: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    marginTop: theme.typography.pxToRem(5),
    marginBottom: theme.typography.pxToRem(5),
  },
}));

export const SearchSelection = ({
  isCollapsed,
  selectedStores,
  onChange = noOp,
  isDisabled = false,
}: Props) => {
  const classes = useStyles();
  const [showStores, setShowStores] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const wrapper = useRef<HTMLDivElement>(null);
  // const open = Boolean(anchorEl);

  const removeStore = (storeIndex: number) => {
    const stores = [...selectedStores];
    stores.splice(storeIndex, 1);

    onChange(stores);
  };

  const getFirstTwoStores = () => {
    const firstTwoStores = selectedStores.slice(0, 2);

    return (
      <>
        <div>
          {firstTwoStores.map((store) => (
            <SearchTag key={store.storeNum} removable={false}>
              {' '}
              {getStoreSearchName(store)}
            </SearchTag>
          ))}
        </div>
      </>
    );
  };

  const toggleShowStores = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setShowStores(!showStores);
  };
  // const clearStores = () => {
  //   onChange([]);
  // };

  return (
    <div
      className={clsx({
        [styles.selectionBox]: true,
        [styles.collapsed]: isCollapsed,
        [styles.disabled]: isDisabled,
      })}
    >
      {isCollapsed && (
        <>
          <OutsideClickHandler
            onOutsideClick={() => {
              setAnchorEl(null);
              setShowStores(false);
            }}
          >
            <div className={styles.hiddenCount}>
              <Button
                className={classes.arrow}
                onClick={toggleShowStores}
                startIcon={!showStores ? <ArrowDropDown /> : <ArrowDropUp />}
              >
                <span style={{ color: 'black' }}> {selectedStores.length}</span>
              </Button>
              {/* <ArrowDropDown className={classes.arrow} onClick={toggleShowStores} /> */}
              <span> {getFirstTwoStores()} </span>
            </div>
            <div>
              <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={classes.popRoot}
              >
                <div className={classes.storeInfo}>
                  {selectedStores.length === 0 ? (
                    <div className={classes.message}>{noSelectionMessage}</div>
                  ) : (
                    <div className={classes.message}>
                      {selectionMessage + selectedStores.length}
                    </div>
                  )}

                  {
                    //eslint-disable-next-line
                    selectedStores.map((store) => (
                      <SearchTag key={store.storeNum} removable={false}>
                        {getStoreSearchName(store)}
                      </SearchTag>
                    ))
                  }
                </div>
              </Popper>
            </div>
          </OutsideClickHandler>
        </>
      )}
      <div
        className={clsx({
          [styles.entriesWrap]: true,
          [classes.collapsed]: isCollapsed,
        })}
      >
        {/* {!isCollapsed && <label>Selected Stores</label>} */}

        <div className={styles.entries} ref={wrapper}>
          {selectedStores.length === 0 && (
            <div className={styles.emptyMessage}>{noSelectionMessage}</div>
          )}
          {selectedStores.map((store, index) => (
            <SearchTag key={store.storeNum} onRemove={() => removeStore(index)}>
              {getStoreSearchName(store)}
            </SearchTag>
          ))}
        </div>
      </div>
      {/*       
      {!isCollapsed && (
        <div className={styles.entriesWrap}>
          <label>Selected Stores</label>
          <div className={styles.entries} ref={wrapper}>
            {selectedStores.length === 0 && (
              <div className={styles.emptyMessage}>{noSelectionMessage}</div>
            )}
            {selectedStores.map((store, index) => (
              <SearchTag key={store.storeNum} onRemove={() => removeStore(index)}>
                {getStoreSearchName(store)}
              </SearchTag>
            ))}
          </div>
        </div>
      )} */}
    </div>
  );
};
