/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { makeStyles, RACSearchDropdown } from '@rentacenter/racstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getStoreDetailByNumber } from '../../api/store';
import { AutoNumeric } from '../../common/AutoNumeric/AutoNumeric';
import { FieldWithTooltip } from '../../common/FieldWithTooltip/FieldWithTooltip';
import {
  GenericObject,
  PoFormField,
  PoFormLabel,
  VALIDATIONS,
} from '../../constants/constants';
import { createStoreOption, getStoreByNumber, Store } from '../../domain/Store';
import { SelectFieldOption, StoreDetailResponse } from '../../types/types';

export interface AllocationItemProps {
  readonly index: number;
  readonly allocationField: any;
  readonly onRemoveButtonClick: (index: number) => void;
  readonly disabled: boolean;
  readonly stores: Store[];
  readonly selectStoreOptions: SelectFieldOption[];
  readonly isSearchable?: boolean;
  readonly isStoreLoading?: boolean;
  readonly children: any;
  readonly onSearch?: (text: string) => void;
}

const HiddenField = (props: GenericObject) => <input {...props} />;

const useStyles = makeStyles((theme: any) => ({
  row: {
    display: 'flex',
    gap: theme.typography.pxToRem(32),
    marginTop: theme.typography.pxToRem(8),
  },
  smallField: {
    width: theme.typography.pxToRem(80),
  },
  largeField: {
    width: theme.typography.pxToRem(230),
  },

  button: {
    height: theme.typography.pxToRem(43),
  },
  errorMessage: {
    position: 'absolute',
  },
}));
export const AllocationItem = (props: AllocationItemProps) => {
  const classes = useStyles();
  const {
    index,
    disabled,
    allocationField,
    stores,
    selectStoreOptions,
    onSearch,
    isStoreLoading,
    children,
  } = props;

  // We are fetching the first 125 (configurable number) of stores, but the PO can be assigned to a store that
  // is not included into the initial fetch.
  // In this case, we are fetching the store details for that specific store
  // (because name is missing and we cannot display it in the dropdown).
  const [fetchedStore, setFetchedStore] = useState<Store>();
  const [isFetchStoreOptionLoading, setIsFetchStoreOptionLoading] =
    useState(false);
  const [selectedStore, setSelectedStore] = useState<
    SelectFieldOption | undefined
  >();
  const { setValue, errors, getValues } = useFormContext();

  const formValues = getValues();
  useEffect(() => {
    const newStoreNum = formValues?.allocations?.[index]?.storeNum;

    if (newStoreNum) {
      const foundStore = getStoreByNumber(
        [...stores, fetchedStore].filter((option) => !!option) as Store[],
        newStoreNum
      );

      if (foundStore) {
        setSelectedStore(createStoreOption(foundStore));
      }
    }
  }, [formValues?.allocations?.[index]?.storeNum, fetchedStore]);

  const setStoreState = useCallback(
    (storeNum: string) => {
      const store: Store | undefined = getStoreByNumber(stores, storeNum);

      if (store) {
        setValue(`allocations[${index}].state`, store.state.abbreviation, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    },
    [index, stores, setValue]
  );

  const setStoreNumber = useCallback(
    (storeNum: string) => {
      setValue(`allocations[${index}].storeNum`, storeNum, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [index, setValue]
  );

  const onStoreChange = (event: any) => {
    const { value } = event;
    setStoreState(value);
    setStoreNumber(value);
  };

  const errorMessage = errors?.allocations?.[index]?.quantity
    ? VALIDATIONS[PoFormField.Quantity]
    : '';

  useEffect(() => {
    if (!selectStoreOptions?.length || !allocationField?.storeNum) return;

    const storeFromOptions = selectStoreOptions.findIndex(
      (option) => option.value === allocationField.storeNum
    );
    if (storeFromOptions < 0) {
      setIsFetchStoreOptionLoading(true);
      getStoreDetailByNumber(allocationField.storeNum)
        .then((data: StoreDetailResponse) => {
          if (data?.response?.length) {
            setFetchedStore({
              ...data.response[0],
              storeNum: data.response[0]?.storeNumber,
            } as any);
          }
        })
        .catch(() => setFetchedStore(undefined))
        .finally(() => setIsFetchStoreOptionLoading(false));
    }
  }, []);

  return (
    <div className={classes.row}>
      <span className={classes.smallField}>
        <FieldWithTooltip
          id={PoFormLabel.Quantity}
          title={PoFormLabel.Quantity}
          required
          errorMessage={errorMessage}
        >
          <Controller
            as={AutoNumeric}
            name={`allocations[${index}].quantity`}
            options={{
              minimumValue: 0,
              maximumValue: 9999,
              decimalPlaces: 0,
              restrictZeroFirstChar: true,
            }}
            disabled={disabled}
            rules={{ required: true, min: 1 }}
          />
        </FieldWithTooltip>
      </span>
      <span className={classes.largeField}>
        <Controller
          as={HiddenField}
          type="hidden"
          name={`allocations[${index}].storeNum`}
          rules={{ required: true }}
        />
        <Controller
          as={HiddenField}
          type="hidden"
          name={`allocations[${index}].draftPoDetailsId`}
          rules={{ required: false }}
        />
        <RACSearchDropdown
          name={`allocations[${index}].storeNum`}
          // isLoading and onInputChange are passed to react-select
          //@ts-ignore
          isLoading={isStoreLoading || isFetchStoreOptionLoading}
          //@ts-ignore
          onInputChange={onSearch}
          disabled={disabled}
          inputLabel="Store"
          options={selectStoreOptions}
          OnChange={onStoreChange}
          required
          value={selectedStore}
          errormessage={errors?.allocations?.[index]?.storeNum?.message || ''}
        />
        <Controller
          type="hidden"
          name={`allocations[${index}].state`}
          defaultValue={allocationField.state}
          rules={{ required: true }}
          render={() => <span />}
        />
      </span>
      {children}
    </div>
  );
};
