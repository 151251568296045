import React, { useMemo } from 'react';
import { PurchaseOrderFormValues } from '../../types/types';
import { useAutoPricingState } from './context/AutoPricingProvider';
import { PricingList } from './PricingList';
import { LoadingOverlay } from '../../common/LoadingOverlay/LoadingOverlay';

export const pricingTestId = 'pricingComponent';

export interface PricingListProps {
  readonly purchaseOrder: PurchaseOrderFormValues | null;
  readonly uniqueStates: string[];
  readonly allocations: any;
}

export const Pricing = ({
  purchaseOrder,
  uniqueStates,
  allocations,
}: PricingListProps) => {
  const { autoPriceLoading } = useAutoPricingState();

  return useMemo(
    () => (
      <>
        {autoPriceLoading && <LoadingOverlay />}
        <div style={{ width: '100%' }} data-testid={pricingTestId}>
          <PricingList
            uniqueStates={uniqueStates}
            allocations={allocations as any}
            purchaseOrder={purchaseOrder}
          />
        </div>
      </>
    ),
    [autoPriceLoading, uniqueStates, allocations, purchaseOrder]
  );
};
