import { RACSearchDropdown } from '@rentacenter/racstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ClassificationsInputPlaceholder,
  emptyField,
  PoFormField,
} from '../../constants/constants';
import { ClassificationType } from '../../domain/PurchaseOrder/ClassificationType';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';
import { SelectedFieldOption } from '../../types/types';
import { filterById, populateSelectByChild } from '../../utils/utils';
import {
  useClassificationsDispatch,
  useClassificationsState,
} from './context/Provider';
import { ClassificationsComponentProps } from './context/types';

export const subdepartmentTestId = 'subdepartmentComponent';

// eslint-disable-next-line
export const SubDepartment = (props: ClassificationsComponentProps) => {
  const { name, label, purchaseOrder } = props;
  const { setValue } = useFormContext();
  const { startWithDepartment, subDepartments } = useClassificationsState();
  const dispatch = useClassificationsDispatch();
  const [itemValue, setItemValue] = useState(emptyField);

  useEffect(() => {
    const poSubDepartment = purchaseOrder && purchaseOrder.subDepartment;
    const selectedSubDepartment: {
      name: string;
      id: string;
      brackets: [];
    } | null =
      subDepartments &&
      poSubDepartment &&
      filterById(subDepartments, poSubDepartment)[0];

    if (subDepartments && subDepartments.length === 1) {
      setItemValue({
        value: subDepartments[0].id,
        label: subDepartments[0].name,
      });

      dispatch({
        type: ClassificationType.SET_SINGLE_BRACKET,
        payload: subDepartments[0].brackets,
      });
    } else {
      setItemValue({
        value: (selectedSubDepartment && selectedSubDepartment.id) || '',
        label:
          (selectedSubDepartment && selectedSubDepartment.name) ||
          ClassificationsInputPlaceholder,
      });

      selectedSubDepartment && selectedSubDepartment.brackets
        ? dispatch({
            type: ClassificationType.SET_SUBDEPARTMENTS,
            payload: selectedSubDepartment.brackets,
          })
        : dispatch({
            type: ClassificationType.CLEAR_BRACKETS,
            payload: [],
          });
    }
    // eslint-disable-next-line
  }, [subDepartments, dispatch]);

  const handleChange = useCallback(
    // eslint-disable-next-line
    (selected: SelectedFieldOption) => {
      const { value, label, brackets } = selected;
      setItemValue({ value, label });
      setValue(name, value, { shouldValidate: true, shouldDirty: true });

      if (purchaseOrder && purchaseOrder.subDepartment !== value) {
        purchaseOrder.bracket = '';
        if (startWithDepartment) {
          purchaseOrder.itemType = '';
          purchaseOrder.itemSubType = '';
        }
      }

      if (brackets) {
        dispatch({
          type: ClassificationType.SET_SUBDEPARTMENTS,
          payload: brackets,
        });
        if (brackets.length === 1) {
          setValue(PoFormField.Bracket, brackets[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setValue(PoFormField.Bracket, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
        if (startWithDepartment) {
          const filterdTypes = brackets[0].types;
          const filterdSubTypes = filterdTypes[0].subTypes;
          if (filterdTypes.length === 1) {
            setValue(PoFormField.ItemType, filterdTypes[0].id, {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else {
            setValue(PoFormField.ItemType, '', {
              shouldValidate: true,
              shouldDirty: true,
            });
          }

          if (filterdSubTypes.length === 1) {
            setValue(PoFormField.ItemSubType, filterdSubTypes[0].id, {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else {
            setValue(PoFormField.ItemSubType, '', {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        }
      }
    },
    [dispatch, name, purchaseOrder, setValue, startWithDepartment]
  );

  const isViewMode =
    purchaseOrder &&
    (purchaseOrder.poStatus === PoStatus.SUBMITTED ||
      purchaseOrder.poStatus === PoStatus.OPEN_CANCELLED);

  return useMemo(
    () => (
      <Controller
        name={name}
        rules={{ required: true }}
        render={({ name }) => (
          <RACSearchDropdown
            name={name}
            inputLabel={label}
            options={populateSelectByChild(subDepartments, 'brackets') || []}
            OnChange={handleChange}
            required
            disabled={
              isViewMode || (subDepartments && subDepartments.length === 0)
            }
            value={itemValue}
          />
        )}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isViewMode,
      purchaseOrder,
      subDepartments,
      handleChange,
      itemValue,
      label,
      name,
    ]
  );
};
