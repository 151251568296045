import { RACSearchDropdown } from '@rentacenter/racstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ClassificationsInputPlaceholder,
  emptyField,
  PoFormField,
} from '../../constants/constants';
import { ClassificationType } from '../../domain/PurchaseOrder/ClassificationType';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';
import { SelectedFieldOption } from '../../types/types';
import { filterById, populateSelectByChild } from '../../utils/utils';
import {
  useClassificationsDispatch,
  useClassificationsState,
} from './context/Provider';
import { ClassificationsComponentProps } from './context/types';

export const bracketComponentTestId = 'bracketComponent';

// eslint-disable-next-line
export const Bracket = (props: ClassificationsComponentProps) => {
  const { name, label, purchaseOrder } = props;
  const { setValue } = useFormContext();
  const { brackets, startWithDepartment } = useClassificationsState();
  const dispatch = useClassificationsDispatch();
  const [itemValue, setItemValue] = useState(emptyField);

  useEffect(
    // eslint-disable-next-line
    () => {
      const poSubDepartment = purchaseOrder && purchaseOrder.subDepartment;
      const poBracket = purchaseOrder && purchaseOrder.bracket;
      const selectedBraket: {
        name: string;
        id: string;
        types: [];
      } | null =
        poSubDepartment && poBracket && filterById(brackets, poBracket)[0];

      if (brackets.length === 1) {
        setItemValue({ value: brackets[0].id, label: brackets[0].name });

        if (startWithDepartment) {
          dispatch({
            type: ClassificationType.SET_SINGLE_TYPE,
            payload: brackets[0].types,
          });
        }
      } else {
        setItemValue({
          value: (selectedBraket && selectedBraket.id) || '',
          label:
            (selectedBraket && selectedBraket.name) ||
            ClassificationsInputPlaceholder,
        });

        if (startWithDepartment) {
          dispatch({
            type: ClassificationType.CLEAR_TYPES,
            payload: [],
          });

          if (selectedBraket) {
            dispatch({
              type: ClassificationType.SET_BRACKETS,
              payload: selectedBraket.types,
            });
          }
        }
      }
    },
    // eslint-disable-next-line
    [brackets, dispatch, startWithDepartment]
  );

  const handleChange = useCallback(
    ({ value, label, types }: SelectedFieldOption) => {
      setItemValue({ value, label });
      setValue(name, value, { shouldValidate: true, shouldDirty: true });

      !startWithDepartment &&
        dispatch({
          type: ClassificationType.SET_BRACKET_END,
        });

      if (startWithDepartment && types) {
        let filteredSubTypes = types[0].subTypes;

        if (purchaseOrder && purchaseOrder.bracket !== value) {
          purchaseOrder.itemType = '';
          purchaseOrder.itemSubType = '';
        }

        dispatch({
          type: ClassificationType.SET_BRACKETS,
          payload: types,
        });

        if (types.length === 1) {
          setValue(PoFormField.ItemType, types[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          filteredSubTypes = [];
          setValue(PoFormField.ItemType, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }

        if (filteredSubTypes && filteredSubTypes.length === 1) {
          setValue(PoFormField.ItemSubType, filteredSubTypes[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          filteredSubTypes = [];
          setValue(PoFormField.ItemSubType, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
    },
    [dispatch, name, purchaseOrder, setValue, startWithDepartment]
  );

  return useMemo(() => {
    const isViewMode =
      purchaseOrder?.poStatus === PoStatus.SUBMITTED ||
      purchaseOrder?.poStatus === PoStatus.OPEN_CANCELLED;
    return (
      <Controller
        name={name}
        rules={{ required: true }}
        render={({ name }) => (
          <RACSearchDropdown
            name={name}
            inputLabel={label}
            options={populateSelectByChild(brackets, 'types') || []}
            OnChange={handleChange}
            required
            disabled={isViewMode || !brackets.length}
            value={itemValue}
          />
        )}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brackets, itemValue, label, name, purchaseOrder]);
};
