import { roundUp } from './utils';

/**
 * @export
 * @class PricingCalculator
 */
export class PricingCalculator {
  /**
   * @static
   * @memberof PricingCalculator
   */
  static noOfWeeksInYear = 52;
  /**
   * @static
   * @memberof PricingCalculator
   */
  static noOfMonthsInYear = 12;
  /**
   * Convert values to cents
   * @static
   * @param {number} value
   * @returns {number}
   * @memberof PricingCalculator
   */
  static toCents(value = 1): number {
    return value * 100;
  }
  /**
   * Convert values to dollars
   * @static
   * @param {number} value
   * @returns {number}
   * @memberof PricingCalculator
   */
  static toDollars(value = 1): number {
    return value / 100;
  }

  /**
   * @static
   * @param {number} [quantity=1]
   * @param {number} [costPerItem=1]
   * @returns {number} quantity * costPerItem
   * @memberof PricingCalculator
   */
  static totalCost(quantity = 1, costPerItem = 1): number {
    const resultToCents = quantity * PricingCalculator.toCents(costPerItem);
    return PricingCalculator.toDollars(resultToCents);
  }
  /**
   * @static
   * @param {number} [weeklyRent=1]
   * @param {number} [weeklyTerm=1]
   * @returns {number} weeklyRent * weeklyTerm
   * @memberof PricingCalculator
   */
  static totalRtoPrice(weeklyRent = 1, weeklyTerm = 1): number {
    const resultToCents = PricingCalculator.toCents(weeklyRent) * weeklyTerm;
    return PricingCalculator.toDollars(resultToCents);
  }
  /**
   * @static
   * @param {number} [weeklyRent=1]
   * @returns {number} weeklyRent * 2
   * @memberof PricingCalculator
   */
  static biWeeklyRent(weeklyRent = 1): number {
    const resultToCents = PricingCalculator.toCents(weeklyRent) * 2;
    return PricingCalculator.toDollars(resultToCents);
  }
  /**
   * @static
   * @param {number} [weeklyTerm=1]
   * @returns {number} weeklyTerm / 2
   * @memberof PricingCalculator
   */
  static biWeeklyTerm(weeklyTerm = 1): number {
    return Math.ceil(weeklyTerm / 2);
  }
  /**
   * @static
   * @returns {number} noOfWeeksInYear / noOfMonthsInYear
   * @memberof PricingCalculator
   */
  static monthlyMultiplier(): number {
    return roundUp(this.noOfWeeksInYear / this.noOfMonthsInYear, 4);
  }
  /**
   * @static
   * @param {number} [weeklyRent=1]
   * @param {number} monthlyMultiplier
   * @returns {number} weeklyRent * monthlyMultiplier
   * @memberof PricingCalculator
   */
  static monthlyRent(weeklyRent = 1, monthlyMultiplier: number): number {
    const resultToCents =
      PricingCalculator.toCents(weeklyRent) * monthlyMultiplier;

    return PricingCalculator.toDollars(resultToCents);
  }
  /**
   * @static
   * @param {number} totalCost
   * @param {number} monthlyRent
   * @returns {number} totalCost / monthlyRent
   * @memberof PricingCalculator
   */
  static monthlyTerm(totalRtoPrice: number, monthlyRent: number): number {
    return Math.ceil(totalRtoPrice / monthlyRent);
  }
  /**
   * @static
   * @param {number} monthlyRent
   * @returns {number} monthlyRent / 2
   * @memberof PricingCalculator
   */
  static semiMonthlyRent(monthlyRent: number): number {
    const resultToCents = PricingCalculator.toCents(monthlyRent) / 2;

    return PricingCalculator.toDollars(resultToCents);
  }
  /**
   * @static
   * @param {number} monthlyRent
   * @returns {number} monthlyTerm * 2
   * @memberof PricingCalculator
   */
  static semiMonthlyTerm(monthlyTerm: number): number {
    return Math.ceil(monthlyTerm * 2);
  }
}
