import { Dispatch } from 'react';
import { CancelToken } from 'axios';

import {
  DraftPosAction,
  getDraftPosAction,
  getDraftPosErrorAction,
  getDraftPosSuccessAction,
} from './draft-pos-actions';
import { Store } from '../../domain/Store';
import { getPurchaseOrdersByStatus } from '../../api/purchaseOrders';
import { draftPOCancelMessage } from '../../constants/constants';
import { GetPurchaseOrderResponse } from '../../types/types';

export const getDraftPurchaseOrders =
  (dispatch: Dispatch<DraftPosAction>) =>
  async (poStores?: Store[], token?: CancelToken): Promise<void> => {
    try {
      dispatch(getDraftPosAction());

      poStores = poStores || [];
      const poStoreNums = poStores.map((store) => store.storeNum);

      const request = {
        storeNumber: poStoreNums,
        purchaseOrderType: ['ER'],
      };

      const purchaseOrdersResponse: GetPurchaseOrderResponse =
        await getPurchaseOrdersByStatus(request, undefined, undefined, token);

      const { purchaseOrders } = purchaseOrdersResponse;

      dispatch(getDraftPosSuccessAction(purchaseOrders));
    } catch (e: any) {
      if (e.message !== draftPOCancelMessage) {
        dispatch(getDraftPosErrorAction());
      }
    }
  };
