import { makeStyles, RACButton, RACModal } from '@rentacenter/racstrap';
import clsx from 'clsx';
import { chain, isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { FormProvider, get, useForm } from 'react-hook-form';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { getPurchaseOrderById } from '../../api/purchaseOrders';
import { AppRoute } from '../../config/route-config';
import {
  PoFormField,
  TIRES_CLASSIFICATIONS_SELECT,
  TIRES_DEPARTMENT,
  TIRES_MANUALLY_RECEIVED_BRACKET,
  TIRES_SUB_DEPARTMENT,
  TIRES_WHEEL_ITEM_SUB_TYPE,
  TIRES_WHEEL_ITEM_TYPE,
} from '../../constants/constants';
import { useGlobalContext } from '../../context/global/GlobalContext';
import {
  checkLegalLimits,
  createDraftPurchaseOrder,
  createSubmitPurchaseOrder,
  handledCreateDraftPurchaseOrder,
  SUBMIT_ERROR_MESSAGE,
} from '../../context/single/purchaseOrderThunks';
import { LegalEngineErrorCode } from '../../domain/Legal/LegalEngineError';
import { toIllegalResponseList } from '../../domain/Legal/LegalEngineResponseItem';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';
import { PurchaseOrder } from '../../domain/PurchaseOrder/PurchaseOrder';
import { getStoreByNumber, Store } from '../../domain/Store';
import {
  LegalEngineResponseItem,
  PurchaseOrderFormValues,
  APIError,
  LegalEngineRequest,
  StoreConfigsResponse,
} from '../../types/types';
import { Either, left } from '../../utils/Either';
import { generateFormDefaults } from '../../utils/generateFormDefaults';
import { PricingCalculator } from '../../utils/PricingCalculator';
import { toLegalEngineRequest } from '../../utils/purchaseOrderFormMappers';
import {
  IllegalStateInfo,
  IllegalValueBox,
} from './illegal-values-box/IllegalValuesBox';
import { calculateValue, noOp } from '../../utils/utils';
import { hasMaxQuantityError } from '../Allocations/TotalQuantity';
import { Allocations } from './Allocations';
import { CreatePOFooter } from './CreatePOFooter';
import { DetailsTitle } from './DetailsTitle';
import { InventoryDetails } from './InventoryDetails';
import { ItemDetails } from './ItemDetails';
import { PODetailsBreadcrumb } from './PODetailsBreadcrumb';
import { PricingWrapper } from './PricingWrapper';
import { AlignmentBox } from './alignment-box/AlignmentBox';
import { AlertModal } from '../POs/AlertModal';
import { LoadingOverlay } from '../../common/LoadingOverlay/LoadingOverlay';
import { CancelPOConfirmModal } from '../POs/CancelPOConfirmModal';
import { CANCELLED_SUCCESS } from '../../constants/constants';
import { useDuplicatePo } from './useDuplicatePo';
import { LooseWorkModal } from '../POs/LooseWorkModal';
import { getStoreConfigs } from '../../api/store';

const DRAFT_PO_ERROR_MESSAGE =
  'We are unable to Save as Draft at this time, please try again later';
const SUBMIT_PO_ERROR_MESSAGE =
  'We are unable to Create New PO at this time, please try again later';

const EXCEED_MAX_MESSAGE =
  'The total cost max cannot equal or exceed 100000. Please adjust term/rate';

const useStyles = makeStyles((theme: any) => ({
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(16),
  },
  bottom: {
    marginBottom: theme.typography.pxToRem(124),
  },
  dialogContent: {
    textAlign: 'center',
    margin: `2rem 0 0`,
    padding: 0,
    fontSize: theme.typography.pxToRem(16),
  },
  dialogActions: {
    margin: 'auto',
    bottom: 0,
  },
  dialogRoot: {
    height: '100%',
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialogTitle-root': {
      display: 'none',
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
  },
  footerButton: {
    height: theme.typography.pxToRem(43),
  },
  redButton: {
    //TODO: add to racstrap
    // eslint-disable-next-line sonarjs/no-duplicate-string
    backgroundColor: 'rgba(233,92,92,255)',
    '&:disabled': {
      background: 'rgba(233,92,92,255)',
      opacity: 0.5,
    },
    '&:hover': {
      backgroundColor: 'rgba(233,92,92,255)',
    },
  },
}));

export interface PODetailsFormProps
  extends RouteComponentProps<
    { purchaseOrderId: string; storeNumber?: string },
    Record<string, unknown>
  > {
  readonly purchaseOrderId: string | undefined;
  readonly purchaseOrder: PurchaseOrderFormValues | null;
  readonly stores: Store[];
  readonly isPurchaseOrderPending?: boolean;
  readonly isSaveDraftDisabled: boolean;
  readonly setIsSaveDraftDisabled?: any;
  readonly isSubmitDisabled?: boolean;
  readonly isSearchLoading?: boolean;
  readonly setIsSubmitDisabled?: any;
  readonly onSearch?: (text: string) => void;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export const PODetailsForm = (props: PODetailsFormProps) => {
  const {
    purchaseOrder: purchaseorder,
    stores,
    setIsSaveDraftDisabled,
    isSearchLoading,
    onSearch,
    purchaseOrderId,
  } = props;

  const classes = useStyles();
  const [isAlignmentModalOpen, showAlignmentModal] = useState(false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);
  const [isDraftPOReload, setIsDraftPOReload] = useState(false);
  const [isSubmittedPOReload, setIsSubmittedPOReload] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState<any>(purchaseorder);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [canLoseWorkPromptBeShown, setCanLoseWorkPromptBeShown] =
    useState(false);
  const [serverResponseMessage, setServerResponseMessage] = useState('');

  const [illegalResponse, setIllegalResponse] = useState<
    LegalEngineResponseItem[]
  >([]);

  const [showLooseWorkModal, setShowLooseWorkModal] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');

  const globalContext = useGlobalContext();

  let skipLegal = false;
  const [isTireItem, setIsTireItem] = useState(false);
  const methods = useForm<PurchaseOrderFormValues>({
    mode: 'onSubmit',
    defaultValues: generateFormDefaults(stores, purchaseOrder || undefined),
  });

  const {
    match: {
      params: { storeNumber },
    },
  } = props;

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    errors,
    formState: { isDirty },
    reset,
    trigger,
    setError,
    clearErrors,
  } = methods;

  const isViewMode = !!(
    purchaseOrder && ![PoStatus.DRAFT, ''].includes(purchaseOrder.poStatus)
  );

  // eslint-disable-next-line no-console
  console.log('purchaseOrder.poStatus', purchaseOrder?.poStatus?.length);
  const isEditMode = !!(
    purchaseOrder && purchaseOrder.poStatus === PoStatus.DRAFT
  );
  const isCanceled = !!(
    purchaseOrder && purchaseOrder.poStatus === PoStatus.OPEN_CANCELLED
  );

  const isCreateMode = purchaseOrderId === undefined;

  useEffect(() => {
    if (isEditMode) {
      setCanLoseWorkPromptBeShown(true);
      return;
    }

    if (isViewMode) {
      setCanLoseWorkPromptBeShown(false);
      return;
    }
    if (isCanceled) {
      setCanLoseWorkPromptBeShown(false);
      return;
    }
    // duplicate po
    setCanLoseWorkPromptBeShown(true);
  }, [isViewMode, isEditMode, isCanceled, isDirty]);

  useEffect(() => {
    setPurchaseOrder(purchaseorder);
  }, [purchaseorder]);

  const { handleDuplicate } = useDuplicatePo(purchaseOrderId || '', {
    getValues,
    setValue,
    reset,
  });

  const shouldShowAlignmentModal = () => {
    const {
      department,
      itemSubType,
      subDepartment,
      startClassificationsSelect,
      itemType,
      bracket,
    } = getValues();

    if (isAlignmentModalOpen) return false;
    if (
      department === TIRES_DEPARTMENT &&
      subDepartment === TIRES_SUB_DEPARTMENT &&
      startClassificationsSelect === TIRES_CLASSIFICATIONS_SELECT &&
      bracket === TIRES_MANUALLY_RECEIVED_BRACKET &&
      itemType === TIRES_WHEEL_ITEM_TYPE &&
      itemSubType === TIRES_WHEEL_ITEM_SUB_TYPE
    ) {
      return true;
    }

    return false;
  };

  const hasTireAlignmentEnabled = async (
    selectedStoreNumber: string
  ): Promise<boolean> => {
    const selectedStore = selectedStoreNumber;
    const paramKeyName = 'EnableAccess';
    const storeConfig = await getStoreConfigs({
      storeNumbers: [selectedStore],
      paramKeyNames: [paramKeyName],
    });
    return checkIfTireAlignmentAllowed(storeConfig);
  };

  const checkIfTireAlignmentAllowed = (
    response: StoreConfigsResponse[]
  ): boolean => {
    let tireAlignmentAllowed = false;
    if (
      response &&
      response.length > 0 &&
      response[0].configDetails &&
      response[0].configDetails.length > 0
    ) {
      tireAlignmentAllowed = response[0].configDetails.some(
        (config) =>
          config.paramGroupName === 'MpoTireAlignment' &&
          config.paramValue == '1'
      );
    }
    return tireAlignmentAllowed;
  };

  const autofixRate = (illegalStatesInfo: IllegalStateInfo[]) => {
    const formValues: PurchaseOrderFormValues = getValues();
    const correctionsByState: any = {};
    illegalStatesInfo.forEach((illegalState) => {
      correctionsByState[illegalState.state.abbreviation] =
        illegalState.adjustedTerm;
    });
    const adjustedPricing = formValues.pricing.map((pricing) => {
      if (!correctionsByState[pricing.state]) {
        return pricing;
      }
      if (
        parseInt(pricing.weeklyTerm, 10) <= correctionsByState[pricing.state]
      ) {
        return pricing;
      }
      const weeklyTerm = String(correctionsByState[pricing.state]);
      const totalRtoPrice = calculateValue(
        PricingCalculator.totalRtoPrice,
        pricing.weeklyRent,
        weeklyTerm
      );
      const biWeeklyTerm = calculateValue(
        PricingCalculator.biWeeklyTerm,
        weeklyTerm
      );
      const monthlyRent = calculateValue(
        PricingCalculator.monthlyRent,
        pricing.weeklyRent,
        String(PricingCalculator.monthlyMultiplier())
      );
      const monthlyTerm = calculateValue(
        PricingCalculator.monthlyTerm,
        totalRtoPrice,
        monthlyRent
      );
      const semiMonthlyTerm = calculateValue(
        PricingCalculator.semiMonthlyTerm,
        monthlyTerm
      );
      return {
        ...pricing,
        weeklyTerm,
        totalRtoPrice,
        biWeeklyTerm,
        semiMonthlyTerm,
        monthlyTerm,
      };
    });
    skipLegal = true;
    onSaveSubmitted({ ...formValues, pricing: adjustedPricing });
    return false;
  };

  const redirectToDraftPurchaseOrders = (oneOrManyPurchaseOrders?: any) => {
    setIsSaveDraftDisabled(true);

    setIsSubmitting(false);
    if (isArray(oneOrManyPurchaseOrders)) {
      const submittedIds: string = oneOrManyPurchaseOrders?.reduce(
        (ids: string, po: any) => {
          if (po?.purchaseOrder) {
            return ids
              ? `${ids}, #${po.purchaseOrder.number}`
              : `${po.purchaseOrder.number}`;
          }
          return ids;
        },
        ''
      );

      setShowAlertModal(true);
      setAlertType('success');
      setAlertMessage(
        `Manual PO with PO #${submittedIds} submitted successfully`
      );
    } else {
      setIsDraftPOReload(true);
    }
  };

  const checkMaxValue = (formValues: PurchaseOrderFormValues): boolean => {
    return !!formValues?.pricing?.find(
      (item) => Number(item.totalRtoPrice) > 99999
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onSaveSubmitted = async (formValues: PurchaseOrderFormValues) => {
    if (checkMaxValue(formValues)) {
      setAlertMessage(EXCEED_MAX_MESSAGE);
      setAlertType('error');
      setShowAlertModal(true);
      setIsSubmitting(false);
      return;
    }

    setIllegalResponse([]);
    setIsSubmitting(true);
    // call legal Price service
    const legalPriceRequest: LegalEngineRequest =
      toLegalEngineRequest(formValues);

    let either: Either<LegalEngineResponseItem[], APIError>;

    if (!skipLegal) {
      either = await checkLegalLimits(legalPriceRequest);
    } else {
      either = left([]);
    }
    showAlignmentModal(false);

    if (either.isLeft()) {
      const responseItems: LegalEngineResponseItem[] = toIllegalResponseList(
        either.value
      );

      if (responseItems.length) {
        setIsTireItem(formValues.costOfAlignment ? true : false);
        setIllegalResponse(responseItems);
        if (formValues.costOfAlignment) {
          setIsSubmitting(true);
        } else {
          setIsSubmitting(false);
          return;
        }
      }

      if (!shouldShowAlignmentModal()) {
        runSubmit({
          ...formValues,
          poStatus: PoStatus.SUBMITTED,
        });
      } else {
        const isTireAlignmentAllowed = await hasTireAlignmentEnabled(
          formValues?.allocations[0]?.storeNum
        );
        if (isTireAlignmentAllowed) {
          showAlignmentModal(true);
          setIsSubmitting(false);
        } else {
          runSubmit({
            ...formValues,
            poStatus: PoStatus.SUBMITTED,
          });
        }
      }
    } else {
      const userFacingLegalError = either.value.errors.find(
        (e: any) => e.code === LegalEngineErrorCode.UNABLE_TO_COMPUTE
      );

      if (userFacingLegalError) {
        setIsSubmitting(false);
      } else {
        const either: Either<PurchaseOrder, APIError> =
          await handledCreateDraftPurchaseOrder(formValues);
        setIsSubmitting(false);
        if (either.isLeft()) {
          redirectAfterLegalEngineFail();
        }
      }
    }
  };

  const handleFailedPO = (isDraftPO = false, errorMessage?: string) => {
    setAlertMessage(
      isDraftPO ? DRAFT_PO_ERROR_MESSAGE : SUBMIT_PO_ERROR_MESSAGE
    );
    setServerResponseMessage(errorMessage || '');
    setAlertType('error');
    setShowAlertModal(true);
    setIsSubmitting(false);
  };

  const handleAlertModalClose = () => {
    if (alertType === 'success' && alertMessage !== CANCELLED_SUCCESS) {
      setIsSubmittedPOReload(true);
    } else if (alertMessage === SUBMIT_ERROR_MESSAGE) {
      setIsDraftPOReload(true);
    }
    setShowAlertModal(false);
  };

  const { run: runSaveDraft, isPending: isPendingSaveDraft } = useAsync({
    deferFn: ([purchaseOrderFormValues]: PurchaseOrderFormValues[]): Promise<
      PurchaseOrder | PurchaseOrder[]
    > => createDraftPurchaseOrder(purchaseOrderFormValues),
    onResolve: redirectToDraftPurchaseOrders,
    onReject: (error: any) => {
      handleFailedPO(true, error?.errors[0]?.message);
    },
  });

  const { run: runSubmit, isPending: isPendingSaveSubmit } = useAsync({
    deferFn: ([purchaseOrderFormValues]: PurchaseOrderFormValues[]): Promise<
      PurchaseOrder | PurchaseOrder[]
    > => {
      return createSubmitPurchaseOrder(purchaseOrderFormValues, globalContext);
    },
    onResolve: redirectToDraftPurchaseOrders,
    onReject: () => handleFailedPO(),
  });

  const showLoader = isPendingSaveDraft || isPendingSaveSubmit || isSubmitting;

  const redirectAfterLegalEngineFail = () => {
    setIsSaveDraftDisabled(true);

    setShowAlertModal(true);
    setAlertType('error');
    setAlertMessage(SUBMIT_ERROR_MESSAGE);
  };

  const states = illegalResponse.map(
    (item: LegalEngineResponseItem) =>
      ({
        adjustedTerm: item?.priceInfo?.weeklyTerm,
        totalCostLimit: item?.priceInfo?.totalCost,
        state: getStoreByNumber(stores, item?.storeNumber)?.state,
      } as IllegalStateInfo)
  );

  const uniqueStatesWithIllegalValues: IllegalStateInfo[] = chain(states)
    .uniqBy((item: IllegalStateInfo) => item.state.name)
    .value();

  const onSaveDraft = async () => {
    const firstAllocationStoreNum = 'allocations[0].storeNum';

    await trigger([firstAllocationStoreNum, PoFormField.TotalQuantity]);

    if (get(errors, firstAllocationStoreNum) || hasMaxQuantityError(errors)) {
      clearErrors();
      setError('allocations[0].storeNum', {
        message: 'Please select a store!',
      });
      return;
    }

    const formValues: PurchaseOrderFormValues = getValues();
    if (checkMaxValue(formValues)) {
      setAlertMessage(EXCEED_MAX_MESSAGE);
      setAlertType('error');
      setShowAlertModal(true);
      setIsSubmitting(false);
      return;
    }

    setIsSaveDraftDisabled(true);
    setIsCancelDisabled(true);
    runSaveDraft(formValues);
  };

  const fetchPurchaseOrder = async () => {
    try {
      if (!purchaseOrderId) return;

      const request = {
        purchaseOrderNumber: purchaseOrderId,
        storeNumber: [storeNumber],
      };

      const purchaseOrder = await getPurchaseOrderById(request);
      const purchaseOrderValue = {
        ...purchaseOrder?.purchaseOrders[0],
        poStatus: purchaseOrder?.purchaseOrders[0]?.poStatusType,
      };
      purchaseOrder && setPurchaseOrder(purchaseOrderValue);
    } catch (error: any) {
      setIsSaveDraftDisabled(false);
    }
  };

  const handleCancelPOModalResolve = (value: string) => {
    if (value === 'success') {
      setAlertMessage(CANCELLED_SUCCESS);
      setAlertType('success');
      fetchPurchaseOrder();
    } else {
      setAlertMessage(value);
      setAlertType('error');
    }
    setOpenCancelModal(false);
    setShowAlertModal(true);
  };

  const handleCancelPOModalClose = () => {
    setOpenCancelModal(false);
    setShowAlertModal(false);
  };

  if (!showLooseWorkModal && redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (
    <>
      {showLooseWorkModal && (
        <LooseWorkModal
          onClose={() => {
            setRedirectPath('');
            setShowLooseWorkModal(false);
          }}
          handleCancelButtonClick={() => {
            setShowLooseWorkModal(false);
          }}
          isEditPo={isEditMode}
        />
      )}
      <PODetailsBreadcrumb
        purchaseOrderId={purchaseOrderId}
        onClick={isDirty ? (event, href) => setRedirectPath(href) : undefined}
      />
      {showAlertModal && (
        <AlertModal
          onClose={() => handleAlertModalClose()}
          alertType={alertType}
          alertMessage={alertMessage}
          serverResponse={serverResponseMessage}
        />
      )}
      {openCancelModal && (
        <CancelPOConfirmModal
          id={purchaseOrderId || ''}
          onClose={handleCancelPOModalClose}
          onResolve={(value) => handleCancelPOModalResolve(value)}
        />
      )}
      <RACModal
        isOpen={illegalResponse.length !== 0 && !isTireItem}
        classes={{
          dialogContent: classes.dialogContent,
          dialog: classes.dialogRoot,
          dialogActions: classes.dialogActions,
        }}
        maxWidth="sm"
        title=""
        content={
          <IllegalValueBox
            illegalResponse={illegalResponse}
            stores={stores}
            poNumber={purchaseOrderId}
          />
        }
        onClose={() => {
          noOp();
        }}
        buttons={
          <>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                autofixRate(uniqueStatesWithIllegalValues);
              }}
            >
              OK
            </RACButton>
          </>
        }
      />

      {showLoader && <LoadingOverlay />}

      <FormProvider {...methods}>
        {isDraftPOReload && (
          <Redirect
            to={{
              pathname: AppRoute.POs,
              search: '?draft',
            }}
          />
        )}
        {isSubmittedPOReload && <Redirect to={AppRoute.POs} />}
        <RACModal
          isOpen={isAlignmentModalOpen}
          classes={{
            dialog: classes.dialogRoot,
            dialogActions: classes.dialogActions,
          }}
          maxWidth="xs"
          title=""
          content={
            <AlignmentBox
              onConfirm={handleSubmit(onSaveSubmitted)}
              purchaseOrder={purchaseOrder}
            />
          }
          onClose={() => {
            noOp();
          }}
          buttons={<></>}
        />
        <form
          id="createPOForm"
          onSubmit={methods.handleSubmit(onSaveSubmitted)}
          noValidate
        >
          {purchaseOrderId ? (
            <input
              type="hidden"
              name={PoFormField.Id}
              defaultValue={purchaseOrderId}
              ref={register}
            />
          ) : null}
          <input type="hidden" name={PoFormField.PoStatus} ref={register} />
          <DetailsTitle
            purchaseOrderId={purchaseOrderId}
            purchaseOrder={purchaseOrder}
            disabled={isViewMode}
            isEditMode={isEditMode}
            isCanceled={isCanceled}
          />
          <ItemDetails purchaseOrder={purchaseOrder} isViewMode={isViewMode} />
          <InventoryDetails purchaseOrder={purchaseOrder} />
          <div className={clsx(classes.flex, classes.bottom)}>
            <Allocations
              purchaseOrder={purchaseOrder}
              stores={stores}
              onSearch={onSearch}
              isSearchLoading={isSearchLoading}
              disabled={isViewMode}
            />
            <PricingWrapper purchaseOrder={purchaseOrder} />
          </div>
          <CreatePOFooter
            isEditPo={isEditMode}
            isDirty={canLoseWorkPromptBeShown}
          >
            {(!purchaseOrderId || isEditMode) && (
              <>
                <RACButton
                  className={clsx(classes.footerButton)}
                  variant="contained"
                  size="small"
                  color="primary"
                  key="saveAsDraft"
                  onClick={onSaveDraft}
                  disabled={isPendingSaveDraft || isPendingSaveSubmit}
                  loading={isPendingSaveDraft}
                >
                  Save as Draft
                </RACButton>
                <RACButton
                  type="submit"
                  form="createPOForm"
                  className={clsx(classes.footerButton)}
                  variant="contained"
                  size="small"
                  color="primary"
                  key="submitPO"
                  loading={isPendingSaveSubmit}
                  disabled={isPendingSaveDraft || isPendingSaveSubmit}
                >
                  Submit PO
                </RACButton>
              </>
            )}
            {isViewMode && (
              <>
                {!isCreateMode && (
                  <RACButton
                    className={clsx(classes.footerButton)}
                    variant="contained"
                    size="small"
                    color="primary"
                    key="saveAsDraft"
                    onClick={handleDuplicate}
                  >
                    Duplicate PO
                  </RACButton>
                )}
                {!isCanceled && (
                  <RACButton
                    className={clsx(classes.footerButton, classes.redButton)}
                    variant="contained"
                    size="small"
                    color="primary"
                    key="submitPO"
                    disabled={isCancelDisabled}
                    onClick={() => {
                      setOpenCancelModal(true);
                    }}
                  >
                    Cancel PO
                  </RACButton>
                )}
              </>
            )}
          </CreatePOFooter>
        </form>
      </FormProvider>
    </>
  );
};
