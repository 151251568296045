/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable */
import { sanitizeURL } from '../utils/utils';

export interface ApiUrls {
  readonly container: string;
  readonly micro: string;
  readonly micro_old?: string;
  readonly customers: string;
  readonly mock: string;
  readonly am: string;
}

export interface AppConfigItem {
  readonly apiUrls: ApiUrls;
  readonly microUrl: string;
  readonly auth: {
    readonly region: string;
    readonly userPoolId: string;
    readonly userPoolWebClientId: string;
    readonly oauth: {
      readonly domain: string;
      readonly scope?: string[];
      readonly redirectSignIn: string;
      readonly redirectSignOut: string;
      readonly responseType: string;
      readonly urlOpener: (url: string) => void;
    };
  };
}

const microUrl = {
  localhost: 'http://localhost:3006',
  local: 'https://local-manual-po-racpad.rentacenter.com',
  dev: 'https://dev-manual-po-racpad.rentacenter.com',
  qa: 'https://qa-manual-po-racpad.rentacenter.com',
  uat: 'https://uat-manual-po-racpad.rentacenter.com',
  prod: 'https://racpad-am.rentacenter.com',
};

export interface AppConfig extends AppConfigItem {
  readonly appName: string;
}

export const urlOpener = (url: string): void => {
  const urlToOpen = sanitizeURL(url);

  if (urlToOpen) {
    window.open(urlToOpen, '_self');
  }
};

export const localhost: AppConfigItem = {
  apiUrls: {
    container: 'https://local-racpad.rentacenter.com/api',
    micro: 'https://local-mpo-racpad.rentacenter.com/api',
    micro_old: 'https://local-manual-po-racpad.rentacenter.com/api',
    customers: 'https://local-customers-racpad.rentacenter.com/api',
    mock: 'http://localhost:3006/',
    am: 'https://local-am-racpad.rentacenter.com/api',
  },
  microUrl: microUrl.localhost,
  auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_FPWut7aB',
    userPoolWebClientId: '608s8dso4aecka8omjpukssq3n',
    oauth: {
      domain: 'auth-local-racpad.auth.us-east-1.amazoncognito.com',
      redirectSignIn: microUrl.localhost,
      redirectSignOut: microUrl.localhost,
      responseType: 'token',
      urlOpener,
    },
  },
};

export const localhostIE11: AppConfigItem = { ...localhost };

const apiUrlMicro = '_apiUrls.micro';
const apiUrlMicroOld = '_apiUrls.micro_old';
export const artifact: AppConfigItem = {
  apiUrls: {
    container: '_apiUrls.container',
    micro: apiUrlMicro,
    micro_old: `${apiUrlMicroOld}/api`,
    customers: apiUrlMicro,
    // only used for mock integration
    mock: apiUrlMicro,
    am: '_apiUrls.am',
  },
  microUrl: '_microUrl',
  auth: {
    region: '_auth.region',
    userPoolId: '_auth.userPoolId',
    userPoolWebClientId: '_auth.userPoolWebClientId',
    oauth: {
      domain: '_auth.oauth.domain',
      redirectSignIn: '_auth.oauth.redirectSignIn',
      redirectSignOut: '_auth.oauth.redirectSignOut',
      responseType: '_auth.oauth.responseType',
      urlOpener,
    },
  },
};

let appConfigItem: AppConfigItem;

switch (process.env.REACT_APP_STAGE) {
  case 'localhost':
    appConfigItem = localhost;
    break;
  case 'localhost-ie11':
    appConfigItem = localhostIE11;
    break;
  case 'artifact':
    appConfigItem = artifact;
    break;
  default:
    appConfigItem = localhost;
    break;
}

export const appConfig: AppConfig = {
  appName: 'Manual PO',
  ...appConfigItem,
};
