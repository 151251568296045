import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
import { POs } from '../components/POs/POs';
import { PODetails } from '../components/PODetails/PODetails';
import { GlobalContextProvider } from '../context/global/GlobalContextProvider';
import { PoSubTypesFetcher } from './PoSubTypesFetcher';
import { ClassificationsFetcher } from './ClassificationsFetcher';
import { StoresProvider } from '../context/stores/StoresProvider';
import { StoresFetcher } from '../components/POs/StoresFetcher';
import { DraftPODetails } from '../components/PODetails/DraftPODetails';

export const routesTestId = 'routesTestId';

export const Routes = () => {
  return (
    <UserProvider>
      <PermissionsProvider>
        <UserFetcher />
        <StoresProvider>
          <StoresFetcher />
          <GlobalContextProvider>
            <ClassificationsFetcher />
            <PoSubTypesFetcher />
            <div data-testid={routesTestId}>
              <Switch>
                <Route exact path={AppRoute.Index}>
                  <Redirect to={AppRoute.POs} />
                </Route>
                <Route exact path={AppRoute.IndexWithAppName}>
                  <Redirect to={AppRoute.POs} />
                </Route>
                <Route exact path={AppRoute.POs}>
                  <POs />
                </Route>
                <Route path={AppRoute.Details} component={PODetails} />
                <Route
                  path={AppRoute.DraftDetails}
                  component={DraftPODetails}
                />
                <Route path={AppRoute.Create} component={PODetails} />
              </Switch>
            </div>
          </GlobalContextProvider>
        </StoresProvider>
      </PermissionsProvider>
    </UserProvider>
  );
};
