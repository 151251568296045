import { RACSearchDropdown } from '@rentacenter/racstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ClassificationsInputPlaceholder,
  emptyField,
  PoFormField,
} from '../../constants/constants';
import { ClassificationType } from '../../domain/PurchaseOrder/ClassificationType';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';
import { SelectedFieldOption } from '../../types/types';
import { filterById, populateSelectByChild } from '../../utils/utils';
import {
  useClassificationsDispatch,
  useClassificationsState,
} from './context/Provider';
import { ClassificationsComponentProps } from './context/types';

export const classificationsTypeTestId = 'classificationsTypeComponent';

// eslint-disable-next-line
export const ClassificationsType = (props: ClassificationsComponentProps) => {
  const { name, label, purchaseOrder } = props;
  const { setValue, getValues } = useFormContext();
  const { initialTypes, types, startWithDepartment, isBracketSelected } =
    useClassificationsState();
  const dispatch = useClassificationsDispatch();
  const [itemValue, setItemValue] = useState(emptyField);

  useEffect(
    // eslint-disable-next-line
    () => {
      const poItemType = purchaseOrder && purchaseOrder.itemType;
      const optionTypes = types && types.length ? types : initialTypes || [];
      const selectedType: {
        name: string;
        id: string;
        subTypes: [];
      } | null = poItemType ? filterById(optionTypes, poItemType)[0] : null;

      if (types.length === 1) {
        setItemValue({ value: types[0].id, label: types[0].name });
        dispatch({
          type: ClassificationType.SET_SINGLE_SUBTYPE,
          payload: types[0].subTypes,
        });
        selectedType &&
          dispatch({
            type: ClassificationType.SET_SINGLE_SUBTYPE,
            payload: selectedType.subTypes,
          });
      } else {
        if (startWithDepartment) {
          setItemValue(emptyField);
          dispatch({
            type: ClassificationType.CLEAR_SUBTYPES,
            payload: [],
          });
        }
      }
      if (poItemType && !startWithDepartment && !isBracketSelected) {
        setItemValue({
          value: (selectedType && selectedType.id) || '',
          label:
            (selectedType && selectedType.name) ||
            ClassificationsInputPlaceholder,
        });
      }
      if (startWithDepartment && selectedType && isBracketSelected) {
        dispatch({
          type: ClassificationType.SET_TYPES,
          payload: selectedType.subTypes,
        });
      }
    },
    // eslint-disable-next-line
    [types, dispatch, startWithDepartment, initialTypes, isBracketSelected]
  );

  useEffect(
    () => {
      const poItemType = purchaseOrder && purchaseOrder.itemType;
      const poStartClassificationsSelect =
        purchaseOrder && purchaseOrder.startClassificationsSelect;

      // eslint-disable-next-line
      const optionTypes = types && types.length ? types : initialTypes || [];
      const selectedType: {
        name: string;
        id: string;
        subTypes: [];
      } | null = poItemType ? filterById(optionTypes, poItemType)[0] : null;

      if (
        poStartClassificationsSelect === 'itemType' &&
        !startWithDepartment &&
        selectedType
      ) {
        dispatch({
          type: ClassificationType.SET_TYPES_AS_PARENT,
          payload: selectedType,
        });
      }
    },
    // eslint-disable-next-line
    [dispatch, types, startWithDepartment, initialTypes]
  );

  const startClassificationsSelect = getValues()?.startClassificationsSelect;

  const handleChange = useCallback(
    // eslint-disable-next-line
    (selected: SelectedFieldOption) => {
      const { value, label, subTypes } = selected;

      setItemValue({ value, label });
      setValue(name, value, { shouldValidate: true, shouldDirty: true });

      startClassificationsSelect !== PoFormField.Department &&
        setValue(PoFormField.StartClassificationsSelect, PoFormField.ItemType);

      if (!startWithDepartment) {
        dispatch({
          type: ClassificationType.SET_TYPES_AS_PARENT,
          payload: selected,
        });
        if (purchaseOrder && purchaseOrder.itemType !== value) {
          purchaseOrder.itemSubType = '';
          purchaseOrder.department = '';
          purchaseOrder.subDepartment = '';
          purchaseOrder.bracket = '';
        }

        // eslint-disable-next-line
        let subTypes = selected.subTypes;
        let departments = subTypes[0].departments;
        let subDepartments = departments[0].subDepartments;
        let brackets = subDepartments[0].brackets;

        if (subTypes && subTypes.length === 1) {
          setValue(PoFormField.ItemSubType, subTypes[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          departments = [];
          subDepartments = [];
          brackets = [];
          setValue(PoFormField.ItemSubType, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }

        if (departments && departments.length === 1) {
          setValue(PoFormField.Department, departments[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          departments = [];
          subDepartments = [];
          brackets = [];
          setValue(PoFormField.Department, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }

        if (subDepartments.length === 1) {
          setValue(PoFormField.SubDepartment, subDepartments[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          departments = [];
          subDepartments = [];
          brackets = [];
          setValue(PoFormField.SubDepartment, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }

        if (brackets && brackets.length === 1) {
          setValue(PoFormField.Bracket, brackets[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          departments = [];
          subDepartments = [];
          brackets = [];
          setValue(PoFormField.Bracket, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }

      if (startWithDepartment) {
        dispatch({
          type: ClassificationType.SET_TYPES,
          payload: subTypes,
        });
        if (subTypes.length === 1) {
          setValue(PoFormField.ItemSubType, subTypes[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setValue(PoFormField.ItemSubType, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
    },
    [
      dispatch,
      startClassificationsSelect,
      name,
      purchaseOrder,
      setValue,
      startWithDepartment,
    ]
  );

  const typeOptions = useCallback(() => {
    return types.length > 0
      ? populateSelectByChild(types, 'subTypes')
      : populateSelectByChild(initialTypes, 'subTypes');
  }, [initialTypes, types]);

  const isViewMode =
    purchaseOrder &&
    (purchaseOrder.poStatus === PoStatus.SUBMITTED ||
      purchaseOrder.poStatus === PoStatus.OPEN_CANCELLED);

  return useMemo(
    () => (
      <Controller
        name={name}
        rules={{ required: true }}
        render={({ name }) => (
          <RACSearchDropdown
            name={name}
            inputLabel={label}
            options={typeOptions() || []}
            OnChange={handleChange}
            required
            disabled={isViewMode || (startWithDepartment && !types.length)}
            value={itemValue}
          />
        )}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isViewMode,
      itemValue,
      label,
      name,
      purchaseOrder,
      startWithDepartment,
      typeOptions,
      types.length,
    ]
  );
};
