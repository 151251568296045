import { makeStyles } from '@rentacenter/racstrap';
// import { CancelTokenSource } from 'axios';
import React, {
  //  useContext,
  useEffect,
  useState,
} from 'react';
// import { generateCancelToken } from '../../api/client';
// import {
//   GET_STORES_LIMIT,
//   INITIAL_OFFSET,
//   MANUAL_PO_API_LIMIT,
// } from '../../constants/constants';
// import { useGlobalContext } from '../../context/global/GlobalContext';
// import { StoresStateContext } from '../../context/stores/StoresProvider';
// import { getSubmittedPurchaseOrders } from '../../context/submitted/submitted-pos-thunks';
// import { SubmittedPosContext } from '../../context/submitted/SubmittedPosContext';
import { SearchPO } from '../SearchPO/SearchPO';
import { POTable } from './POTable';
import { RACSelect } from '@rentacenter/racstrap';
import { getReference } from '../../api/reference';
import { ReferenceKeys, Option, POSearchFilter } from '../../types/types';
import {
  addAllOption,
  mapReferenceResponse,
  orderByField,
  pipe,
  filterSubmittedPurchaseOrdersType,
} from '../../utils/utils';
import {
  ALL_OPTION,
  PURCHASE_ORDER_TYPE_CACHE_KEY,
} from '../../constants/constants';
import { Search } from '../SearchPO/Search';

const ORDER_BY_DESC_FIELD = 'description';
export const orderByDescField = orderByField.bind(null, ORDER_BY_DESC_FIELD);

const useStyles = makeStyles({
  tableWrapper: {
    overflow: 'auto',
    marginTop: '20px',
  },
  filterBy: {
    position: 'absolute',
    right: '40px',
    marginTop: '130px',
    width: '200px',
    '& .MuiSelect-root': {
      lineHeight: '32px',
    },
  },
});

export const initialFilter = {
  poNumber: '',
  fromDate: '',
  toDate: '',
};

export const SubmittedPOs = () => {
  const classes = useStyles();
  // const { poStores } = useGlobalContext();
  // const {
  // purchaseOrders,
  // isPending,
  // hasError,
  // dispatch,
  // } = useContext(SubmittedPosContext);

  const [selectedPurchaseOrderType, setSelectedPurchaseOrderType] =
    useState(ALL_OPTION);
  const [purchaseOrdersTypeOptions, setPurchaseOrdersTypeOptions] = useState<
    Option[]
  >([]);

  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState<POSearchFilter>(initialFilter);

  // const { stores: initialStores, hadError: hadStoreError } =
  //   useContext(StoresStateContext);

  // useEffect(() => {
  //   if (
  //     (initialStores.length === 0 ||
  //       initialStores.length >= GET_STORES_LIMIT ||
  //       !!purchaseOrders?.length) &&
  //     !poStores.length
  //   ) {
  //     return;
  //   }

  //   const source: CancelTokenSource = generateCancelToken();

  //   getSubmittedPurchaseOrders(dispatch)(
  //     poStores,
  //     source.token,
  //     INITIAL_OFFSET,
  //     MANUAL_PO_API_LIMIT
  //   );

  //   return source.cancel;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [poStores, initialStores]);

  const fetchPurchaseOrderTypeOptions = () => {
    setLoading(true);
    getReference(
      [ReferenceKeys.PURCHASE_ORDER_STATUS_TYPE],
      PURCHASE_ORDER_TYPE_CACHE_KEY
    )
      .then((response: any) =>
        pipe(
          orderByDescField,
          mapReferenceResponse,
          filterSubmittedPurchaseOrdersType,
          addAllOption,
          setPurchaseOrdersTypeOptions
        )(response?.references[0]?.referenceDetails)
      )
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPurchaseOrderTypeOptions();
  }, []);

  const handleSearchFilter = (
    poNumber: string,
    fromDate: string,
    toDate: string
  ) => {
    setFilter({
      poNumber,
      fromDate,
      toDate,
    });
  };

  const handleFilterClear = () => setFilter(initialFilter);

  return (
    <>
      <Search setFilter={handleSearchFilter} clearFilter={handleFilterClear} />
      <SearchPO />
      <div className={classes.filterBy}>
        <RACSelect
          inputLabel="Filter by"
          defaultValue={selectedPurchaseOrderType}
          options={purchaseOrdersTypeOptions || []}
          onChange={(e: any) => {
            setSelectedPurchaseOrderType(e.target.value);
          }}
        />
      </div>

      <div className={classes.tableWrapper}>
        <POTable
          isLoading={loading}
          filter={filter}
          selectedPurchaseOrderType={selectedPurchaseOrderType}
        />
      </div>
    </>
  );
};
