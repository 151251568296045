import React from 'react';
import { RACModal, RACButton, makeStyles } from '@rentacenter/racstrap';
import { ReactComponent as SuccessIcon } from '../../assets/img/success-icon.svg';
import { ReactComponent as Erroricon } from '../../assets/img/apiErrorIcon.svg';

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'center',
    margin: `2rem 0 0`,
    padding: 0,
  },
  dialogActions: {
    margin: 'auto',
    bottom: 0,
  },
  dialogRoot: {
    height: '100%',
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialog-paperScrollPaper': {
      width: '35%',
      minHeight: theme.typography.pxToRem(230),
      minWidth: theme.typography.pxToRem(300),
    },
    '& .MuiDialogTitle-root': {
      display: 'none',
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
  },
  cancelledText: {
    fontWeight: 800,
    fontSize: '1rem',
  },
  contentHeight: {
    height: '30%',
    overflow: 'scroll',
  },
  racErrorIcon: {
    width: theme.typography.pxToRem(50),
  },
}));
export interface AlertModalProps {
  onClose: () => void;
  alertMessage: string;
  alertType: string;
  serverResponse?: string;
}

export const AlertModal = ({
  onClose,
  alertMessage,
  alertType,
  serverResponse,
}: AlertModalProps) => {
  const classes = useStyles();

  return (
    <RACModal
      isOpen
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
        dialogActions: classes.dialogActions,
      }}
      maxWidth="lg"
      title=""
      content={
        <>
          {alertType === 'success' ? (
            <SuccessIcon />
          ) : (
            <Erroricon className={classes.racErrorIcon} />
          )}
          <p className={classes.cancelledText}>{serverResponse}</p>
          <p className={classes.cancelledText}>{alertMessage}</p>
        </>
      }
      onClose={onClose}
      buttons={
        <>
          <RACButton variant="contained" color="primary" onClick={onClose}>
            OK
          </RACButton>
        </>
      }
    />
  );
};
