import * as purchaseOrderClient from '../../api/purchaseOrders';
import { mapError } from '../../api/client';
import {
  ManagePurchaseOrderRequest,
  PurchaseOrder,
} from '../../domain/PurchaseOrder/PurchaseOrder';
import {
  Allocation,
  APIError,
  LegalEngineRequest,
  LegalEngineResponseItem,
  PurchaseOrderFormValues,
} from '../../types/types';
import { Either, left, right } from '../../utils/Either';
import { toPurchaseOrder } from '../../utils/purchaseOrderFormMappers';
import { GlobalContextValue } from '../global/GlobalContext';

export const SUBMIT_ERROR_MESSAGE =
  'Unable to save your PO. But don`t worry you will always find it in Draft PO`s';

/**
 * @function saveAsDraft Function that saves a po as draft and displays an error message.
 * Used when submit po fails and we want to back it up as a draft.
 * @param {PurchaseOrderFormValues} purchaseOrderFormValues Purchase order object to be saved as draft
 * @param { Context } context Global context, used to display snackbar
 * @param { String } id Id of the purchase order , if available.
 * @return {Promise} Returns a Promise that resolves to the saved PO
 */
const saveAsDraft = (
  purchaseOrderFormValues: PurchaseOrderFormValues,
  context: GlobalContextValue,
  id?: string
): Promise<PurchaseOrder> => {
  purchaseOrderFormValues = {
    ...purchaseOrderFormValues,
  };

  const draftActionPromise = id
    ? purchaseOrderClient.updateDraftPurchaseOrder(
        id,
        toPurchaseOrder(purchaseOrderFormValues)
      )
    : purchaseOrderClient.createDraftPurchaseOrder(
        toPurchaseOrder(purchaseOrderFormValues)
      );

  return draftActionPromise.then((purchaseOrder: PurchaseOrder) => {
    // context.dispatch(
    //   openSnackbarAction(createGenericMessage(SUBMIT_ERROR_MESSAGE))
    // );

    return purchaseOrder;
  });
};

const createDraftPurchaseOrder = (
  purchaseOrderFormValues: PurchaseOrderFormValues
): Promise<PurchaseOrder> => {
  if (purchaseOrderFormValues && purchaseOrderFormValues.id) {
    const purchaseOrderId = purchaseOrderFormValues.id;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    delete purchaseOrderFormValues.id;

    return purchaseOrderClient
      .updateDraftPurchaseOrder(
        purchaseOrderId,
        toPurchaseOrder(purchaseOrderFormValues)
      )
      .catch(mapError);
  }

  return purchaseOrderClient
    .createDraftPurchaseOrder(toPurchaseOrder(purchaseOrderFormValues))
    .catch(mapError);
};

const handledCreateDraftPurchaseOrder = async (
  formValues: PurchaseOrderFormValues
): Promise<Either<PurchaseOrder, APIError>> => {
  try {
    return left(await createDraftPurchaseOrder(formValues));
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return right(e);
  }
};

const deleteDraftPurchaseOrder = (draftId: string): Promise<void> =>
  purchaseOrderClient.deleteDraftPurchaseOrder(draftId);

const createSubmitPurchaseOrder = (
  purchaseOrderFormValues: PurchaseOrderFormValues,
  globalContext: GlobalContextValue
): Promise<PurchaseOrder | PurchaseOrder[]> => {
  if (purchaseOrderFormValues && purchaseOrderFormValues.id) {
    const purchaseOrderId = purchaseOrderFormValues.id;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    delete purchaseOrderFormValues.id;
    purchaseOrderFormValues?.allocations?.map((allocation: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      delete allocation?.draftPoDetailsId;
    });

    return purchaseOrderClient
      .updateSubmittedPurchaseOrder(
        purchaseOrderId,
        toPurchaseOrder(purchaseOrderFormValues)
      )
      .catch(() =>
        saveAsDraft(purchaseOrderFormValues, globalContext, purchaseOrderId)
      );
  }

  return purchaseOrderClient
    .createSubmittedPurchaseOrder(toPurchaseOrder(purchaseOrderFormValues))
    .catch(() => saveAsDraft(purchaseOrderFormValues, globalContext));
};

const checkLegalLimits = async (
  request: LegalEngineRequest
): Promise<Either<LegalEngineResponseItem[], APIError>> => {
  try {
    request?.allocations?.map((allocation: Allocation) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      delete allocation?.draftPoDetailsId;
    });
    return left(await purchaseOrderClient.getLegalPrice(request));
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return right(e);
  }
};

const managePurchaseOrder = (
  request: ManagePurchaseOrderRequest
): Promise<void> => purchaseOrderClient.managePurchaseOrder(request);

export {
  createDraftPurchaseOrder,
  handledCreateDraftPurchaseOrder,
  deleteDraftPurchaseOrder,
  createSubmitPurchaseOrder,
  checkLegalLimits,
  managePurchaseOrder,
};
