import { toNumber, toString, uniqBy } from 'lodash';
import { Allocation } from '../domain/PurchaseOrder/Allocation';
import { Pricing } from '../domain/PurchaseOrder/Pricing';
import { PurchaseOrder } from '../domain/PurchaseOrder/PurchaseOrder';
import {
  FormAllocation,
  FormPricing,
  LegalEngineRequest,
  PurchaseOrderFormValues,
} from '../types/types';

const toNumberOrUndefined = (maybeNumber?: any) => {
  const num = toNumber(maybeNumber);

  return num > 0 ? num : undefined;
};

const toAllocations = (
  formAllocations: FormAllocation[],
  formPricing: FormPricing[],
  pricingFields: Array<keyof FormPricing>,
  STORE_NUMBER_KEY?: string
): Allocation[] => {
  return formAllocations?.reduce(
    (allocations: Allocation[], formAllocation: FormAllocation) => {
      const foundFormPricing: FormPricing | undefined =
        formPricing &&
        formPricing.find((p: FormPricing) => p.state === formAllocation.state);

      let pricing: Pricing = {};

      if (foundFormPricing) {
        pricingFields.forEach((field: keyof FormPricing) => {
          if (foundFormPricing[field]) {
            pricing = {
              ...pricing,
              [field]: toNumberOrUndefined(foundFormPricing[field]),
            };
          }
        });
      }

      const allocation: Allocation = {
        draftPoDetailsId: formAllocation.draftPoDetailsId || undefined,
        // will change this
        [STORE_NUMBER_KEY || 'storeNumber']:
          formAllocation.storeNum || undefined,
        quantity: toNumberOrUndefined(formAllocation.quantity),
        state: formAllocation.state || undefined,
        pricing,
      };

      return [...allocations, allocation];
    },
    []
  );
};

export const toPurchaseOrder = (
  formValues: PurchaseOrderFormValues
  // eslint-disable-next-line
): PurchaseOrder => {
  const pricingFields: Array<keyof FormPricing> = [
    'totalRtoPrice',
    'cashPrice',
    'weeklyRent',
    'weeklyTerm',
    'biWeeklyRent',
    'biWeeklyTerm',
    'semiMonthlyRent',
    'semiMonthlyTerm',
    'monthlyRent',
    'monthlyTerm',
  ];

  return {
    id: formValues.id || undefined,
    purchaseOrderSubType: formValues.purchaseOrderSubType || undefined,
    referenceNumber: formValues.referenceNumber || undefined,
    startClassificationsSelect:
      formValues.startClassificationsSelect || undefined,
    supplier: formValues.supplier || undefined,
    brand: formValues.brand || undefined,
    model: formValues.model || undefined,
    itemCondition: formValues.itemCondition || undefined,
    department: formValues.department || undefined,
    subDepartment: formValues.subDepartment || undefined,
    bracket: formValues.bracket || undefined,
    itemType: formValues.itemType || undefined,
    itemSubType: formValues.itemSubType || undefined,
    description: formValues.description || undefined,
    supplierOrderNo: formValues.supplierOrderNo || undefined,
    allocations: toAllocations(
      formValues.allocations,
      formValues.pricing,
      pricingFields
    ),
    totalQuantity: toNumberOrUndefined(formValues.totalQuantity),
    costPerItem: toNumberOrUndefined(formValues.costPerItem),
    totalCost: toNumberOrUndefined(formValues.totalCost),
    costOfAlignment: formValues.costOfAlignment
      ? Number(formValues.costOfAlignment)
      : undefined,
  };
};

export const toPurchaseOrderFormValues = (
  purchaseOrder: PurchaseOrder
  // eslint-disable-next-line
): PurchaseOrderFormValues => {
  const allocations: FormAllocation[] = purchaseOrder?.allocations?.map(
    (a: Allocation): FormAllocation => ({
      draftPoDetailsId: toString(a?.draftPoDetailsId) || '',
      quantity: toString(a?.quantity),
      storeNum: a?.storeNum || '',
      state: a?.state || '',
    })
  );

  const pricing: FormPricing[] = purchaseOrder?.allocations
    ?.filter((a: Allocation): boolean => !!a?.state)
    .map(
      (a: Allocation): FormPricing => ({
        state: a.state || '',
        weeklyRent: a.pricing ? toString(a.pricing.weeklyRent) : '',
        weeklyTerm: a.pricing ? toString(a.pricing.weeklyTerm) : '',
        biWeeklyRent: a.pricing ? toString(a.pricing.biWeeklyRent) : '',
        biWeeklyTerm: a.pricing ? toString(a.pricing.biWeeklyTerm) : '',
        semiMonthlyRent: a.pricing ? toString(a.pricing.semiMonthlyRent) : '',
        semiMonthlyTerm: a.pricing ? toString(a.pricing.semiMonthlyTerm) : '',
        monthlyRent: a.pricing ? toString(a.pricing.monthlyRent) : '',
        monthlyTerm: a.pricing ? toString(a.pricing.monthlyTerm) : '',
        cashPrice: a.pricing ? toString(a.pricing.cashPrice) : '',
        totalRtoPrice: a.pricing ? toString(a.pricing.totalRtoPrice) : '',
      })
    );

  const uniquePricing = uniqBy(pricing, (p: FormPricing) => {
    return p.state;
  });

  return {
    id: purchaseOrder.id || '',
    purchaseOrderSubType: purchaseOrder.purchaseOrderSubType || '',
    referenceNumber: purchaseOrder.referenceNumber || '',
    startClassificationsSelect: purchaseOrder.startClassificationsSelect || '',
    supplier: purchaseOrder.supplier || '',
    brand: purchaseOrder.brand || '',
    model: purchaseOrder.model || '',
    itemCondition: purchaseOrder.itemCondition || '',
    department: purchaseOrder.department || '',
    subDepartment: purchaseOrder.subDepartment || '',
    bracket: purchaseOrder.bracket || '',
    itemType: purchaseOrder.itemType || '',
    itemSubType: purchaseOrder.itemSubType || '',
    description: purchaseOrder.description || '',
    supplierOrderNo: purchaseOrder.supplierOrderNo || '',
    allocations: allocations,
    pricing: uniquePricing,
    totalQuantity: toString(purchaseOrder.totalQuantity),
    costPerItem: toString(purchaseOrder.costPerItem),
    totalCost: toString(purchaseOrder.totalCost),
    poStatus: purchaseOrder.poStatus || '',
    isCanceled: purchaseOrder.isCanceled || false,
    costOfAlignment: purchaseOrder.costOfAlignment || 0,
  };
};

export const toLegalEngineRequest = (
  formValues: PurchaseOrderFormValues
): LegalEngineRequest => {
  const pricingFields: Array<keyof FormPricing> = [
    'weeklyRent',
    'weeklyTerm',
    'totalRtoPrice',
  ];

  return {
    itemCondition: formValues.itemCondition,
    department: formValues.department,
    subDepartment: formValues.subDepartment,
    allocations: toAllocations(
      formValues.allocations,
      formValues.pricing,
      pricingFields,
      'storeNum' // will change it
    ),
    costPerItem: toNumber(formValues.costPerItem),
    itemType: formValues.itemType,
    itemSubType: formValues.itemSubType,
    bracket: formValues.bracket,
  };
};
