import { PoFormField } from '../constants/constants';
import { Store } from '../domain/Store';
import { FormAllocation, PurchaseOrderFormValues } from '../types/types';
import { createFormAllocation } from './formAllocations';
import { createFormPricing } from './formPricing';

export const generateFormDefaults = (
  stores: Store[],
  purchaseOrder?: PurchaseOrderFormValues
): Partial<PurchaseOrderFormValues> => {
  const serverAllocations = purchaseOrder?.allocations?.map(
    (a: FormAllocation) => createFormAllocation({ ...a, isDefault: true })
  );

  const clientAllocations = [
    stores.length === 1
      ? createFormAllocation({
          storeNum: stores[0].storeNum,
          state: stores[0].state.abbreviation,
          isDefault: true,
        })
      : createFormAllocation({ isDefault: true }),
  ];

  const pricing =
    purchaseOrder?.pricing ||
    (stores.length === 1
      ? [createFormPricing({ state: stores[0].state.abbreviation })]
      : []);

  return {
    purchaseOrderSubType: purchaseOrder?.purchaseOrderSubType,
    referenceNumber: purchaseOrder?.referenceNumber,
    poStatus: purchaseOrder?.poStatus,
    allocations: serverAllocations || clientAllocations,
    totalQuantity: purchaseOrder?.totalQuantity,
    totalCost: purchaseOrder?.totalCost,
    costPerItem: purchaseOrder?.costPerItem,
    pricing,
    // Inventory details
    [PoFormField.Department]: purchaseOrder?.department,
    [PoFormField.SubDepartment]: purchaseOrder?.subDepartment,
    [PoFormField.Bracket]: purchaseOrder?.bracket,
    [PoFormField.ItemType]: purchaseOrder?.itemType,
    [PoFormField.ItemSubType]: purchaseOrder?.itemSubType,
    // Item details
    [PoFormField.Supplier]: purchaseOrder?.supplier,
    [PoFormField.Brand]: purchaseOrder?.brand,
    [PoFormField.Model]: purchaseOrder?.model,
    [PoFormField.SupplierOrderNo]: purchaseOrder?.supplierOrderNo,
    [PoFormField.Description]: purchaseOrder?.description,
  };
};
