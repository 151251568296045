import { makeStyles, RACCard, Typography } from '@rentacenter/racstrap';
import { chain } from 'lodash';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormAllocation, PurchaseOrderFormValues } from '../../types/types';
import { AutoPricingProvider } from '../Pricing/context/AutoPricingProvider';
import { Pricing } from '../Pricing/Pricing';

export interface PricingWrapperProps {
  readonly purchaseOrder: PurchaseOrderFormValues | null;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    flex: 1,
    minWidth: theme.typography.pxToRem(600),
    ['@media (max-width:1240px)']: {
      // eslint-disable-line no-useless-computed-key
      maxWidth: theme.typography.pxToRem(600),
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(8),
    flexWrap: 'wrap',
  },
  cardContentContainer: {
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    minHeight: '267px',
    overflowX: 'hidden',
    borderRadius: '1rem',
  },
}));

export const PricingWrapper = ({ purchaseOrder }: PricingWrapperProps) => {
  const classes = useStyles();

  const allocations = useWatch({
    name: 'allocations',
  });

  const uniqueStates: string[] = chain(allocations)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .filter((a: FormAllocation) => !!a.state)
    .uniqBy((a: FormAllocation) => a.state)
    .map('state')
    .value();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography noWrap variant="h4">
          Pricing
        </Typography>
      </div>
      <RACCard>
        <AutoPricingProvider
          uniqueStates={uniqueStates}
          purchaseOrder={purchaseOrder}
        >
          <div className={classes.cardContentContainer}>
            <Pricing
              purchaseOrder={purchaseOrder}
              uniqueStates={uniqueStates}
              allocations={allocations}
            />
          </div>
        </AutoPricingProvider>
      </RACCard>
    </div>
  );
};
