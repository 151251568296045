import {
  makeStyles,
  RACCard,
  RACSearchDropdown,
  RACTextField,
  Typography,
} from '@rentacenter/racstrap';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  INPUT_MAX_LENGTH,
  PoFormField,
  PoFormLabel,
  VALIDATIONS,
} from '../../constants/constants';
import { PurchaseOrderFormValues } from '../../types/types';
import { isNonEmptyValue } from '../../utils/utils';

export interface ItemDetailsProps {
  purchaseOrder?: PurchaseOrderFormValues | null;
  isViewMode?: boolean;
}
const useStyles = makeStyles((theme: any) => ({
  root: {
    marginBottom: '1.5rem',
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(8),
  },
  cardContentContainer: {
    padding: '1.25rem',
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  field: {
    width: theme.typography.pxToRem(200),
  },
  extraLargeField: {
    minWidth: theme.typography.pxToRem(600),
  },
}));

export const ItemDetails = ({
  purchaseOrder,
  isViewMode,
}: ItemDetailsProps) => {
  const classes = useStyles();
  const { setValue, errors } = useFormContext();
  const [condition, setCondition] = useState({
    value: '',
    label: 'Specify condition',
  });

  useEffect(() => {
    if (purchaseOrder && purchaseOrder.itemCondition) {
      setCondition({
        value: purchaseOrder.itemCondition,
        label: purchaseOrder.itemCondition,
      });
      setValue(PoFormField.Condition, purchaseOrder.itemCondition, {
        shouldValidate: true,
      });
    }
  }, [purchaseOrder, setValue]);

  const onConditionChanged = ({
    value,
    label,
  }: {
    value: string;
    label: string;
  }) => {
    setCondition({ value, label });
    setValue(PoFormField.Condition, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography noWrap variant="h4">
          Item Details
        </Typography>
      </div>
      <RACCard>
        <div className={classes.cardContentContainer}>
          <span className={classes.field}>
            <Controller
              name={PoFormField.Supplier}
              rules={{
                required: true,
                maxLength: 240,
                validate: (value) => isNonEmptyValue(value),
              }}
              render={({ onChange, value, name }) => (
                <RACTextField
                  disabled={isViewMode}
                  required
                  type="text"
                  label={PoFormLabel.Supplier}
                  onChange={onChange}
                  value={value}
                  name={name}
                  errorMessage={
                    errors[PoFormField.Supplier]
                      ? VALIDATIONS[PoFormField.Supplier]
                      : ''
                  }
                />
              )}
            />
          </span>
          <span className={classes.field}>
            <Controller
              name={PoFormField.Brand}
              rules={{
                required: true,
                maxLength: INPUT_MAX_LENGTH,
                validate: (value) => isNonEmptyValue(value),
              }}
              render={({ onChange, value, name }) => (
                <RACTextField
                  disabled={isViewMode}
                  required
                  type="text"
                  label={PoFormLabel.Brand}
                  onChange={onChange}
                  value={value}
                  name={name}
                  errorMessage={
                    errors[PoFormField.Brand]
                      ? VALIDATIONS[PoFormField.Brand]
                      : ''
                  }
                />
              )}
            />
          </span>
          <span className={classes.field}>
            <Controller
              name={PoFormField.Model}
              rules={{
                required: true,
                maxLength: INPUT_MAX_LENGTH,
                validate: (value) => isNonEmptyValue(value),
              }}
              render={({ onChange, value, name }) => (
                <RACTextField
                  disabled={isViewMode}
                  required
                  type="text"
                  label={PoFormLabel.Model}
                  onChange={onChange}
                  value={value}
                  name={name}
                  errorMessage={
                    errors[PoFormField.Model]
                      ? VALIDATIONS[PoFormField.Model]
                      : ''
                  }
                />
              )}
            />
          </span>
          <span className={classes.field}>
            <Controller
              name={PoFormField.Condition}
              rules={{
                required: true,
              }}
              render={({ name }) => {
                return (
                  <RACSearchDropdown
                    name={name}
                    inputLabel={PoFormLabel.Condition}
                    options={[
                      { value: 'New', label: 'New' },
                      { value: 'Refurbished', label: 'Refurbished' },
                    ]}
                    OnChange={onConditionChanged}
                    required
                    disabled={isViewMode}
                    value={condition}
                  />
                );
              }}
            />
          </span>
          <span className={classes.field}>
            <Controller
              name={PoFormField.SupplierOrderNo}
              rules={{
                maxLength: 150,
                validate: (value) => isNonEmptyValue(value),
              }}
              render={({ onChange, value, name }) => (
                <RACTextField
                  disabled={isViewMode}
                  type="text"
                  label={PoFormLabel.SupplierOrderNo}
                  onChange={onChange}
                  value={value}
                  name={name}
                  errorMessage={
                    errors[PoFormField.SupplierOrderNo]
                      ? VALIDATIONS[PoFormField.SupplierOrderNo]
                      : ''
                  }
                />
              )}
            />
          </span>
          <span className={classes.extraLargeField}>
            <Controller
              name={PoFormField.Description}
              rules={{
                required: true,
                maxLength: 255,
                validate: (value) => isNonEmptyValue(value),
              }}
              render={({ onChange, value, name }) => (
                <RACTextField
                  disabled={isViewMode}
                  required
                  type="text"
                  label={PoFormLabel.Description}
                  onChange={onChange}
                  value={value}
                  name={name}
                  errorMessage={
                    errors[PoFormField.Description]
                      ? VALIDATIONS[PoFormField.Description]
                      : ''
                  }
                />
              )}
            />
          </span>
        </div>
      </RACCard>
    </div>
  );
};
