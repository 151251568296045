import { Action, SelectFieldOption } from '../../types/types';

export enum PoSubTypesActionType {
  SET_PO_SUB_TYPES = 'SET_PO_SUB_TYPES',
}

export interface SetPoSubTypesAction extends Action {
  readonly type: PoSubTypesActionType.SET_PO_SUB_TYPES;
  readonly payload: {
    readonly poSubTypes: SelectFieldOption[];
  };
}

export const setPoSubTypesAction = (
  poSubTypes: SelectFieldOption[]
): SetPoSubTypesAction => ({
  type: PoSubTypesActionType.SET_PO_SUB_TYPES,
  payload: { poSubTypes },
});
