import { POStoreActionType } from './poStoreActions';
import { Store } from '../../domain/Store';
import { GlobalContextAction } from '../global/globalReducer';

export const poStoreReducer = (
  state: Store[],
  action: GlobalContextAction
): Store[] =>
  action.type === POStoreActionType.SELECT_PO_STORE
    ? action?.payload?.poStores || []
    : state;
