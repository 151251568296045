import { GenericObject } from '../../constants/constants';
import { GlobalContextAction } from '../global/globalReducer';
import { ClassificationsActionType } from './classificationsActions';

export const classificationsReducer = (
  state: GenericObject,
  action: GlobalContextAction
): GenericObject => {
  if (action.type === ClassificationsActionType.SET_CLASSIFICATIONS) {
    return action.payload.classifications;
  }
  return state;
};
