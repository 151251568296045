import { Dispatch } from 'react';
import { CancelToken } from 'axios';

import {
  ApiAction,
  getErrorAction,
  getLoadingAction,
  getSuccessAction,
} from '../api-actions';
import { Store } from '../../domain/Store';
import { getStores } from '../../api/store';

export const getStoresThunk = async (
  dispatch: Dispatch<ApiAction>,
  cancelToken: CancelToken,
  term?: string
) => {
  try {
    dispatch(getLoadingAction());
    const stores: Store[] = await getStores(term, cancelToken);
    dispatch(getSuccessAction(stores));
  } catch (error: any) {
    if (!error.__CANCEL__) {
      dispatch(getErrorAction(error));
    }
  }
};
