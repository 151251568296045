import { Dispatch } from 'react';
import { CancelToken } from 'axios';

import {
  getSubmittedPosAction,
  getSubmittedPosErrorAction,
  getSubmittedPosSuccessAction,
  SubmittedPosAction,
} from './submitted-pos-actions';
import { Store } from '../../domain/Store';
import { getPurchaseOrdersByStatus } from '../../api/purchaseOrders';
import { submittedPOCancelMessage } from '../../constants/constants';
import { GetPurchaseOrderResponse } from '../../types/types';

export const getSubmittedPurchaseOrders =
  (dispatch: Dispatch<SubmittedPosAction>) =>
  async (
    poStoreNums?: Store[],
    token?: CancelToken,
    offset?: number,
    limit?: number
  ): Promise<void> => {
    try {
      dispatch(getSubmittedPosAction());

      poStoreNums = poStoreNums || [];
      const poStoreNumsStrings: string[] = poStoreNums.map(
        (store) => store.storeNum
      );

      const request = {
        storeNumber: poStoreNumsStrings,
        purchaseOrderType: ['ER'],
      };

      const purchaseOrdersResponse: GetPurchaseOrderResponse =
        await getPurchaseOrdersByStatus(request, offset, limit, token);

      const { purchaseOrders } = purchaseOrdersResponse;

      dispatch(getSubmittedPosSuccessAction(purchaseOrders));
    } catch (e: any) {
      if (e.message !== submittedPOCancelMessage) {
        dispatch(getSubmittedPosErrorAction());
      }
    }
  };
