import React from 'react';
import { RACModal, RACButton, makeStyles } from '@rentacenter/racstrap';
import { format } from 'date-fns';
import { managePurchaseOrder } from '../../context/single/purchaseOrderThunks';
import { useAsync } from 'react-async';
import { CONFIRM_MESSAGE, ManagePOOperation } from '../../constants/constants';

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'center',
    margin: `2rem 0 1.5rem`,
    height: '20%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
  },
  dialogActions: {
    justifyContent: 'flex-end',
    position: 'sticky',
    bottom: 0,
  },
  dialogRoot: {
    height: '100%',
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialog-paperScrollPaper': {
      width: '43%',
      minHeight: theme.typography.pxToRem(230),
      minWidth: theme.typography.pxToRem(300),
    },
    '& .MuiDialogTitle-root': {
      display: 'none',
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
  contentHeight: {
    height: '30%',
    overflow: 'scroll',
  },
  confirmSpan: {
    marginTop: '0.75rem',
  },
}));
export interface CancelPOModalProps {
  id: string;
  onResolve: (type: string) => void;
  onClose: () => void;
}

export const CancelPOConfirmModal = ({
  id,
  onResolve,
  onClose,
}: CancelPOModalProps) => {
  const classes = useStyles();

  const { run, isPending } = useAsync({
    deferFn: (purchaseOrderId: string[]) => {
      const managePORequest = {
        operation: ManagePOOperation.Cancel,
        cancelInfo: {
          purchaseOrderNumber: purchaseOrderId.shift() || '',
          cancelDate: format(new Date(), 'yyyy-MM-dd'),
        },
      };
      return managePurchaseOrder(managePORequest);
    },
    onResolve: () => onResolve('success'),
    onReject: (error: any) => onResolve(error?.errors[0]?.message),
  });

  return (
    <RACModal
      isOpen
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
      }}
      maxWidth="lg"
      title="Cancel PO"
      content={
        <>
          <span>You are about to cancel this PO.</span>
          <span className={classes.confirmSpan}>{CONFIRM_MESSAGE}</span>
        </>
      }
      onClose={onClose}
      buttons={
        <>
          <RACButton
            variant="contained"
            color="primary"
            disabled={isPending}
            onClick={onClose}
          >
            No
          </RACButton>
          <RACButton
            disabled={isPending}
            loading={isPending}
            variant="outlined"
            color="secondary"
            onClick={() => run(id)}
          >
            Yes, Cancel
          </RACButton>
        </>
      }
    />
  );
};
