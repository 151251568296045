import React, { Dispatch, ReactNode, createContext, useReducer } from 'react';
import { Store } from '../../domain/Store';
import { noOp } from '../../utils/utils';

import { ApiAction, ApiActionType } from '../api-actions';

export interface StoresState {
  stores: Store[];
  loading: boolean;
  hadError: boolean;
}

export const defaultStoresState: StoresState = {
  stores: [],
  loading: false,
  hadError: false,
};

function storesReducer(state: StoresState, action: ApiAction): StoresState {
  switch (action.type) {
    case ApiActionType.LOADING:
      return { ...state, loading: true };
    case ApiActionType.SUCCESS:
      return { ...state, loading: false, stores: action.payload as Store[] };
    case ApiActionType.ERROR:
      return { ...state, loading: false, hadError: true };
  }
}

export function useStores() {
  return useReducer(storesReducer, defaultStoresState);
}

export const StoresStateContext =
  createContext<StoresState>(defaultStoresState);
export const StoresDispatchContext = createContext<Dispatch<ApiAction>>(noOp);

export const StoresProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useStores();

  return (
    <StoresStateContext.Provider value={state}>
      <StoresDispatchContext.Provider value={dispatch}>
        {props.children}
      </StoresDispatchContext.Provider>
    </StoresStateContext.Provider>
  );
};
