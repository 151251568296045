import { SelectedPOClearedActionType } from './selectedPOClearedActions';
import { GlobalContextAction } from '../global/globalReducer';

export const selectedPOClearedReducer = (
  state: boolean,
  action: GlobalContextAction
): boolean =>
  action.type === SelectedPOClearedActionType.SELECTED_PO_STORE
    ? action?.payload?.selectedPOStore || false
    : state;
