import React from 'react';
import { RACModal, RACButton, makeStyles } from '@rentacenter/racstrap';
import { noOp } from '../../utils/utils';
import {
  CANCEL_CREATE_LOSE_WORK,
  CANCEL_EDIT_LOSE_WORK,
  CONFIRM_MESSAGE,
} from '../../constants/constants';

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'center',
    marginTop: `3rem`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
  },
  dialogActions: {
    justifyContent: 'flex-end',
    position: 'sticky',
    bottom: 0,
  },
  dialogRoot: {
    height: '100%',
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialog-paperScrollPaper': {
      width: '43%',
      minHeight: theme.typography.pxToRem(230),
      minWidth: theme.typography.pxToRem(300),
    },
    '& .MuiDialogTitle-root': {
      display: 'none',
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
  contentHeight: {
    height: '30%',
    overflow: 'scroll',
  },
  confirmSpan: {
    marginTop: '0.75rem',
  },
}));
export interface LooseWorkModalProps {
  isEditPo: boolean;
  onClose: () => void;
  handleCancelButtonClick: () => void;
}

export const LooseWorkModal = ({
  isEditPo,
  handleCancelButtonClick,
  onClose,
}: LooseWorkModalProps) => {
  const classes = useStyles();

  return (
    <RACModal
      isOpen
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
      }}
      maxWidth="lg"
      title="Cancel PO"
      content={
        <>
          <span>
            {isEditPo ? CANCEL_EDIT_LOSE_WORK : CANCEL_CREATE_LOSE_WORK}
          </span>
          <span className={classes.confirmSpan}>{CONFIRM_MESSAGE}</span>
        </>
      }
      onClose={() => noOp()}
      buttons={
        <>
          <RACButton variant="contained" color="primary" onClick={onClose}>
            No
          </RACButton>
          <RACButton
            variant="outlined"
            color="secondary"
            onClick={handleCancelButtonClick}
          >
            Yes, Cancel
          </RACButton>
        </>
      }
    />
  );
};
