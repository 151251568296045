import { RACSearchDropdown } from '@rentacenter/racstrap';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ClassificationsInputPlaceholder,
  emptyField,
  PoFormField,
} from '../../constants/constants';
import { ClassificationType } from '../../domain/PurchaseOrder/ClassificationType';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';
import { SelectedFieldOption } from '../../types/types';
import { filterById, populateSelectByChild } from '../../utils/utils';
import {
  useClassificationsDispatch,
  useClassificationsState,
} from './context/Provider';
import { ClassificationsComponentProps } from './context/types';

export const subTypeTestId = 'subTypeComponent';

// eslint-disable-next-line
export const SubType = (props: ClassificationsComponentProps) => {
  const { name, label, purchaseOrder } = props;
  const { setValue } = useFormContext();
  const { subTypes, startWithTypes } = useClassificationsState();
  const dispatch = useClassificationsDispatch();
  const [itemValue, setItemValue] = useState(emptyField);

  // eslint-disable-next-line
  useEffect(() => {
    const poItemSubType = purchaseOrder && purchaseOrder.itemSubType;

    const selectedSubType: {
      name: string;
      id: string;
      subTypes: [];
    } | null = poItemSubType ? filterById(subTypes, poItemSubType)[0] : null;

    if (subTypes.length === 1) {
      setItemValue({ value: subTypes[0].id, label: subTypes[0].name });

      if (startWithTypes) {
        dispatch({
          type: ClassificationType.SET_SINGLE_DEPARTMENT,
          payload: subTypes[0].departments,
        });
      }
    } else {
      setItemValue({
        value: (selectedSubType && selectedSubType.id) || '',
        label:
          (selectedSubType && selectedSubType.name) ||
          ClassificationsInputPlaceholder,
      });

      if (startWithTypes) {
        dispatch({
          type: ClassificationType.CLEAR_DEPARTMENTS,
        });
        if (selectedSubType) {
          dispatch({
            type: ClassificationType.SET_DEPARTMENTS,
            payload: filterById(subTypes, selectedSubType.id)[0].departments,
          });
        }
      }

      !startWithTypes &&
        selectedSubType &&
        dispatch({
          type: ClassificationType.SET_SUBTYPES,
          payload: selectedSubType.subTypes,
        });
    }
    // eslint-disable-next-line
  }, [subTypes, dispatch, startWithTypes]);

  const handleChange = useCallback(
    (selected: SelectedFieldOption) => {
      const { value, label } = selected;
      setItemValue({ value, label });
      setValue(name, value, { shouldValidate: true, shouldDirty: true });

      if (startWithTypes) {
        const filterdDepartments = filterById(subTypes, value)[0].departments;
        let singleSubDepartment = filterdDepartments[0].subDepartments;
        let singleBracket = singleSubDepartment[0].brackets;

        if (purchaseOrder && purchaseOrder.itemSubType !== value) {
          purchaseOrder.department = '';
          purchaseOrder.subDepartment = '';
          purchaseOrder.bracket = '';
        }
        dispatch({
          type: ClassificationType.SET_DEPARTMENTS,
          payload: filterdDepartments,
        });
        if (filterdDepartments.length === 1) {
          setValue(PoFormField.Department, filterdDepartments[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setValue(PoFormField.Department, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }

        if (singleSubDepartment.length === 1) {
          setValue(PoFormField.SubDepartment, singleSubDepartment[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          singleSubDepartment = [];
          setValue(PoFormField.SubDepartment, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
        if (singleBracket.length === 1) {
          setValue(PoFormField.Bracket, singleBracket[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          singleBracket = [];
          setValue(PoFormField.Bracket, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
        dispatch({
          type: ClassificationType.SET_SUBTYPES,
          payload: filterById(subTypes, value)[0].departments,
        });
      }
    },
    [dispatch, purchaseOrder, name, setValue, startWithTypes, subTypes]
  );

  const isViewMode =
    purchaseOrder &&
    (purchaseOrder.poStatus === PoStatus.SUBMITTED ||
      purchaseOrder.poStatus === PoStatus.OPEN_CANCELLED);

  return useMemo(
    () => (
      <Controller
        name={name}
        rules={{ required: true }}
        render={({ name }) => (
          <RACSearchDropdown
            name={name}
            inputLabel={label}
            options={populateSelectByChild(subTypes) || []}
            OnChange={handleChange}
            required
            disabled={isViewMode || !subTypes.length}
            value={itemValue}
          />
        )}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isViewMode, itemValue, label, name, purchaseOrder, subTypes]
  );
};
