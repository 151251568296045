import { GenericObject } from '../../constants/constants';
import { Store } from '../../domain/Store';
import { SelectFieldOption } from '../../types/types';
import { SetClassificationsAction } from '../classifications/classificationsActions';
import { classificationsReducer } from '../classifications/classificationsReducer';
import { poSubTypesReducer } from '../poSubTypes/poSubTypeReducer';
import { SetPoSubTypesAction } from '../poSubTypes/poSubTypesActions';
import { SelectPOStoreAction } from '../reducer/poStoreActions';
import { poStoreReducer } from '../reducer/poStoreReducer';
import { SelectedPOClearedAction } from '../reducer/selectedPOClearedActions';
import { selectedPOClearedReducer } from '../reducer/selectedPOClearedReducer';

export interface GlobalState {
  readonly poStores: Store[];
  readonly poSubTypes: SelectFieldOption[];
  readonly classifications: GenericObject;
  readonly selectedPOCleared: boolean;
}

export type GlobalContextAction =
  | SelectedPOClearedAction
  | SelectPOStoreAction
  | SetPoSubTypesAction
  | SetClassificationsAction;

export const initialState: GlobalState = {
  poStores: [],
  poSubTypes: [],
  classifications: {},
  selectedPOCleared: false,
};

export const globalReducer = (
  state: GlobalState,
  action: GlobalContextAction
): GlobalState => {
  const { classifications, poStores, poSubTypes, selectedPOCleared } = state;

  return {
    classifications: classificationsReducer(classifications, action),
    poStores: poStoreReducer(poStores, action),
    selectedPOCleared: selectedPOClearedReducer(selectedPOCleared, action),
    poSubTypes: poSubTypesReducer(poSubTypes, action),
  };
};
