export enum AppRoute {
  Index = '/',
  IndexWithAppName = '/mpo',
  POs = '/mpo/pos',
  // used by router
  Details = '/mpo/details/:purchaseOrderId/:storeNumber',
  DraftDetails = '/mpo/draftDetails/:purchaseOrderId',
  // used when user click on PO
  DetailsNavigation = '/mpo/details',
  DraftDetailsNavigation = '/mpo/draftDetails',
  Create = '/mpo/create',
  ReceivingPO = '/receivingpo/purchaseorders',
}
