import {
  makeStyles,
  RACButton,
  RACCard,
  RACCheckBox,
  RACDateRangePicker,
  RACTextField,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import { add, format } from 'date-fns';
import React, { useState } from 'react';
import {
  POSearchFormLabel,
  REQUIRED_FIELD_MESSAGE,
  TO_DATE_TOO_BIG,
  INVALID_TO_DATE,
  PO_NUMBER_INVALID_MESSAGE,
} from '../../constants/constants';
import { TWELVE_MONTHS_AGO, TWELVE_MONTHS_AFTER } from '../../utils/utils';

const dateFormat = 'yyyy-MM-dd';

const useStyles = makeStyles((theme: any) => ({
  searchRoot: {
    width: '100%',
    marginBottom: '1rem',
    display: 'block',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(32),
  },
  column: {
    display: 'flex',
    flex: 4,
    flexWrap: 'wrap',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),
  },
  buttonsColumn: {
    flex: 1,
    alignItems: 'flex-end',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardBody: {
    flex: '1 1 auto',
    padding: '2rem 2rem',
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: theme.typography.pxToRem(42),
    alignSelf: 'center',
    marginTop: theme.typography.pxToRem(19),
    gap: theme.typography.pxToRem(24),
  },
  field: {
    width: '12rem',
    '& input': {
      height: theme.typography.pxToRem(25),
    },
  },
  noPaddingLeft: {
    paddingLeft: '0 !important',
  },
  checkbox: {
    marginTop: theme.typography.pxToRem(20),
  },
}));

const PONumberSearch = new RegExp(/^\d{1,18}$|^M.{1,14}$/);

interface searchProps {
  setFilter: (poNumber: string, fromDate: string, toDate: string) => void;
  clearFilter: () => void;
}

export const Search = ({ setFilter, clearFilter }: searchProps) => {
  const classes = useStyles();

  const [fromDate, setFromDate] = useState('');
  const [maxTo, setMaxTo] = useState('');
  const [toDate, setToDate] = useState('');
  const [poNumber, setPONumber] = useState('');
  const [poNumberError, setPONumberError] = useState('');
  const [toDateRangeError, setToDateRangeError] = useState('');
  const [fromDateRangeError, setFromDateRangeError] = useState('');
  const [isSearchBySubmittedChecked, setIsSearchBySubmittedChecked] =
    useState(false);
  const isFormInValid = !fromDate && !toDate && !poNumber;

  const onClear = () => {
    setFromDate('');
    setMaxTo('');
    setToDate('');
    setPONumber('');
    setPONumberError('');
    setFromDateRangeError('');
    setToDateRangeError('');
    clearFilter();
  };

  const setMaxInState = (value: string) => {
    const fromdateAsDate = new Date(value);
    const todateAsDate = add(fromdateAsDate, { days: 31 });

    setMaxTo(format(todateAsDate, dateFormat));
  };

  const validateForm = () => {
    if (isFormInValid) return false;
    setPONumberError('');

    if (poNumber && !PONumberSearch.test(poNumber)) {
      setPONumberError(PO_NUMBER_INVALID_MESSAGE);
      return false;
    }

    if (fromDate && !toDate) {
      setToDateRangeError(REQUIRED_FIELD_MESSAGE);
      return false;
    }

    if (toDate && !fromDate) {
      setFromDateRangeError(REQUIRED_FIELD_MESSAGE);
      return false;
    }

    const todateAsDate = new Date(toDate);
    let fromdateAsDate = new Date(fromDate);
    const fromDateNoChange = new Date(fromDate);
    fromdateAsDate = add(fromdateAsDate, { days: 30 });

    if (todateAsDate > fromdateAsDate) {
      setToDateRangeError(TO_DATE_TOO_BIG);
      return false;
    }

    if (todateAsDate < fromDateNoChange) {
      setToDateRangeError(INVALID_TO_DATE);
      return false;
    }

    return true;
  };

  const handleSearchClick = () => {
    if (!validateForm()) return;

    setFilter(poNumber, fromDate, toDate);
  };

  return (
    <div className={clsx(classes.searchRoot)}>
      <RACCard className={classes.card}>
        <div className={clsx(classes.cardBody)}>
          <div className={clsx(classes.column)}>
            <div className={clsx(classes.row)}>
              <div className={clsx(classes.field)}>
                <RACTextField
                  label={POSearchFormLabel.PONumber}
                  type="text"
                  onChange={setPONumber}
                  value={poNumber}
                  name={POSearchFormLabel.PONumber}
                  errorMessage={poNumberError}
                />
              </div>
              <div className={clsx(classes.column)}>
                <div className={clsx(classes.row)}>
                  <div className={classes.checkbox}>
                    <RACCheckBox
                      color="primary"
                      checked={isSearchBySubmittedChecked}
                      label={POSearchFormLabel.SearchBySubmittedDate}
                      onChange={(event: any) => {
                        event.target.checked && setPONumber('');
                        setIsSearchBySubmittedChecked(event.target.checked);
                      }}
                    />
                  </div>
                  <div className={clsx(classes.field)}>
                    <RACDateRangePicker
                      disabled={!isSearchBySubmittedChecked}
                      name="dateRange"
                      fromLabel="From:"
                      toLabel="To:"
                      fromValue={fromDate}
                      toValue={toDate}
                      minFrom={format(TWELVE_MONTHS_AGO, dateFormat)}
                      maxFrom={format(TWELVE_MONTHS_AFTER, dateFormat)}
                      minTo={format(TWELVE_MONTHS_AGO, dateFormat)}
                      maxTo={maxTo}
                      onFromChanged={(value) => {
                        setFromDateRangeError('');
                        setToDateRangeError('');
                        setFromDate(value);
                        setMaxInState(value);
                      }}
                      onToChanged={(value) => {
                        setToDateRangeError('');
                        setFromDateRangeError('');
                        setToDate(value);
                      }}
                      fromErrorMessage={fromDateRangeError}
                      toErrorMessage={toDateRangeError}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={clsx(classes.column, classes.buttonsColumn)}>
            <div className={classes.row}>
              <div className={classes.buttonContainer}>
                <RACButton
                  variant="text"
                  size="large"
                  color="primary"
                  onClick={onClear}
                >
                  Clear
                </RACButton>
                <RACButton
                  type="submit"
                  form="searchPOForm"
                  variant="contained"
                  size="small"
                  color="primary"
                  key="submitPO"
                  loading={false}
                  disabled={isFormInValid}
                  onClick={handleSearchClick}
                >
                  Search
                </RACButton>
              </div>
            </div>
          </div>
        </div>
      </RACCard>
    </div>
  );
};
