import { appConfig } from '../config/app-config';
import { client } from './client';

export function getClassifications() {
  return client(
    'classifications/processed',
    { method: 'GET' },
    appConfig.apiUrls.micro
  );
}
