import { RACSearchDropdown } from '@rentacenter/racstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  ClassificationsInputPlaceholder,
  emptyField,
  PoFormField,
} from '../../constants/constants';
import { ClassificationType } from '../../domain/PurchaseOrder/ClassificationType';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';
import { SelectedFieldOption } from '../../types/types';
import { filterById, populateSelectByChild } from '../../utils/utils';
import {
  useClassificationsDispatch,
  useClassificationsState,
} from './context/Provider';
import { ClassificationsComponentProps } from './context/types';

export const departmentTestId = 'departmentComponent';

// eslint-disable-next-line
export const Department = (props: ClassificationsComponentProps) => {
  const { name, label, purchaseOrder } = props;
  const { setValue, getValues } = useFormContext();
  const dispatch = useClassificationsDispatch();
  const { initialDepartments, departments, startWithTypes } =
    useClassificationsState();
  const [itemValue, setItemValue] = useState(emptyField);

  useEffect(
    // eslint-disable-next-line
    () => {
      const poDepartment = purchaseOrder && purchaseOrder.department;
      const poStartClassificationsSelect =
        purchaseOrder && purchaseOrder.startClassificationsSelect;

      const optionDepartments =
        departments && departments.length ? departments : initialDepartments;
      const selectedDepartment: {
        name: string;
        id: string;
        subDepartments?: [];
      } | null = poDepartment && filterById(optionDepartments, poDepartment)[0];

      if (startWithTypes) {
        if (departments && departments.length === 1) {
          setItemValue({
            value: departments[0].id,
            label: departments[0].name,
          });

          dispatch({
            type: ClassificationType.SET_SINGLE_SUBDEPARTMENT,
            payload: departments[0].subDepartments,
          });
        } else {
          setItemValue(emptyField);
          dispatch({
            type: ClassificationType.CLEAR_SUBDEPARTMENTS,
          });
        }
      }

      if (initialDepartments && poDepartment && selectedDepartment) {
        if (poStartClassificationsSelect === 'department' && !startWithTypes) {
          dispatch({
            type: ClassificationType.SET_DEPARTMENT,
            payload: selectedDepartment,
          });
        }

        if (startWithTypes) {
          if (departments && departments.length === 0) {
            dispatch({
              type: ClassificationType.SET_DEPARTMENTS,
              payload: [selectedDepartment],
            });
          }

          dispatch({
            type: ClassificationType.SET_SUBDEPARTMENTS_FROM_DEPARTMENT,
            payload: selectedDepartment.subDepartments,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [departments, startWithTypes, initialDepartments, dispatch]
  );

  useEffect(
    // eslint-disable-next-line
    () => {
      const poDepartment = purchaseOrder && purchaseOrder.department;

      const optionDepartments =
        departments && departments.length ? departments : initialDepartments;
      const selectedDepartment: {
        name: string;
        id: string;
        subDepartments?: [];
      } | null = poDepartment && filterById(optionDepartments, poDepartment)[0];

      poDepartment &&
        setItemValue({
          value: poDepartment || '',
          label:
            (selectedDepartment && selectedDepartment.name) ||
            ClassificationsInputPlaceholder,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [departments, initialDepartments]
  );

  const formStartClassificationsSelect =
    getValues()?.startClassificationsSelect;

  const handleChange = useCallback(
    // eslint-disable-next-line
    (selected: SelectedFieldOption) => {
      const { value, label, subDepartments } = selected;

      setItemValue({ value, label });
      setValue(name, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
      formStartClassificationsSelect !== PoFormField.ItemSubType &&
        setValue(
          PoFormField.StartClassificationsSelect,
          PoFormField.Department
        );

      if (startWithTypes) {
        dispatch({
          type: ClassificationType.SET_SUBDEPARTMENTS_FROM_DEPARTMENT,
          payload: subDepartments,
        });
      }

      if (startWithTypes && purchaseOrder && subDepartments) {
        let brackets = subDepartments[0].brackets;

        if (purchaseOrder && purchaseOrder.department !== value) {
          purchaseOrder.subDepartment = '';
          purchaseOrder.bracket = '';
          purchaseOrder.itemType = '';
          purchaseOrder.itemSubType = '';
        }

        if (subDepartments.length === 1) {
          setValue(PoFormField.SubDepartment, subDepartments[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          brackets = [];

          setValue(PoFormField.SubDepartment, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }

        if (brackets && brackets.length === 1) {
          setValue(PoFormField.Bracket, brackets[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          brackets = [];

          setValue(PoFormField.Bracket, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }

      if (!startWithTypes && subDepartments) {
        dispatch({
          type: ClassificationType.SET_DEPARTMENT,
          payload: selected,
        });

        let brackets = subDepartments[0].brackets;
        let types = brackets[0].types;
        let subTypes = types[0].subTypes;

        if (subDepartments.length === 1) {
          setValue(PoFormField.SubDepartment, subDepartments[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          brackets = [];
          types = [];
          subTypes = [];
          setValue(PoFormField.SubDepartment, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }

        if (brackets && brackets.length === 1) {
          setValue(PoFormField.Bracket, brackets[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          brackets = [];
          types = [];
          subTypes = [];
          setValue(PoFormField.Bracket, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }

        if (types && types.length === 1) {
          setValue(PoFormField.ItemType, types[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          brackets = [];
          types = [];
          subTypes = [];
          setValue(PoFormField.ItemType, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }

        if (subTypes && subTypes.length === 1) {
          setValue(PoFormField.ItemSubType, subTypes[0].id, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          brackets = [];
          types = [];
          subTypes = [];
          setValue(PoFormField.ItemSubType, '', {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
    },
    [
      name,
      purchaseOrder,
      startWithTypes,
      dispatch,
      formStartClassificationsSelect,
      setValue,
    ]
  );

  const departmentOptions = useCallback(() => {
    return startWithTypes
      ? departments && populateSelectByChild(departments, 'subDepartments')
      : populateSelectByChild(initialDepartments, 'subDepartments');
  }, [departments, initialDepartments, startWithTypes]);

  const isViewMode =
    purchaseOrder &&
    (purchaseOrder.poStatus === PoStatus.SUBMITTED ||
      purchaseOrder.poStatus === PoStatus.OPEN_CANCELLED);

  return (
    <Controller
      name={name}
      rules={{ required: true }}
      render={({ name }) => (
        <RACSearchDropdown
          name={name}
          inputLabel={label}
          options={departmentOptions() || []}
          OnChange={handleChange}
          required
          disabled={!!isViewMode}
          value={itemValue}
        />
      )}
    />
  );
};
