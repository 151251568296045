import { PurchaseOrder } from '../../domain/PurchaseOrder/PurchaseOrder';
import {
  SubmittedPosAction,
  SubmittedPosActionType,
} from './submitted-pos-actions';

export interface SubmittedPosState {
  purchaseOrders?: PurchaseOrder[];
  isPending: boolean;
  hasError: boolean;
}

export const submittedPosInitialState: SubmittedPosState = {
  isPending: false,
  hasError: false,
};

export const submittedPosReducer = (
  state: SubmittedPosState,
  action: SubmittedPosAction
): SubmittedPosState => {
  switch (action.type) {
    case SubmittedPosActionType.GET_SUBMITTED_POS:
      return {
        ...state,
        isPending: true,
        hasError: false,
      };

    case SubmittedPosActionType.GET_SUBMITTED_POS_SUCCESS:
      return {
        ...state,
        purchaseOrders: action.payload.purchaseOrders,
        isPending: false,
        hasError: false,
      };

    case SubmittedPosActionType.GET_SUBMITTED_POS_ERROR:
      return {
        ...state,
        isPending: false,
        hasError: true,
      };

    default:
      return state;
  }
};
