import clsx from 'clsx';
import React from 'react';
import { StoresState } from '../../../../context/stores/StoresProvider';
import { getStoreSearchName, Store } from '../../../../domain/Store';
import { noOp } from '../../../../utils/utils';
import { SearchEntry } from '../search-entry/SearchEntry';

import styles from './SearchList.module.scss';

interface Props {
  storeList: StoresState;
  selectedStores: Store[];
  onChange?: (newStores: Store[]) => void;
}

export const errorMessage =
  'There was an error while retrieving search results. Please try again.';
export const loadingMessage = 'Searching stores ...';
export const noStoresMessage =
  'No stores were found matching your search request.';

export const SearchList = ({
  storeList,
  selectedStores,
  onChange = noOp,
}: Props) => {
  const addStore = (store: Store) => {
    const foundStore = storeList.stores.find(
      (storeItem: any) => storeItem.storeId === store.storeId
    );

    if (!foundStore) {
      return;
    }

    onChange([...selectedStores, foundStore]);
  };

  const removeStore = (store: Store) => {
    const stores = [...selectedStores];
    const storeIndex = stores.findIndex(
      (storeItem) => storeItem.storeId === store.storeId
    );
    stores.splice(storeIndex, 1);

    onChange(stores);
  };

  const isStoreSelected = (store: Store) => {
    return !!selectedStores.find(
      (selectedStore) => selectedStore.storeId === store.storeId
    );
  };

  return (
    <div className={styles.searchList}>
      {storeList.hadError ? (
        <div className={clsx([styles.info, styles.error])}>{errorMessage}</div>
      ) : storeList.loading ? (
        <div className={styles.info}>{loadingMessage}</div>
      ) : storeList.stores.length === 0 ? (
        <div className={styles.info}>{noStoresMessage}</div>
      ) : (
        storeList.stores.map((store: any) => (
          <SearchEntry
            checked={isStoreSelected(store)}
            key={store.storeId}
            label={getStoreSearchName(store)}
            onChange={(isSelected) => {
              if (isSelected) {
                addStore(store);
              } else {
                removeStore(store);
              }
            }}
          />
        ))
      )}
    </div>
  );
};
