import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { AutoNumeric } from '../../common/AutoNumeric/AutoNumeric';
import { DEFAULT_NR_OF_DECIMALS } from '../../constants/constants';
import { PoStatus } from '../../domain/PurchaseOrder/PoStatus';
import { PurchaseOrderFormValues } from '../../types/types';

export interface PricingElement {
  fieldName: string;
  min?: number;
  max?: number;
  required?: boolean;
  disabled?: boolean;
  purchaseOrder?: PurchaseOrderFormValues | null;
  defaultValue?: string | undefined;
  decimalPlaces?: number;
}

export const PricingElement = (props: PricingElement) => {
  const {
    fieldName,
    min,
    max,
    required,
    disabled,
    purchaseOrder,
    defaultValue,
    decimalPlaces,
  } = props;
  const isSubmitted = purchaseOrder?.poStatus === PoStatus.SUBMITTED;

  return useMemo(
    () => (
      <Controller
        as={AutoNumeric}
        name={fieldName}
        rules={{ required: required, min, max }}
        imperativeMode
        imperativeValue={defaultValue}
        options={{
          minimumValue: 0,
          maximumValue: max || 99999999,
          decimalPlaces:
            decimalPlaces === undefined || decimalPlaces === null
              ? DEFAULT_NR_OF_DECIMALS
              : decimalPlaces,
        }}
        disabled={disabled || isSubmitted}
        defaultValue={defaultValue}
        placeholder=""
      />
    ),
    [
      fieldName,
      max,
      min,
      required,
      disabled,
      isSubmitted,
      defaultValue,
      decimalPlaces,
    ]
  );
};
