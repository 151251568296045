import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AutoNumeric } from '../../common/AutoNumeric/AutoNumeric';
import { FieldWithTooltip } from '../../common/FieldWithTooltip/FieldWithTooltip';
import {
  PoFormField,
  PoFormLabel,
  VALIDATIONS,
} from '../../constants/constants';
import { PurchaseOrderFormValues } from '../../types/types';

export const costPerItemTestId = 'CostPerItemComponent';

interface Props {
  readonly purchaseOrder: PurchaseOrderFormValues | null;
  readonly disabled: boolean;
}

export const CostPerItem = (props: Props) => {
  const { purchaseOrder, disabled } = props;
  const { errors } = useFormContext();

  const errorType = errors.costPerItem?.type;

  return useMemo(
    () => (
      <div style={{ flex: 1 }} data-testid={costPerItemTestId}>
        <FieldWithTooltip
          id={PoFormField.CostPerItem}
          currency
          title={PoFormLabel.CostPerItem}
          required={true}
          errorMessage={errorType ? VALIDATIONS[PoFormField.CostPerItem] : ''}
        >
          <Controller
            as={AutoNumeric}
            name={PoFormField.CostPerItem}
            defaultValue={purchaseOrder?.costPerItem || ''}
            options={{
              minimumValue: 0,
              maximumValue: 9999.0,
              decimalPlaces: 2,
              restrictZeroFirstChar: true,
            }}
            disabled={disabled}
            rules={{ required: true, min: 1 }}
            hasError={!!errorType}
            changeOnBlureOnly
          />
        </FieldWithTooltip>
      </div>
    ),
    [errorType, purchaseOrder?.costPerItem, disabled]
  );
};
