import { PoFormLabel } from '../constants/constants';
import { FormPricing, FormPricingKeys } from '../types/types';

export interface FormPricingItemConfig {
  label: PoFormLabel;
  validations: { min?: number; max?: number };
}

export type FormPricingConfig = {
  [key in FormPricingKeys]: FormPricingItemConfig;
};

export const createFormPricing = (
  partial: Partial<FormPricing> = {}
): FormPricing => ({
  state: '',
  weeklyRent: '',
  weeklyTerm: '',
  biWeeklyRent: '',
  biWeeklyTerm: '',
  semiMonthlyRent: '',
  semiMonthlyTerm: '',
  monthlyRent: '',
  monthlyTerm: '',
  cashPrice: '',
  totalRtoPrice: '',
  ...partial,
});
