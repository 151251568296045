/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './SearchPO.module.scss';

import { SearchBox } from './search-box/SearchBox';
import { SearchSelection } from './search-selection/SearchSelection';
import { useGlobalContext } from '../../context/global/GlobalContext';
import { Store } from '../../domain/Store';
import { selectPOStoreAction } from '../../context/reducer/poStoreActions';
import { StoresStateContext } from '../../context/stores/StoresProvider';
import { GET_STORES_LIMIT } from '../../constants/constants';
import { selectedPOClearedAction } from '../../context/reducer/selectedPOClearedActions';

export const SearchPOTestId = 'SearchPOTestId';

export const SearchPO = () => {
  const [isCollapsed, setCollapsed] = useState(true);
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);

  const { poStores, dispatch, selectedPOCleared } = useGlobalContext();
  const { hadError: hadStoreError } = useContext(StoresStateContext);

  const { stores: initialStores } = useContext(StoresStateContext);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (
      initialStores?.length &&
      initialStores?.length < GET_STORES_LIMIT &&
      !poStores?.length &&
      !selectedPOCleared
    ) {
      setSelectedStores(initialStores);
      dispatch(selectPOStoreAction(initialStores));
    } else {
      setSelectedStores(poStores || []);
    }
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (initialStores?.length < GET_STORES_LIMIT && !selectedPOCleared) {
      setSelectedStores(initialStores);
      dispatch(selectPOStoreAction(initialStores));
    }
  }, [initialStores]);

  const applySearch = () => {
    dispatch(selectedPOClearedAction(true));
    dispatch(selectPOStoreAction(selectedStores));
    setCollapsed(true);
  };

  const handleSelectionChange = (stores: Store[]) => {
    setSelectedStores(stores);
    if (isCollapsed) {
      dispatch(selectedPOClearedAction(true));
      dispatch(selectPOStoreAction(stores));
    }
  };

  useEffect(
    function initializeSelectedStores() {
      if (isFirstRender && !poStores?.length) return;
      setSelectedStores(poStores || []);
    },
    [poStores]
  );

  return (
    <>
      <div
        className={clsx({
          [styles.searchPO]: true,
          [styles.disabled]: hadStoreError,
        })}
        data-testid={SearchPOTestId}
      >
        <label>
          <span className={styles.requiredIndicator}>*</span>
          Select Stores
        </label>

        <div
          className={clsx({
            [styles.searchWrap]: true,
            [styles.collapsed]: isCollapsed,
          })}
        >
          <SearchBox
            isCollapsed={isCollapsed}
            setCollapsed={setCollapsed}
            selectedStores={selectedStores}
            setSelectedStores={setSelectedStores}
            onApply={applySearch}
            isDisabled={hadStoreError}
          />
          <SearchSelection
            isCollapsed={isCollapsed}
            selectedStores={selectedStores}
            onChange={handleSelectionChange}
            isDisabled={hadStoreError}
          />
        </div>
      </div>
      <div className={styles.placeholder} />
    </>
  );
};
