import React from 'react';
import { RACModal, RACButton, makeStyles } from '@rentacenter/racstrap';
import { managePurchaseOrder } from '../../context/single/purchaseOrderThunks';
import { useAsync } from 'react-async';
import { CONFIRM_MESSAGE, OPERATIONS } from '../../constants/constants';

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'center',
    margin: `2rem 0 1rem`,
    height: '20%',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
  },
  dialogActions: {
    justifyContent: 'flex-end',
    position: 'sticky',
    bottom: 0,
  },
  dialogRoot: {
    height: '100%',
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialog-paperScrollPaper': {
      width: '43%',
      minHeight: theme.typography.pxToRem(230),
      minWidth: theme.typography.pxToRem(300),
    },
    '& .MuiDialogTitle-root': {
      display: 'none',
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
  contentHeight: {
    height: '30%',
    overflow: 'scroll',
  },
  confirmSpan: {
    marginTop: '0.75rem',
  },
}));
export interface DeleteModalProps {
  id: string;
  onResolve: (type: string) => void;
  onClose: () => void;
}

export const DeletePOConfirmModal = ({
  id,
  onResolve,
  onClose,
}: DeleteModalProps) => {
  const classes = useStyles();

  const { run, isPending } = useAsync({
    deferFn: (draftId: string[]) => {
      return managePurchaseOrder({
        operation: OPERATIONS.DRAFT_DELETE,
        draftDeleteInfo: {
          draftPurchaseOrderId: draftId[0],
        },
      });
    },
    onResolve: () => onResolve('success'),
    onReject: (error: any) => onResolve(error?.errors[0]?.message),
  });

  return (
    <RACModal
      isOpen
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
      }}
      maxWidth="lg"
      title={''}
      content={
        <>
          <span>
            By deleting the PO you will permanently remove it from the
            application.
          </span>
          <span className={classes.confirmSpan}>
            This action cannot be undone.
          </span>
          <span className={classes.confirmSpan}>{CONFIRM_MESSAGE}</span>
        </>
      }
      onClose={onClose}
      buttons={
        <>
          <RACButton
            variant="contained"
            disabled={isPending}
            color="primary"
            onClick={onClose}
          >
            No
          </RACButton>
          <RACButton
            disabled={isPending}
            loading={isPending}
            variant="outlined"
            color="secondary"
            onClick={() => run(id)}
          >
            Yes, Delete
          </RACButton>
        </>
      }
    />
  );
};
