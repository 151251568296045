import React from 'react';
import { useState } from 'react';
import {
  RACModal,
  RACButton,
  makeStyles,
  RACTextbox,
} from '@rentacenter/racstrap';

import { managePurchaseOrder } from '../../context/single/purchaseOrderThunks';
import { useAsync } from 'react-async';
import {
  ManagePOOperation,
  INVALID_UPDATE_ATTEMPT,
} from '../../constants/constants';

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'center',
    margin: `2rem 0 1.5rem`,
    height: '20%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
  },
  dialogActions: {
    justifyContent: 'flex-end',
    position: 'sticky',
    bottom: 0,
  },
  dialogRoot: {
    height: '100%',
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialog-paperScrollPaper': {
      width: '43%',
      minHeight: theme.typography.pxToRem(230),
      minWidth: theme.typography.pxToRem(300),
    },
    '& .MuiDialogTitle-root': {
      display: 'none',
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
  row: {
    display: 'inline-flex',
    width: '90%',
    marginBottom: theme.typography.pxToRem(10),
  },
  leftField: {
    width: '20%',
    marginLeft: theme.typography.pxToRem(5),
  },
  rightField: {
    width: '50%',
    marginRight: theme.typography.pxToRem(5),
  },
  contentHeight: {
    height: '30%',
    overflow: 'scroll',
  },
  confirmSpan: {
    marginTop: '0.75rem',
  },
}));
export interface UpdateModalProps {
  id: string;
  detailId: string;
  qty: string;
  receivedCnt: number;
  onResolve: (type: string) => void;
  onClose: () => void;
}

export const UpdatePOModal = ({
  id,
  detailId,
  qty,
  receivedCnt,
  onResolve,
  onClose,
}: UpdateModalProps) => {
  const classes = useStyles();

  const [tempQuantity, setTempQuantity] = useState(qty);
  const [quantityErrorMessage, setQuantityErrorMessage] = useState('');

  const { run, isPending } = useAsync({
    deferFn: (purchaseOrderIds: string[]) => {
      const poId = purchaseOrderIds.shift();
      const poDetailId = purchaseOrderIds.shift();

      const managePORequest = {
        operation: ManagePOOperation.Update,
        updateInfo: {
          purchaseOrderNumber: poId || '',
          purchaseOrderDetailId: poDetailId || '',
          quantity: tempQuantity,
        },
      };
      return managePurchaseOrder(managePORequest);
    },
    onResolve: () => onResolve('success'),
    onReject: (error: any) => onResolve(error?.errors[0]?.message),
  });

  const handleUpdate = () => {
    if (quantityErrorMessage === '' && tempQuantity !== '') {
      run(id, detailId);
    } else {
      setQuantityErrorMessage(INVALID_UPDATE_ATTEMPT);
    }
  };

  const handleQuantityChange = (e: any) => {
    // put in checks to make sure number is legit ( greater than 0 less than the original value)
    setTempQuantity(e.target.value);
    if (e.target.value !== '') {
      const newvalue = Number(e.target.value);

      // const leftToReceive = Number(qty) - receivedCnt;
      const minToCheck = receivedCnt >= 1 ? receivedCnt + 1 : 1;

      if (newvalue > Number(qty) || newvalue < minToCheck) {
        setQuantityErrorMessage(INVALID_UPDATE_ATTEMPT);
        return;
      }

      setQuantityErrorMessage('');
    }
  };

  return (
    <RACModal
      isOpen
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
      }}
      maxWidth="sm"
      title="Update PO"
      content={
        <>
          <div className={classes.row}>
            <span>You are updating the quantity of the PO.</span>
          </div>
          <div className={classes.row}>
            <div className={classes.rightField}>
              <RACTextbox
                name="Quantity"
                type="number"
                inputlabel="Quantity"
                maxlength={4}
                value={tempQuantity}
                required
                OnChange={handleQuantityChange}
                {...(quantityErrorMessage && {
                  errorMessage: quantityErrorMessage,
                })}
              />
            </div>
          </div>
        </>
      }
      onClose={onClose}
      buttons={
        <>
          <RACButton
            variant="contained"
            color="primary"
            disabled={isPending}
            onClick={onClose}
          >
            Cancel
          </RACButton>
          <RACButton
            disabled={isPending}
            loading={isPending}
            variant="outlined"
            color="secondary"
            onClick={handleUpdate}
          >
            Update
          </RACButton>
        </>
      }
    />
  );
};
