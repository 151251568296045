import { Action } from '../types/types';

export enum ApiActionType {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type ApiAction = Action<ApiActionType>;

export const getLoadingAction = (): ApiAction => ({
  type: ApiActionType.LOADING,
});

export const getSuccessAction = (payload: any): ApiAction => ({
  type: ApiActionType.SUCCESS,
  payload,
});

export const getErrorAction = (error: any): ApiAction => ({
  type: ApiActionType.ERROR,
  payload: error,
});
