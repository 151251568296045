import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { ProductPermissions } from '../../constants/constants';
import { ApiAction, ApiActionType } from '../api-actions';
import { getPermissionsThunk } from './permissions-thunks';

export interface PermissionsState {
  loading: boolean;
  hasError: boolean;
  rawPermissions: ProductPermissions[];
  poSubtypeRequired: boolean;
  canSelectPoSubType: boolean;
  hasAutoPricing: boolean;
  hasEditablePricing: boolean;
}

const defaultPermissions = {
  poSubtypeRequired: false,
  canSelectPoSubType: false,
  hasAutoPricing: false,
  hasEditablePricing: false,
};
export const defaultState = {
  loading: false,
  hasError: false,
  rawPermissions: [],
  ...defaultPermissions,
};

export function permissionsReducer(
  state: PermissionsState,
  action: ApiAction
): PermissionsState {
  switch (action.type) {
    case ApiActionType.LOADING:
      return { ...state, loading: true };
    case ApiActionType.SUCCESS:
      return {
        ...state,
        loading: false,
        rawPermissions: action.payload as ProductPermissions[],
        poSubtypeRequired: action.payload.includes(
          ProductPermissions.PO_SUBTYPE_REQUIRED
        ),
        canSelectPoSubType: action.payload.includes(
          ProductPermissions.CAN_SELECT_PO_SUB_TYPE
        ),
        hasAutoPricing: action.payload.includes(
          ProductPermissions.AUTO_PRICING
        ),
        hasEditablePricing: action.payload.includes(
          ProductPermissions.EDITABLE_PRICING
        ),
      };
    case ApiActionType.ERROR:
      return { ...state, loading: false, hasError: true };
  }
}

export const PermissionsContext = createContext<PermissionsState>(defaultState);

export const useUserPermissions = () => useContext(PermissionsContext);

export const PermissionsProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(permissionsReducer, defaultState);

  useEffect(() => {
    getPermissionsThunk(dispatch)();
  }, []);

  return (
    <PermissionsContext.Provider value={state}>
      {props.children}
    </PermissionsContext.Provider>
  );
};
