import { SelectFieldOption } from '../../types/types';
import { GlobalContextAction } from '../global/globalReducer';
import { PoSubTypesActionType } from './poSubTypesActions';

export const poSubTypesReducer = (
  state: SelectFieldOption[],
  action: GlobalContextAction
): SelectFieldOption[] => {
  if (action.type === PoSubTypesActionType.SET_PO_SUB_TYPES) {
    return action.payload.poSubTypes;
  }

  return state;
};
