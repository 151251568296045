import { ProductPermissions } from '../constants/constants';

export interface Error {
  code: string;
  message: string;
}

export interface APIError {
  traceId: string;
  requestId: string;
  errors: Error[];
}

export interface User {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly employeeId: string;
  readonly route: string;
  readonly permissions: ProductPermissions[];
}

export interface Action<T1 = any, T2 = any> {
  type: T1;
  payload?: T2;
}

export type StyleClasses<T extends string | number | symbol> = Partial<
  Record<T, string>
>;

export interface StoreDetail {
  storeId: string;
  storeNumber: string;
  storeName: string;
  lob: string;
  countryAbb: string;
  stateName: string;
  stateAbb: string;
  city: string;
  zip: string;
  fullZip: string;
  addressLine1: string;
  workPhoneNumber: string;
  latitude: string;
  longitude: string;
}

export interface StoreDetailResponse {
  response: StoreDetail[];
}

export interface StoreConfigsPayload {
  storeNumbers: string[];
  paramKeyNames: string[];
}

export interface StoreConfigDetails {
  paramValue: string;
  paramKeyName: string;
  paramGroupName: string;
  paramCategoryName: string;
  paramKeyId: string;
  paramHierarchy: string;
}

export interface StoreConfigsResponse {
  storeNumber: string;
  configDetails: StoreConfigDetails[];
}

export type ListStatus =
  | 'initial'
  | 'loading'
  | 'apiError'
  | 'empty'
  | 'success'
  | 'custom';

export interface SelectFieldOption {
  label: string;
  value: string;
}

export interface SelectedFieldOption extends SelectFieldOption {
  departments?: any[];
  subDepartments?: any[];
  brackets?: any[];
  types?: any[];
  subTypes: any[];
}

export interface State {
  readonly name: string;
  readonly abbreviation: string;
  [key: string]: any;
}

export interface FormAllocation {
  readonly draftPoDetailsId?: string;
  readonly quantity: string;
  readonly storeNum: string;
  readonly state: string;
  readonly isDefault?: boolean;
}

export interface FormPricing {
  readonly state: string;
  readonly weeklyRent: string;
  readonly weeklyTerm: string;
  readonly biWeeklyRent: string;
  readonly biWeeklyTerm: string;
  readonly semiMonthlyRent: string;
  readonly semiMonthlyTerm: string;
  readonly monthlyRent: string;
  readonly monthlyTerm: string;
  readonly cashPrice?: string;
  readonly totalRtoPrice: string;
  [key: string]: any;
}

export interface PurchaseOrderFormValues {
  id: string;
  readonly referenceNumber: string;
  readonly purchaseOrderSubType: string;
  readonly startClassificationsSelect: string;
  readonly supplier: string;
  readonly brand: string;
  readonly model: string;
  readonly itemCondition: string;
  department: string;
  subDepartment: string;
  bracket: string;
  itemType: string;
  itemSubType: string;
  readonly description: string;
  readonly supplierOrderNo?: string;
  readonly allocations: FormAllocation[];
  readonly pricing: FormPricing[];
  readonly totalQuantity: string;
  readonly costPerItem: string;
  readonly totalCost: string;
  readonly poStatus: string;
  readonly costOfAlignment: number;
  readonly isCanceled: boolean;

  [key: string]: any;
}

export enum FormPricingKeys {
  WeeklyRent = 'weeklyRent',
  WeeklyTerm = 'weeklyTerm',
  TotalRtoPrice = 'totalRtoPrice',
  BiWeeklyRent = 'biWeeklyRent',
  BiWeeklyTerm = 'biWeeklyTerm',
  SemiMonthlyRent = 'semiMonthlyRent',
  SemiMonthlyTerm = 'semiMonthlyTerm',
  MonthlyRent = 'monthlyRent',
  MonthlyTerm = 'monthlyTerm',
}

export interface OldLegalEngineResponseItem {
  readonly inCompliance?: boolean;
  readonly hasCashPriceCheckFailed?: boolean;
  readonly cashPriceWithinLegalLimit?: boolean;
  readonly totalCostWithinLegalLimit: boolean;
  readonly termOrCashPriceAdjusted?: boolean;
  readonly storeNum: string;
  readonly weeklyRent: number;
  readonly weeklyTerm: number;
  readonly cashPrice?: number;
  readonly totalCost?: number;
  readonly adjustedTerm?: number;
  readonly totalCostLimit?: number;
  readonly cashPriceLimit?: number;
}

export interface Pricing {
  readonly weeklyRent?: number;
  readonly weeklyTerm?: number;
  readonly totalRtoPrice?: number;
  readonly biWeeklyRent?: number;
  readonly biWeeklyTerm?: number;
  readonly semiMonthlyRent?: number;
  readonly semiMonthlyTerm?: number;
  readonly monthlyRent?: number;
  readonly monthlyTerm?: number;
}

export interface Allocation {
  readonly draftPoDetailsId?: string;
  readonly storeNum?: string;
  readonly quantity?: number;
  readonly state?: string;
  readonly pricing?: Pricing;
  [key: string]: any;
}

export interface LegalEngineRequest {
  readonly itemCondition: string;
  readonly department: string;
  readonly subDepartment: string;
  readonly allocations: Allocation[];
  readonly costPerItem: number;
  readonly itemType: string;
  readonly itemSubType: string;
  readonly bracket: string;
}

export interface PropWithChildren {
  children: JSX.Element;
}

export interface LastModifiedBy {
  readonly firstName: string;
  readonly lastName: string;
}

export interface PurchaseOrder {
  readonly poStatusType?: string;
  readonly id?: string;
  readonly referenceNumber?: string;
  readonly purchaseOrderSubType?: string;
  readonly startClassificationsSelect?: string;
  readonly supplier?: string;
  readonly brand?: string;
  readonly model?: string;
  readonly itemCondition?: string;
  readonly department?: string;
  readonly subDepartment?: string;
  readonly bracket?: string;
  readonly itemType?: string;
  readonly itemSubType?: string;
  readonly description?: string;
  readonly supplierOrderNo?: string;
  readonly allocations: Allocation[];
  readonly totalQuantity?: number;
  readonly costPerItem?: number;
  readonly totalCost?: number;
  readonly poStatus?: string;
  readonly isCanceled?: boolean;
  readonly updatedAt?: string;
  readonly lastModifiedBy?: LastModifiedBy;
  readonly costOfAlignment?: number;
}

interface OrderDateRange {
  endDate: string;
  startDate: string;
}

export interface GetPurchaseOrderRequest {
  coworkerId?: string;
  storeNumber?: any;
  poDateRange?: OrderDateRange;
  purchaseOrderType?: string[];
  purchaseOrderNumber?: string;
  poStatusType?: string[];
}

export interface GetPurchaseOrderResponse {
  purchaseOrders: PurchaseOrder[];
  storeNumbers: string[];
  limit: string;
  offset: string;
}

export interface ReferenceResponse {
  references: References[];
}

export interface References {
  readonly referenceDetails: ReferenceItem[];
  readonly referenceKey: string;
}
export interface ReferenceItem {
  readonly referenceCode: string;
  readonly displaySeq: number;
  readonly defaultValue: string;
  readonly description: string;
  readonly descSpanish?: string;
}

export enum ReferenceKeys {
  PURCHASE_ORDER_STATUS_TYPE = 'PURCHASE_ORDER_STATUS_TYPE',
}
export interface Option {
  label: string;
  value: string;
}

export interface POSearchFilter {
  readonly poNumber: string;
  readonly fromDate: string;
  readonly toDate: string;
}

export interface LegalEngineResponseItem {
  readonly priceInfo: PriceInfo;
  readonly storeNumber: string;
  readonly rmsItemNumber: string;
  readonly itemPriceId: string;
  readonly addOnRate: string;
  readonly iNote?: iNoteRate;
  readonly priceTagType: string;
  readonly startTime: string;
  readonly endTime: string;
}

export interface PriceInfo {
  readonly weeklyRate: string;
  readonly biweeklyRate: string;
  readonly semiMonthlyRate: string;
  readonly monthlyRate: string;
  readonly weeklyTerm: number;
  readonly biWeeklyTerm: number;
  readonly semiMonthlyTerm: number;
  readonly monthlyTerm: number;
  readonly cashPrice: string;
  readonly totalCost: string;
  readonly isTotalCostAdjusted: number;
  readonly isCashPriceAdjusted: number;
  readonly originalWeeklyTerm: number;
}

export interface iNoteRate {
  readonly iNoteCost: string;
  readonly iNoteWeeklyTerm: number;
  readonly iNoteBiWeeklyTerm: number;
  readonly iNoteSemiMonthlyTerm: number;
  readonly iNoteMonthlyTerm: number;
}
