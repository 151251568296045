import { Action } from '../../types/types';

export enum SelectedPOClearedActionType {
  SELECTED_PO_STORE = 'SELECTED_PO_STORE',
}

export interface SelectedPOClearedAction extends Action {
  readonly type: SelectedPOClearedActionType.SELECTED_PO_STORE;
  readonly payload?: {
    readonly selectedPOStore: boolean;
  };
}

export const selectedPOClearedAction = (
  selectedPOStore: boolean
): SelectedPOClearedAction => ({
  type: SelectedPOClearedActionType.SELECTED_PO_STORE,
  payload: { selectedPOStore },
});
