import { CancelToken } from 'axios';
import { client, mapError } from './client';
import {
  ManagePurchaseOrderRequest,
  PurchaseOrder,
} from '../domain/PurchaseOrder/PurchaseOrder';
import {
  GetPurchaseOrderRequest,
  GetPurchaseOrderResponse,
  LegalEngineRequest,
  LegalEngineResponseItem,
  SelectFieldOption,
} from '../types/types';
import { appConfig } from '../config/app-config';

const basePath = 'purchase-orders';

function getPurchaseOrdersByStatus(
  request: GetPurchaseOrderRequest,
  offset?: number,
  limit?: number,
  cancelToken?: CancelToken
): Promise<GetPurchaseOrderResponse> {
  const limitParam = limit ? `limit=${limit}` : '';
  const offsetParam = limit ? `offset=${offset}` : '';

  let url = basePath;
  if (limitParam || offsetParam) {
    url = `${url}?`;
    if (limitParam && offsetParam) {
      url = `${url}${limitParam}&${offsetParam}`;
    } else if (limitParam) {
      url = `${url}${limitParam}`;
    } else {
      url = `${url}${offsetParam}`;
    }
  }
  return client(
    url,
    {
      method: 'POST',
      body: request,
      cancelToken,
    },
    appConfig.apiUrls.micro,
    true
  );
}

function getPurchaseOrderById(
  request: GetPurchaseOrderRequest
): Promise<GetPurchaseOrderResponse> {
  return client(
    `${basePath}`,
    {
      method: 'POST',
      body: request,
    },
    appConfig.apiUrls.micro,
    true
  );
}

function getDraftPurchaseOrders() {
  return client(
    `${basePath}/search-draft`,
    {
      method: 'GET',
    },
    appConfig.apiUrls.micro,
    true
  );
}

function getDraftPurchaseOrder(userId: string, draftId: string) {
  return client(
    `${basePath}/search-draft?draftId=${draftId}`,
    {
      method: 'GET',
    },
    appConfig.apiUrls.micro,
    true
  );
}

function createDraftPurchaseOrder(
  purchaseOrder: PurchaseOrder
): Promise<PurchaseOrder> {
  return client(
    `${basePath}/save-draft`,
    {
      method: 'POST',
      body: purchaseOrder,
    },
    appConfig.apiUrls.micro,
    true
  );
}

function updateDraftPurchaseOrder(
  purchaseOrderId: string,
  purchaseOrder: PurchaseOrder
): Promise<PurchaseOrder> {
  return client(
    `${basePath}/save-draft`,
    {
      method: 'POST',
      body: { ...purchaseOrder, draftPoId: purchaseOrderId },
    },
    appConfig.apiUrls.micro,
    true
  );
}

function createSubmittedPurchaseOrder(
  purchaseOrder: PurchaseOrder
): Promise<PurchaseOrder[]> {
  return client(
    `${basePath}/submitted`,
    {
      method: 'POST',
      body: purchaseOrder,
    },
    appConfig.apiUrls.micro,
    true
  ).then((res) => res);
}

function updateSubmittedPurchaseOrder(
  purchaseOrderId: string,
  purchaseOrder: PurchaseOrder
): Promise<PurchaseOrder[]> {
  return client(
    `${basePath}/submitted`,
    {
      method: 'POST',
      body: { ...purchaseOrder, draftId: purchaseOrderId },
    },
    appConfig.apiUrls.micro,
    true
  ).then((res) => res);
}

function deleteDraftPurchaseOrder(draftId: string): Promise<void> {
  return client(`${basePath}/${draftId}`, { method: 'DELETE' });
}

const managePurchaseOrder = (
  request: ManagePurchaseOrderRequest
): Promise<void> =>
  client(
    `${basePath}/manage`,
    {
      method: 'PUT',
      body: request,
    },
    appConfig.apiUrls.micro,
    true
  ).catch(mapError);

const getPoSubTypes = (): Promise<SelectFieldOption[]> =>
  client(`misc/po-sub-types`, { method: 'GET' }, appConfig.apiUrls.micro);

export interface FixedParams {
  readonly department: string;
  readonly subDepartment: string;
  readonly bracket: string;
  readonly itemType: string;
  readonly itemSubType: string;
  readonly costPerItem: string;
}

export interface RateAndTermParams extends FixedParams {
  readonly storeNumber: string;
  readonly poType: string;
}

export interface RateAndTermResponse {
  readonly result: RateAndTermResult[];
  // does not come on the response, it is set in AoutPricing, in order to create an action payload
  readonly state?: string;
}

export interface RateAndTermResult {
  readonly itemPriceId: string;
  readonly priceTagType: string;
  readonly startTime: string;
  readonly endTime: string;
  readonly storeNumber: string;
  readonly rmsItemNumber: string;
  readonly addOnRate: number;
  readonly newRate: NewRate;
  readonly oldRate: OldRate;
  readonly iNote: iNoteRate;
}

export interface OldRate {
  readonly weeklyRate: number;
  readonly biWeeklyRate: number;
  readonly semiMonthlyRate: number;
  readonly monthlyRate: number;
  readonly weeklyTerm: number;
  readonly biWeeklyTerm: number;
  readonly semiMonthlyTerm: number;
  readonly monthlyTerm: number;
  readonly cashPrice: number;
  readonly totalCost: number;
}

export interface NewRate {
  readonly weeklyRate: number | null;
  readonly biWeeklyRate: number | null;
  readonly semiMonthlyRate: number | null;
  readonly monthlyRate: number | null;
  readonly weeklyTerm: number | null;
  readonly biWeeklyTerm: number | null;
  readonly semiMonthlyTerm: number | null;
  readonly monthlyTerm: number | null;
  readonly cashPrice: number | null;
  readonly totalCost: number | null;
}

export interface iNoteRate {
  readonly iNoteCost: string;
  readonly iNoteWeeklyTerm: number;
  readonly iNoteBiWeeklyTerm: number;
  readonly iNoteSemiMonthlyTerm: number;
  readonly iNoteMonthlyTerm: number;
}

const getRateAndTerm = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params: RateAndTermParams
): Promise<RateAndTermResponse> => {
  return client(
    `pricing-summary`,
    {
      method: 'POST',
      body: params,
    },
    appConfig.apiUrls.micro,
    true
  )
    .then((response) => {
      let fieldName = '';
      let fieldValue = undefined;
      let limit = undefined;

      if (response?.result?.[0]?.newRate) {
        const pricingData = response.result[0].newRate;
        if (pricingData.weeklyTerm > 200) {
          fieldName = 'term';
          fieldValue = pricingData.weeklyTerm;
          limit = 200;
        } else if (pricingData.weeklyRate > 900) {
          fieldName = 'rate';
          fieldValue = pricingData.weeklyRate;
          limit = 900;
        }
      } else {
        return Promise.reject({
          response: {
            data: {
              errors: [
                {
                  code: 400,
                  message: 'Invalid Response',
                },
              ],
            },
          },
        });
      }

      if (fieldName) {
        return Promise.reject({
          response: {
            data: {
              errors: [
                {
                  code: 500,
                  message: `Calculated ${fieldName} (${fieldValue}) is above limit. ${limit}`,
                },
              ],
            },
          },
        });
      }
      return response;
    })
    .catch(mapError);
};

const getLegalPrice = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params: LegalEngineRequest
): Promise<LegalEngineResponseItem[]> =>
  client(
    `legal-price`,
    {
      method: 'POST',
      body: params,
    },
    appConfig.apiUrls.micro,
    true
  ).catch(mapError);

export {
  getPurchaseOrdersByStatus,
  getDraftPurchaseOrders,
  getDraftPurchaseOrder,
  getPurchaseOrderById,
  createDraftPurchaseOrder,
  createSubmittedPurchaseOrder,
  updateSubmittedPurchaseOrder,
  updateDraftPurchaseOrder,
  deleteDraftPurchaseOrder,
  managePurchaseOrder,
  getPoSubTypes,
  getRateAndTerm,
  getLegalPrice,
};
