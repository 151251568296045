import { Checkbox, FormControlLabel, Tooltip } from '@rentacenter/racstrap';
import React from 'react';

import styles from './SearchEntry.module.scss';

interface Props {
  readonly label: string;
  readonly checked: boolean;
  readonly onChange: (isSelected: boolean) => void;
}

export const SearchEntry = ({ label, checked, onChange }: Props) => {
  return (
    <Tooltip title={label}>
      <FormControlLabel
        classes={{
          root: styles.checkboxRoot,
          label: styles.checkboxLabel,
        }}
        control={
          <Checkbox
            color="primary"
            checked={checked}
            onChange={(event) => {
              onChange(event.target.checked);
            }}
            classes={{ root: styles.checkbox }}
          />
        }
        label={label}
      />
    </Tooltip>
  );
};
