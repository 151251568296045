/* eslint-disable sonarjs/no-duplicate-string */
import {
  makeStyles,
  RACButton,
  RACCard,
  RACCOLOR,
  Typography,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { createStoreOptions, Store } from '../../domain/Store';
import {
  FormAllocation,
  PurchaseOrderFormValues,
  SelectFieldOption,
} from '../../types/types';
import { createFormAllocation } from '../../utils/formAllocations';
import { CostPerItem } from '../Allocations/CostPerItem';
import { TotalCost } from '../Allocations/TotalCost';
import { TotalQuantity } from '../Allocations/TotalQuantity';
import { AllocationItem } from './AllocationItem';

export interface AllocationsProps {
  readonly purchaseOrder: PurchaseOrderFormValues | null;
  readonly stores: Store[];
  readonly disabled: boolean;
  readonly isSearchLoading?: boolean;
  readonly onSearch?: (text: string) => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    flex: 1,
    minWidth: theme.typography.pxToRem(600),
    ['@media (max-width:1240px)']: {
      // eslint-disable-line no-useless-computed-key
      maxWidth: theme.typography.pxToRem(600),
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(8),
    alignItems: 'flex-start',
  },
  cardContentContainer: {
    padding: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  field: {
    width: theme.typography.pxToRem(200),
  },
  smallField: {
    width: theme.typography.pxToRem(80),
  },
  costFields: {
    width: '7rem',
  },
  largeField: {
    width: theme.typography.pxToRem(230),
  },
  cost: {
    zIndex: 0,
    marginRight: 'auto',
    marginLeft: 'auto',
    gap: theme.typography.pxToRem(32),
  },
  button: {
    height: theme.typography.pxToRem(43),
  },
  stores: {
    display: 'flex',
    gap: theme.typography.pxToRem(32),
    flexDirection: 'column',
    margin: 'auto',
    height: '100%',
  },
  cancelButton: {
    backgroundColor: RACCOLOR.LINEN,
    padding: theme.typography.pxToRem(10),
    width: theme.typography.pxToRem(97),
    height: theme.typography.pxToRem(35),
    color: RACCOLOR.PUNCH,
    '&:hover': {
      backgroundColor: RACCOLOR.LINEN,
    },
    alignSelf: 'flex-start',
    marginTop: theme.typography.pxToRem(22),
    '&:disabled': {
      background: 'rgba(233,92,92,255)',
      opacity: 0.5,
    },
  },
}));
export const Allocations = (props: AllocationsProps) => {
  const { purchaseOrder, stores, disabled, onSearch, isSearchLoading } = props;
  const classes = useStyles();

  const { fields, append, remove } = useFieldArray<FormAllocation>({
    name: 'allocations',
  });

  const addStoreAllocation = useCallback(() => {
    append(createFormAllocation());
  }, [append]);

  const removeStoreAllocation = useCallback(
    (index: number) => {
      if (index > 0) {
        remove(index);
      }
    },
    [remove]
  );

  const allAllocations: any = useWatch({ name: 'allocations' });

  const selectStoreOptions = createStoreOptions(stores).filter(
    (fieldOption: SelectFieldOption) =>
      !allAllocations.find(
        (a: FormAllocation) => a.storeNum === fieldOption.value
      )
  );

  const isMultiStoreDisabled = selectStoreOptions.length === 0;

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography noWrap variant="h4">
          Allocations
        </Typography>
      </div>
      <RACCard>
        <div className={classes.cardContentContainer}>
          <div className={classes.stores}>
            {fields.map((field: any, index: number) => (
              <AllocationItem
                key={field.id}
                index={index}
                allocationField={field}
                stores={stores}
                onRemoveButtonClick={removeStoreAllocation}
                disabled={disabled}
                selectStoreOptions={selectStoreOptions}
                isSearchable
                onSearch={onSearch}
                isStoreLoading={isSearchLoading}
              >
                <RACButton
                  disabled={disabled || index === 0}
                  className={classes.cancelButton}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => removeStoreAllocation(index)}
                >
                  Delete
                </RACButton>
              </AllocationItem>
            ))}

            <div className={clsx(classes.row)}>
              <RACButton
                fullWidth
                disabled={disabled || isMultiStoreDisabled}
                className={clsx(classes.button)}
                size="small"
                variant="contained"
                color="primary"
                onClick={addStoreAllocation}
              >
                Add Store
              </RACButton>
            </div>
          </div>
          <div className={clsx(classes.row, classes.cost)}>
            <TotalQuantity />
            <CostPerItem purchaseOrder={purchaseOrder} disabled={disabled} />
            <TotalCost />
          </div>
        </div>
      </RACCard>
    </div>
  );
};
