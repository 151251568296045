import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from 'react';
import { FixedParams, NewRate } from '../../../api/purchaseOrders';
import { PurchaseOrderFormValues } from '../../../types/types';
import { noOp } from '../../../utils/utils';

interface ActionProps {
  readonly type: string;
  readonly payload?: any;
}

export interface StateProps {
  readonly snapshot: any[];
  readonly isChanged: boolean;
  readonly allFieldsPresent: boolean;
  readonly fixedParams?: FixedParams;
  readonly autoPricing: NewRate;
  readonly autoPriceLoading: boolean;
}

export enum ActionType {
  SET_SNAPSHOT = 'setSnapshot',
  SET_IS_CHANGED = 'setIsChanged',
  SET_ALL_FIELDS_PRESENT = 'setAllFieldsPresent',
  SET_FIXED_PARAMS = 'setAllFields',
  SET_AUTO_PRICING = 'setAutoPricing',
  SET_AUTO_PRICE_LOADING = 'setAutoPriceLoading',
}

export const initialAutoPricingState: NewRate = {
  weeklyRate: null,
  weeklyTerm: null,
  totalCost: null,
  biWeeklyRate: null,
  biWeeklyTerm: null,
  semiMonthlyRate: null,
  semiMonthlyTerm: null,
  monthlyRate: null,
  monthlyTerm: null,
  cashPrice: null,
};

export const initialState: StateProps = {
  snapshot: [],
  isChanged: false,
  allFieldsPresent: true,
  autoPricing: initialAutoPricingState,
  autoPriceLoading: false,
};

export const reducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case ActionType.SET_SNAPSHOT: {
      return { ...state, snapshot: action.payload };
    }
    case ActionType.SET_IS_CHANGED: {
      return { ...state, isChanged: action.payload };
    }
    case ActionType.SET_ALL_FIELDS_PRESENT: {
      return { ...state, allFieldsPresent: action.payload };
    }
    case ActionType.SET_FIXED_PARAMS: {
      return { ...state, fixedParams: action.payload };
    }
    case ActionType.SET_AUTO_PRICING: {
      return { ...state, autoPricing: action.payload };
    }
    case ActionType.SET_AUTO_PRICE_LOADING: {
      return { ...state, autoPriceLoading: action.payload };
    }
    default:
      return state;
  }
};

export const StateContext = createContext(initialState);
export const DispatchContext = createContext<Dispatch<ActionProps>>(noOp);

interface Props {
  readonly children: ReactNode;
  readonly purchaseOrder: PurchaseOrderFormValues | null;
  readonly uniqueStates: string[];
}

export const AutoPricingProvider = (props: Props) => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export const useAutoPricingState = () => {
  return useContext(StateContext);
};

export const useAutoPricingDispatch = () => {
  return useContext(DispatchContext);
};
