import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AutoNumeric } from '../../common/AutoNumeric/AutoNumeric';
import { FieldWithTooltip } from '../../common/FieldWithTooltip/FieldWithTooltip';
import { PoFormField, PoFormLabel } from '../../constants/constants';
import { PricingCalculator } from '../../utils/PricingCalculator';
import { calculateValue } from '../../utils/utils';

export const totalCostTestId = 'TotalCostComponent';

export const TotalCost = () => {
  const totalQuantity = useWatch({ name: 'totalQuantity' }) as string;
  const costPerItem = useWatch({ name: 'costPerItem' }) as string;
  const { setValue } = useFormContext();

  const [itemValue, setItemValue] = useState<string>();

  useEffect(() => {
    const totalCost =
      parseInt(totalQuantity, 10) >= 1 && parseInt(costPerItem, 10) >= 1
        ? calculateValue(
            PricingCalculator.totalCost,
            totalQuantity,
            costPerItem
          )
        : '';
    setItemValue(totalCost);
    setValue(PoFormField.TotalCost, totalCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalQuantity, costPerItem]);
  return useMemo(
    () => (
      <div style={{ flex: 1 }} data-testid={totalCostTestId}>
        <FieldWithTooltip
          id={PoFormLabel.TotalCost}
          title={PoFormLabel.TotalCost}
          required
          currency
        >
          <Controller
            as={AutoNumeric}
            name={PoFormField.TotalCost}
            defaultValue=""
            imperativeMode
            imperativeValue={itemValue}
            options={{
              decimalPlaces: 2,
            }}
            placeholder=""
            disabled
            rules={{ required: true }}
          />
        </FieldWithTooltip>
      </div>
    ),
    [itemValue]
  );
};
