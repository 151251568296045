import { LegalEngineResponseItem } from '../../types/types';

export const isIllegalItem = (item: LegalEngineResponseItem) =>
  item?.priceInfo?.isTotalCostAdjusted === 1 &&
  item?.priceInfo?.originalWeeklyTerm !== item?.priceInfo?.weeklyTerm;

export const hasIllegalResponseItem = (
  legalEngineResponse: LegalEngineResponseItem[]
): boolean => legalEngineResponse.some(isIllegalItem);

export const toIllegalResponseList = (
  legalEngineResponse: any[]
): LegalEngineResponseItem[] =>
  legalEngineResponse
    .map((obj) => {
      return obj.value;
    })
    .filter(isIllegalItem);
