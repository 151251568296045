import React from 'react';

import styles from './SelectAll.module.scss';
import { noOp } from '../../../../utils/utils';
import { Checkbox, FormControlLabel } from '@rentacenter/racstrap';

interface Props {
  readonly selectedCount: number;
  readonly total: number;
  readonly onChange?: (isSelected: boolean) => void;
}

export const SelectAll = ({ selectedCount, total, onChange = noOp }: Props) => {
  return (
    <>
      <div className={styles.selectedCounter}>
        {`${selectedCount} of ${total} Selected`}
      </div>
      <FormControlLabel
        classes={{ label: styles.selectAllLabel }}
        control={
          <Checkbox
            color="primary"
            checked={selectedCount > 0 && selectedCount === total}
            onChange={(event) => {
              onChange(event.target.checked);
            }}
          />
        }
        label="Select all"
      />
    </>
  );
};
