/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  makeStyles,
  RACCard,
  RACTabs,
  Typography,
} from '@rentacenter/racstrap';
import { SubmittedPOs } from './SubmittedPOs';
import { DraftPOs } from './DraftPOs';
import { Footer } from '../../common/Footer/Footer';
import { POFooter } from './POFooter';
import { SubmittedPosProvider } from '../../context/submitted/SubmittedPosProvider';
import { DraftPosProvider } from '../../context/draft/DraftPosProvider';
import clsx from 'clsx';

const AVAILABLE_TABS = ["Submitted PO's", "Draft PO's"];
const SUBMITTED_TAB_INDEX = 0;
const DRAFT_TAB_INDEX = 1;
/*
  Reason for having both whiteSpace: 'nowrap' and <Typography noWrap ....>:
  The one from css (th and td see below) will make the string to not break into new line.
  The one from Typography will add the 3 dots (...) when string cannot be completely displayed.
  */
const useStyles = makeStyles((theme: any) => ({
  posRoot: {
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(8),
  },
  cardContentContainer: {
    marginLeft: '1.25rem',
    marginRight: '1.25rem',
    '& th': {
      whiteSpace: 'nowrap',
    },
    '& td': {
      maxWidth: theme.typography.pxToRem(300),
      whiteSpace: 'nowrap',
    },
  },
  tabPanel: {
    height: '75vh',
    overflow: 'hidden',
  },
  contentWrapper: {
    height: '75vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const POs = () => {
  const classes = useStyles();

  const isDraftPO = location?.search === '?draft';

  return (
    <div className={classes.posRoot}>
      <div className={classes.row}>
        <Typography noWrap variant="h4">
          Manual Purchase Order
        </Typography>
      </div>
      <RACCard>
        <div className={classes.cardContentContainer}>
          <RACTabs
            classes={{
              tabPanel: classes.tabPanel,
            }}
            defaultTab={!isDraftPO ? SUBMITTED_TAB_INDEX : DRAFT_TAB_INDEX}
            tabs={AVAILABLE_TABS}
            contentForTabs={[
              <SubmittedPosProvider key="submitted">
                <div className={classes.contentWrapper}>
                  <SubmittedPOs />
                </div>
              </SubmittedPosProvider>,
              <DraftPosProvider key="draft">
                <div className={classes.contentWrapper}>
                  <DraftPOs />
                </div>
              </DraftPosProvider>,
            ]}
          />
        </div>
      </RACCard>
      <Footer>
        <POFooter />
      </Footer>
    </div>
  );
};
