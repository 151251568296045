import { Grid, makeStyles, RACButton, RACCard } from '@rentacenter/racstrap';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../config/route-config';

const useStyles = makeStyles((theme: any) => ({
  footerRoot: {
    width: '100%',
    marginBottom: '0rem',
    display: 'block',
    position: 'fixed',
    bottom: '0',
    left: '0',
    zIndex: 1100,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(0),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0rem',
  },
  cardBody: {
    flex: '0 0 auto',
    padding: '1rem 1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.typography.pxToRem(4),
    padding: '1rem',
  },
  button: {
    height: theme.typography.pxToRem(43),
  },
}));

export const POFooter = () => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.footerRoot)}>
      <RACCard className={classes.card}>
        <div className={clsx(classes.cardBody)}>
          <div className={clsx(classes.row)}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                classes={{ root: classes.buttonContainer }}
              >
                <Link
                  style={{ color: 'inherit', textDecoration: 'unset' }}
                  to={{
                    pathname: AppRoute.Create,
                  }}
                >
                  <RACButton
                    className={clsx(classes.button)}
                    variant="contained"
                    size="small"
                    key="footerCreatePO"
                    color="primary"
                    onClick={() => {
                      // eslint-disable-next-line no-console
                      console.log('Implement me');
                    }}
                  >
                    Create PO
                  </RACButton>
                </Link>
              </Grid>
            </Grid>
          </div>
        </div>
      </RACCard>
    </div>
  );
};
